import React from 'react';
import ReactHtmlParser from "react-html-parser";

const MaterialRFQTable = ({ rfqData, sortedSuppliers, withGst, openDropdown, handleMaterialDropdown }) => {

    // Calculate lowest prices per material based on material._id
    // Calculate lowest prices per material based on material._id
    const lowestPrices = {};

    rfqData?.materials?.forEach((material) => {
        let minPrice = Infinity;
        let minSupplierIndexes = [];

        sortedSuppliers?.forEach((supplier, vendorIndex) => {
            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
            const vendorItem = vendorQuotation?.materialDetails?.find(item => item.id === material._id);

            if (vendorItem && vendorItem.basePrice > 0) {  // Ignore basePrice === 0
                if (vendorItem.basePrice < minPrice) {
                    minPrice = vendorItem.basePrice;
                    minSupplierIndexes = [vendorIndex]; // Reset and store new lowest index
                } else if (vendorItem.basePrice === minPrice) {
                    minSupplierIndexes.push(vendorIndex); // Add vendor to lowest list
                }
            }
        });

        // If no valid prices were found, default to 0
        lowestPrices[material._id] = {
            minPrice: minPrice === Infinity ? 0 : minPrice,
            minSupplierIndexes
        };
    });


    return (
        <>
            {rfqData?.materials?.map((material, rowIndex) => (
                <tr key={material._id} className='bg-white text-xs'>
                    <div className='sticky left-0 z-10 w-full bg-white' style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
                        <td className="flex justify-between items-center max-w-[300px] min-w-[300px] bg-white sticky py-2 left-0 pl-4 pr-2 border-b">
                            <div className='w-full space-x-2 bg-white justify-between'>
                                <p className='flex space-x-1'>
                                    <span> {rowIndex + 1}. </span>
                                    <span className='w-full'> {ReactHtmlParser(material?.name)} </span>
                                </p>
                                {material?.quantity} {material?.unit}
                            </div>

                            <p className="text-xs text-black bg-white w-[48%] pl-2 break-words">
                                @ Rs.{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(lowestPrices[material._id]?.minPrice ?? 0)}
                            </p>
                        </td>
                    </div>

                    {sortedSuppliers?.map((supplier, vendorIndex) => {
                        const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                        const vendorItem = vendorQuotation?.materialDetails?.find(item => item.id === material._id) || {};
                        const supplierPrice = vendorItem.basePrice ?? 0;
                        const isLowest = lowestPrices[material._id]?.minSupplierIndexes.includes(vendorIndex);

                        return (
                            <td key={vendorIndex} className={`max-w-[300px] min-w-[300px] pt-1 px-4 z-0 border border-gray-300}`}>
                                <div onClick={() => handleMaterialDropdown(rowIndex, vendorIndex)} className='relative cursor-pointer py-1'>
                                    <div className='w-full'>
                                        {vendorItem.deliveryDate && rfqData.providingDate && (
                                            new Date(vendorItem.deliveryDate).getTime() !== new Date(rfqData.providingDate).getTime() && (
                                                <p className='text-xs text-[#DC7604]'>
                                                    Proposed: {new Date(vendorItem.deliveryDate).toLocaleDateString('en-GB')}
                                                </p>
                                            )
                                        )}
                                        <div className='flex justify-between items-center space-x-2'></div>
                                    </div>

                                    <div className='flex justify-between w-full'>
                                        <div className='flex gap-4'>
                                            <p className={`text-xs ${isLowest ? 'bg-lime-200 px-1' : ''}`}>
                                                @ Rs. {new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(supplierPrice)}
                                            </p>
                                            {withGst && <p className='text-xs text-[#777777]'> GST: {vendorItem.gst} %</p>}
                                        </div>
                                        <div className='flex space-x-4'>
                                            {vendorItem?.attachments?.length > 0 && (
                                                <div className='relative mt-1'>
                                                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/files.svg" alt="" className='w-3 h-3' />
                                                    <div className='bg-[#f05252] rounded-full -top-2 -right-2 h-3 w-3 flex justify-center items-center absolute'>
                                                        <p className='text-[10px] text-white text-center pt-[3px]'>{vendorItem?.attachments?.length}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <p className='text-xs font-normal text-[#2D2D11]'>
                                                Rs. {withGst
                                                    ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(vendorItem.netPrice ?? 0)
                                                    : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(vendorItem?.priceWithoutGst ?? 0)}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Dropdown for material */}
                                {(openDropdown.rowIndex === rowIndex && openDropdown.vendorIndex === vendorIndex) && (
                                    <div className='absolute w-[270px] z-10 bg-white shadow-md border border-[#FFA653] p-0 h-fit whitespace-nowrap'>
                                        <div className='p-2 space-y-2 text-xs w-full'>
                                            <div className='flex items-center justify-between'>
                                                <label >
                                                    Brand
                                                </label>
                                                <div className='relative max-w-3/4 ml-2 group'>
                                                    <p className='px-2 text-xs whitespace-normal break-words border-none truncate  bg-[#F0F0F0] text-[#404040] text-right cursor-default'>{vendorItem.brand || "NA"}</p>
                                                    {/* {vendorItem.brand && (
                                                    <div className="absolute bottom-full break-words mb-1 hidden group-hover:flex px-2 py-1 bg-[#f0f0f0] text-[#404040] text-xs rounded shadow-lg">
                                                        {vendorItem.brand}
                                                    </div>
                                                )} */}
                                                </div>
                                            </div>
                                            <label className='flex items-center justify-between w-full'>
                                                <span>Quantity</span>
                                                <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right' >{vendorItem.quantity || "0"} </p>
                                            </label>
                                            <label className='flex items-center justify-between w-full'>
                                                <span>GST (%)</span>
                                                <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.gst || "0"} </p>
                                            </label>
                                            <label className='flex items-center justify-between w-full'>
                                                <span>Discount (%)</span>
                                                <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.discount || "0"} </p>
                                            </label>
                                            <label className='flex items-center justify-between w-full'>
                                                <span>Total (Rs.)</span>
                                                <p className='px-2 text-xs border-none bg-[#F0F0F0] text-[#404040] text-right'>{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(vendorItem.netPrice ?? 0)} </p>
                                            </label>
                                        </div>
                                        <div className=' p-2'>
                                            <p className='text-[#434343]'>Remarks:</p>
                                            <p
                                                placeholder='Note'
                                                className='placeholder:italic break-words whitespace-normal max-h-[60px] overflow-y-auto text-xs cursor-default resize-none border-[#FFA653] border-dashed'
                                            >{ReactHtmlParser(vendorItem.remark) || "NA"}
                                            </p>
                                        </div>


                                        {vendorItem.attachments && vendorItem.attachments.length > 0 && (
                                            <div className="p-2">
                                                <h3 className="text-xs font-medium text-[#434343]">Attachments</h3>
                                                <div className="space-y-2">
                                                    {vendorItem.attachments.map((attachment, index) => {
                                                        const lastIndex = attachment.Name.lastIndexOf('.'); // Extract file extension
                                                        const path = lastIndex !== -1 ? attachment.Name.substring(lastIndex + 1) : null;
                                                        return (
                                                            <div key={index} className="flex justify-between items-center mt-2">
                                                                <div className="px-1 flex items-center justify-center border border-gray-300 rounded-full py-1 space-x-1 text-[#434343]">
                                                                    {/* Dynamic icon based on file type */}
                                                                    <img src={`/assets/icons/${path}.png`} alt={path} className="h-5 w-5" />
                                                                    {/* Document name now clickable */}
                                                                    <a
                                                                        href={attachment.Content}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download={attachment.Name}
                                                                        className="text-xs truncate text-[#434343] hover:underline"
                                                                    >
                                                                        {attachment.Name}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                                }
                            </td>
                        );
                    })}
                </tr>
            ))}
        </>
    );
};

export default MaterialRFQTable;
