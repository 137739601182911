import React from 'react';
import ReactHtmlParser from "react-html-parser";

const LabourRFQTable = ({ rfqData, sortedSuppliers, withGst, openDropdown, handleMaterialDropdown }) => {
    // Calculate lowest rates per labour item based on labourItem._id
    const lowestRates = {};

    rfqData?.labourDetails?.forEach((labourItem) => {
        let minRate = Infinity;
        let minSupplierIndexes = [];

        sortedSuppliers?.forEach((supplier, vendorIndex) => {
            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
            const vendorItem = vendorQuotation?.labourItems?.find(item => item.id === labourItem._id);

            if (vendorItem && vendorItem.labourRate > 0) { // Ignore labourRate === 0
                if (vendorItem.labourRate < minRate) {
                    minRate = vendorItem.labourRate;
                    minSupplierIndexes = [vendorIndex]; // Reset and store new lowest index
                } else if (vendorItem.labourRate === minRate) {
                    minSupplierIndexes.push(vendorIndex); // Add vendor to lowest list
                }
            }
        });

        // If no valid rates were found, default to 0
        lowestRates[labourItem._id] = {
            minRate: minRate === Infinity ? 0 : minRate,
            minSupplierIndexes
        };
    });

    return (
        rfqData?.labourDetails?.map((labourItem, rowIndex) => (
            <tr key={rowIndex} className='bg-white'>
                <div className='sticky left-0 w-[340px] z-10 bg-white' style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
                    <td className="flex items-center bg-white w-full py-2 text-xs z-10 sticky left-0 border-gray-300 pl-4 pr-2">
                        <div className='flex space-x-2 w-full'>
                            <p>{rowIndex + 1}. </p>
                            <p className="text-black w-full bg-white">
                                {ReactHtmlParser(labourItem?.skill)}
                                <b>Qty : </b> {labourItem?.quantity} {labourItem?.unit}
                            </p>
                        </div>
                        <div className='flex justify-end w-[30%]'>
                            <p className='text-xs text-[#2D2D11] break-words'>
                                @ Rs. {new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(lowestRates[labourItem._id]?.minRate ?? 0)}
                            </p>
                        </div>
                    </td>
                </div>

                {sortedSuppliers?.map((supplier, vendorIndex) => {
                    const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                    const vendorItem = vendorQuotation?.labourItems?.[rowIndex] || {};
                    const supplierRate = vendorItem.labourRate;
                    const isLowest = lowestRates[labourItem._id]?.minSupplierIndexes.includes(vendorIndex);

                    return (
                        <td key={vendorIndex} className={`min-w-[300px] py-1 px-4 z-0 border border-gray-300 ${vendorIndex < rfqData?.suppliers?.length - 1 ? 'border-r' : ''}`}>
                            <div onClick={() => handleMaterialDropdown(rowIndex, vendorIndex)} className='relative flex justify-between items-end py-1'>
                                <div className='w-full'>
                                    {vendorItem.workStartDate && rfqData.providingDate && (
                                        new Date(vendorItem.workStartDate).getTime() !== new Date(rfqData.providingDate).getTime() && (
                                            <p className='text-xs text-[#DC7604]'>
                                                Proposed: {new Date(vendorItem.workStartDate).toLocaleDateString('en-GB')}
                                            </p>
                                        )
                                    )}
                                    <div className='flex justify-between items-center gap-4 cursor-pointer'>
                                        <p className={`text-xs text-[#777777] ${isLowest ? 'bg-lime-200 px-1' : ''}`}>
                                            @ Rs. {new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(vendorItem?.labourRate ?? 0)} per qty.
                                        </p>
                                        {withGst && <p className='text-xs text-[#777777]'> GST: {vendorItem?.gst} %</p>}
                                        <div className='flex space-x-4 items-center'>
                                            {vendorItem?.attachments?.length > 0 && <div className='relative'>
                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/files.svg" alt="" className='w-3 h-3 ' />
                                                <div className='bg-[#f05252] rounded-full -top-2 -right-2 h-3 w-3 flex justify-center items-center  absolute'>
                                                    <p className='text-[10px] text-white text-center pt-[3px]'>{vendorItem?.attachments?.length}</p>
                                                </div>
                                            </div>}
                                            <p className='text-xs font-normal text-[#2D2D11] ml-auto'>
                                                Rs. {withGst
                                                    ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(vendorItem.totalAmount ?? 0)
                                                    : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(vendorItem?.priceWithoutGst ?? 0)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(openDropdown.rowIndex === rowIndex && openDropdown.vendorIndex === vendorIndex) && (
                                <div className='absolute text-xs w-[270px] py-2 overflow-y-auto z-10 bg-white border border-[#FFA653] p-0 h-fit whitespace-nowrap'>
                                    <div className=' px-4 space-y-2 text-xs'>
                                        <label className='flex items-center justify-between w-full'>
                                            <span>Quantity</span>
                                            <p className='px-2 text-xs border-none bg-[#F0F0F0] placeholder:text-[#404040] text-right'> {vendorItem?.quantity} {labourItem?.unit} </p>
                                        </label>
                                        <label className='flex items-center justify-between w-full'>
                                            <span>Amount (Rs.)</span>
                                            <p className='px-2 text-xs border-none bg-[#F0F0F0] placeholder:text-[#404040] text-right'>{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(vendorItem.labourRate ?? 0)} </p>
                                        </label>
                                        <label className='flex items-center justify-between w-full'>
                                            <span>GST (%)</span>
                                            <p className='px-2 text-xs border-none bg-[#F0F0F0] placeholder:text-[#404040] text-right'>{vendorItem?.gst} </p>
                                        </label>
                                        <label className='flex items-center justify-between w-full'>
                                            <span>Total (Rs.)</span>
                                            <p className='px-2 text-xs border-none bg-[#F0F0F0] placeholder:text-[#404040] text-right'>{vendorItem?.totalAmount} </p>
                                        </label>
                                    </div>
                                    {/* <textarea placeholder='Remarks' className='placeholder:italic resize-none w-full border-t-[#FFA653] border-transparent border-dashed'>{vendorItem.remarks}</textarea> */}

                                    <div className=' px-4 pt-2'>
                                        <p className='text-[#434343] font-semibold text-xs'>Remarks:</p>
                                        <p
                                            placeholder='Note'
                                            className='placeholder:italic break-words whitespace-normal max-h-[60px] overflow-y-auto text-xs cursor-default resize-none border-[#FFA653] border-dashed'
                                        >{vendorItem.remarks || "NA"}
                                        </p>
                                    </div>

                                    {vendorItem.attachments && vendorItem.attachments.length > 0 && (
                                        <div className="px-4 py-2">
                                            <h3 className="text-xs font-semibold text-[#434343]">Attachments</h3>
                                            <div className="space-y-2">
                                                {vendorItem.attachments.map((attachment, index) => {
                                                    const lastIndex = attachment.Name.lastIndexOf('.'); // Extract file extension
                                                    const path = lastIndex !== -1 ? attachment.Name.substring(lastIndex + 1) : null;
                                                    return (
                                                        <div key={index} className="flex justify-between items-center mt-2">
                                                            <div className="px-1 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-1 text-[#434343]">
                                                                {/* Dynamic icon based on file type */}
                                                                <img src={`/assets/icons/${path}.png`} alt={path} className="h-5 w-5" />
                                                                {/* Document name now clickable */}
                                                                <a
                                                                    href={attachment.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    download={attachment.Name}
                                                                    className="text-xs truncate text-[#434343] hover:underline"
                                                                >
                                                                    {attachment.Name}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </td>
                    );
                })}
            </tr>
        ))
    );
};

export default LabourRFQTable;
