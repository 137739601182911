import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CustomGstDropdown from './CustomGstDropdown';
import ReactQuill from "react-quill";
import Skeleton from "@mui/material/Skeleton"
import ReactHtmlParser from "react-html-parser";
import { handleFileUploadClick, handleFileUpload, handleDeleteSelectedFile, handleCloseFileSizeError } from "./fileUploadHelpers";
import RfqInformation from './RfqInformation';


const MaterialMobileQuotatoinForm = ({ values, setFieldValue, currentStep,
    steps,
    openAccordian,
    toggleAccordion,
    handleDownloadMaterialExcel,
    handleUploadMaterialExcel,
    rfqData,
    toggleExpanded,
    expandedStates,
    isSpecsPopupOpen,
    togglePopupSpecs,
    handleFieldChange,
    overallTotal,
    overallTotalExclGST,
    totalMaterialCostsExclGST,
    currentRank,
    setSelectedMaterialIndex,
    setOpenUploadFile,
    isReadOnly,
    totalCosts,
    visibleMiscellaneousItems,
    handleMiscellaneousChange,
    handleGstChange,
    attachmentsData,
    togglePopup,
    isPopupOpen,
    setIsCounterTerm,
    isCounterTerm,
    attachmentsSkeleton,
    selectedFilesBase,
    attachmentsUploadsErrors,
    openFilesSizeError,
    selectedFilesTotalSize,
    setAttachmentsSkeleton,
    setSelectedFilesBase,
    setSelectedFilesTotalSize,
    setOpenFilesSizeError,
    setAttachmentsUploadsErrors }) => {
    return (
        <div className="block lg:hidden h-screen flex-grow overflow-auto">
            {/* Stepper */}
            {currentStep === 0 ? " " : (
                <div className="sticky top-0 z-20 shadow-md bg-white flex justify-center items-center px-10 py-3 w-full lg:hidden">
                    {/* Step 1 */}
                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 0 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                        <p className={`text-xs font-medium ${currentStep > 0 ? 'text-white' : '#313119'}`}>1</p>
                    </div>
                    <div className={`flex-grow h-[2px] ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                    {/* Step 2 */}
                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                        <p className={`text-xs font-medium ${currentStep > 1 ? 'text-white' : '#313119'}`}>2</p>
                    </div>
                    <div className={`flex-grow h-[2px] ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                    {/* Step 3 */}
                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                        <p className={`text-xs font-medium ${currentStep > 2 ? 'text-white' : '#313119'}`}>3</p>
                    </div>
                    <div className={`flex-grow h-[2px] ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                    {/* Step 4 */}
                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                        <p className={`text-xs font-medium ${currentStep > 3 ? 'text-white' : '#313119'}`}>4</p>
                    </div>
                </div>
            )}

            {/* stepper content */}
            <div className="h-auto flex-grow overflow-y-auto">
                <div className="flex justify-between">
                    {steps.map((step, index) => (
                        <div key={index} className={`step ${currentStep === index ? 'active' : ''}`}>
                            {/* {step} */}
                        </div>
                    ))}
                </div>

                <div className="relative step-content">
                    {currentStep === 0 && (
                        <div>
                            {/* Header Section */}
                            <div className=" lg:grid lg:grid-cols-2 flex items-center flex-col-reverse lg:px-8 lg:py-4 shadow-md sticky top-0 bg-white z-10">
                                <div >
                                    <div className="lg:flex items-center hidden  ">
                                        <h1 className="text-[28px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)}</h1>
                                        <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 sticky top-0 flex items-center justify-center shadow-md">
                                <div >
                                    {/* <div className="flex items-center space-x-2">
                                        <span class="relative flex items-center justify-center h-4 w-4 ">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                            <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                        </span>
                                        <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                            Quotation Rank:{" "}
                                            <span className="font-bold text-black">
                                                {currentRank === "L1"
                                                    ? "L2"
                                                    : currentRank === undefined || currentRank === null
                                                        ? "Quotation not submitted"
                                                        : currentRank}
                                            </span>
                                        </h3>
                                        <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p>
                                    </div> */}
                                </div>
                            </div>
                            {/* rfq information */}
                            <div className="">
                                <div className="px-4 lg:py-[24px] lg:px-8 mt-4 mb-1 lg:hidden">
                                    {/* <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.name}</h3> */}
                                    <h3 className="text-sm font-semibold text-[#3B3B3B]"><span className="text-[#787878]">Requirement Name:</span> {rfqData?.name}</h3>
                                    {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" /> */}
                                
                                <p className="text-sm font-normal text-[#787878] lg:hidden">Create Date: {new Date(rfqData?.createdAt).toLocaleDateString('en-GB')}</p>
                                <p className="text-sm font-medium text-[#787878] mt-2 lg:hidden">RFQ Information</p>
                                </div>
                                <RfqInformation 
                                rfqData={rfqData}
                                togglePopup={togglePopup}
                                isPopupOpen={isPopupOpen}
                                />
                            </div>

                            {
                                <div className="flex flex-col items-end m-6">
                                    <div className="flex space-x-4">
                                        <button
                                            type="button"
                                            onClick={handleDownloadMaterialExcel}
                                            className="bg-blue-500 text-white px-3 py-1.5 text-xs"
                                        >
                                            Download Excel
                                        </button>

                                        <label className="border border-gray-800 px-3 py-1.5 cursor-pointer text-xs">
                                            Upload Excel
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                onChange={(e) => handleUploadMaterialExcel(e, setFieldValue, values)}
                                                className="hidden"
                                            />
                                        </label>
                                    </div>
                                    <p className="text-xs text-gray-500 mt-2">Note: Download Excel to upload material list</p>
                                </div>
                            }


                        </div>
                    )}
                    <div className="px-4 py-6">
                        {currentStep === 1 && (
                            <div>
                                {/* input fields */}
                                <div>
                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Material List</h3>
                                    <p className="text-xs font-normal text-[#9D9D9D] lg:hidden">Add quotations for the Material List below</p>

                                    <div className="space-y-2 mt-4 mb-8">
                                        {rfqData?.materials?.map((row, index) => (
                                            <div key={index}>
                                                {/* Mobile Accordion Header */}
                                                <div className={`block lg:hidden border shadow-md py-4 px-4 cursor-pointer ${openAccordian === index ? 'shadow-none' : ''}`} onClick={() => toggleAccordion(index)}>
                                                    <div className="flex justify-between items-center">
                                                        <div className="flex justify-start items-center space-x-4">
                                                            <div className="w-[24px] h-[24px] bg-[#EFEFEB] rounded-full flex justify-center items-center">
                                                                <p className="text-xs text-[#6A6A6A]">{index + 1}</p>
                                                            </div>
                                                            <h2 className="text-sm font-semibold">{ReactHtmlParser(row?.name)}</h2>
                                                        </div>
                                                        <div className="flex justify-end space-x-4">
                                                            {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/tick_vendor_quotation.svg" alt="tick" /> */}
                                                            <img
                                                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/dropdown_arrow.svg"
                                                                alt=""
                                                                className={`ml-2 transition-transform ${openAccordian === index ? 'rotate-180' : ''}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Accordion Content */}
                                                {openAccordian === index && (
                                                    <div className="space-y-4 py-4 px-4 shadow-md border border-t-transparent">

                                                        {/* quantity */}
                                                        <div className="self-end">
                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Brand</label>
                                                            <p className="text-sm col-span-1">{row.brands.join(", ")}</p>
                                                        </div>

                                                        {/* quantity */}
                                                        <div className="self-end">
                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Quantity</label>
                                                            <p className="text-sm col-span-1">{row.quantity} {row.unit}</p>
                                                        </div>

                                                        {/* specifications */}
                                                        {row?.specifications?.length > 0 && <div className="self-end col-span-2">
                                                            <label htmlFor="" className="text-sm font-semibold pb-2 lg:hidden">Specifications</label>
                                                            <div className="col-span-6 min-h-fit">
                                                                {row?.specifications?.length > 0 && (
                                                                    <div className="col-span-4 text-xs">
                                                                        <p className="font-light whitespace-pre-line">
                                                                            {ReactHtmlParser(
                                                                                expandedStates[index]
                                                                                ? row?.specifications
                                                                                : row?.specifications?.substring(0, 160) +
                                                                                (row?.specifications?.length > 160
                                                                                ? "..."
                                                                                : "")
                                                                                )}
                                                                                </p>
                                                                            {row?.specifications?.length > 160 && (
                                                                                 <button
                                                                                 type="button"
                                                                               className="text-[#8E8E85] text-xs underline italic"
                                                                              onClick={() => toggleExpanded(index)}
                                                                                 >
                                                                               {expandedStates[index] ? "Read Less" : "Read More"}
                                                                                </button>
                                                                                )}
                                                                            </div>
                                                                          )}
                                                                    </div>
                                                        </div>}

                                                        {/* mrp */}
                                                        <div className="col-span-2 flex flex-col">
                                                            <label className="font-semibold text-sm pb-2 col-span-2 flex items-center relative">
                                                                MRP
                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                    i
                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                    </span>
                                                                </span>
                                                            </label>

                                                            <Field
                                                                name={`materials[${index}].basePrice`}
                                                                type="text"
                                                                className="lg:w-full w-[220px] text-sm h-[40px] border-[#DCDCD0] border px-2 py-1"
                                                                onChange={(e) => handleFieldChange(index, 'basePrice', e.target.value, values)}
                                                            />
                                                            <ErrorMessage name={`materials[${index}].basePrice`} component="div" className="text-red-500 text-sm" />
                                                        </div>
                                                        <div className="flex space-x-8 items-center">
                                                            {/* discount */}
                                                            <div className="col-span-2 self-start flex flex-col">
                                                                <label className="font-semibold text-sm pb-1 col-span-2 flex items-center relative">
                                                                    Discount (%)
                                                                    <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                        i
                                                                        <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                            Specify the discount percentage or amount offered on this material. This discount will be applied to the unit cost and reflected in the final pricing
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                                <Field
                                                                    name={`materials[${index}].discount`}
                                                                    type="text"
                                                                    className=" h-[40px] w-[100px] border-[#DCDCD0] border px-2 py-1"
                                                                    onChange={(e) => handleFieldChange(index, 'discount', e.target.value, values)}
                                                                />
                                                                <ErrorMessage name={`materials[${index}].discount`} component="div" className="text-red-500 text-sm" />
                                                            </div>

                                                            {/* gst */}
                                                            <div className="col-span-1 self-start w-1/4">
                                                                <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">GST (%)</label>
                                                                <CustomGstDropdown
                                                                    options={[0, 5, 12, 18, 28]}
                                                                    value={values.materials[index].gst}  // Display the current value
                                                                    onChange={(value) => handleFieldChange(index, 'gst', value, values)}
                                                                    className=" h-[40px] text-base"
                                                                />
                                                                <ErrorMessage name={`materials[${index}].gst`} component="div" className="text-red-500 text-sm h-fit" />
                                                            </div>
                                                        </div>

                                                        <div className="col-span-2 flex flex-col">
                                                            <label className="font-semibold text-sm pb-2 col-span-2 flex items-center relative">
                                                                Item Rate
                                                            </label>
                                                            <p className=" col-span-3 text-base">{new Intl.NumberFormat('en-IN').format(values.materials[index].basePrice - (values.materials[index].basePrice * values.materials[index].discount) / 100) || 0}</p>
                                                        </div>
                                                        {/* delivery date */}
                                                        {/* <div className="col-span-2 self-end">
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Delivery Date</label>
                                                                            <Field
                                                                                type="date"
                                                                                id={`deliveryDate-${index}`}
                                                                                name={`materials[${index}].deliveryDate`}
                                                                                className="shadow-sm block h-[40px] lg:w-full w-[150px] text-sm px-4 py-2 border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
                                                                                onChange={(e) => handleFieldChange(index, 'deliveryDate', e.target.value, values)} // Ensure you have this function defined
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`materials[${index}].deliveryDate`}
                                                                                component="div"
                                                                                className="text-red-500 text-xs"
                                                                            />
                                                                        </div> */}

                                                        <div className=" flex justify-start space-x-4 items-center text-center col-span-1 mt-2">
                                                            <p className="text-sm pb-2 font-semibold ">Add Attachment</p>
                                                            <div className="relative">
                                                                <AttachFileIcon
                                                                    className="hover:cursor-pointer"
                                                                    sx={{
                                                                        width: "0.7em", ml: "2px", mb: "8px",
                                                                        transform: 'rotate(45deg)',
                                                                        transition: 'transform 0.3s ease'
                                                                    }}
                                                                    onClick={() => {
                                                                        setSelectedMaterialIndex(index); // Set the labor index
                                                                        setOpenUploadFile(true); // Open the file upload popup
                                                                    }}
                                                                />

                                                                {values.materials[index]?.attachments?.length > 0 && (
                                                                    <span
                                                                        className="absolute top-0 right-2 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                        style={{ transform: 'translate(50%, -50%)' }}
                                                                    >
                                                                        {values.materials[index].attachments.length}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Remarks</label>

                                                            <style>
                                                                {`
                                                                        /* Remove padding from the ReactQuill editor's container */
                                                                        .no-padding .ql-container {
                                                                        padding: 4px !important;
                                                                        border: none !important;
                                                                      }
                                                                       .no-padding .ql-editor {
                                                                       padding: 4px !important;
                                                                       font-size: 0.75rem !important; /* Equivalent to Tailwind's text-xs */
                                                                       line-height: 1rem; /* Optional: Adjust line height for better readability */
                                                                     }
                                                                  `}
                                                            </style>
                                                            <ReactQuill
                                                                value={values?.materials?.[index]?.remark || ''}
                                                                name={`materials[${index}].remark`}
                                                                onChange={(value) => {
                                                                    handleFieldChange(index, 'remark', value, values);

                                                                    // Adjust the textarea height
                                                                    // e.target.style.height = "auto"; 
                                                                    // e.target.style.height = `${e.target.scrollHeight}px`; 
                                                                }}
                                                                disabled={isReadOnly && true}
                                                                theme="snow"
                                                                className="min-h-[40px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white border-[#DCDCD0] border w-full overflow-y-auto no-padding" // Set min and max height
                                                                modules={{ toolbar: false }}
                                                            // style={{ padding: "-10px" }}
                                                            /><ErrorMessage
                                                                name={`materials[${index}].remark`} component="div" className="text-red-500 text-xs" />
                                                        </div>

                                                        {/* amount */}
                                                        <div className="w-full py-2 px-4 flex justify-between items-center bg-[#E7EAEF] border-t-[#004491] lg:bg-white lg:border-none">
                                                            <label htmlFor="" className="text-base text-[#004491] font-semibold lg:hidden">Total (Excl. GST)</label>
                                                            <p className="text-right text-sm self-end text-[#004491] lg:text-black col-span-1">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.materials[index].totalExclGst || 0)}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentStep === 2 && (
                            <div>
                                {/* miscellaneous */}
                                <div>
                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Other Charges</h3>
                                    <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">Please indicate any miscellaneous charges that will be incurred</p>

                                    <div className="border lg:border-none shadow-md px-3 pb-3 pt-4 space-y-4 lg:space-y-2">
                                        {/* Mapping miscellaneousItems instead of charges */}
                                        {visibleMiscellaneousItems.map((item, index) => (
                                            <div key={index}>
                                                {/* MRP and GST inputs for mobile */}
                                                <div className="flex justify-between items-center lg:hidden pb-2">
                                                    <div >
                                                        <p className=" text-sm">{item.label}</p>
                                                        <Field
                                                            name={`miscellaneousCosts.${item.name}.mrp`}
                                                            type="text"
                                                            className="h-[40px] w-[120px] border border-gray-300"
                                                            placeholder="Enter MRP"
                                                            onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className=" text-sm">GST</p>
                                                        <CustomGstDropdown
                                                            options={[0, 5, 12, 18, 28]}
                                                            value={values.miscellaneousCosts[item.name]?.gst}
                                                            name={`miscellaneousCosts.${item.name}.gst`}
                                                            className="col-span-1 w-[64px] h-[40px]"
                                                            onChange={(value) => handleGstChange(item.name, value, setFieldValue, values)}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Subtotal display */}
                                                <div className="flex justify-between lg:justify-end items-center py-2 px-3 bg-[#E7EAEF] border border-t-[#004491] lg:border-none lg:bg-white">
                                                    <p className="text-[#004491] text-sm font-semibold lg:hidden">Total</p>
                                                    <p className="text-[#004491] font-semibold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                        Rs. {values.miscellaneousCosts[item.name].mrp || 0}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}

                                        {/* Total amount for mobile */}
                                        <div className="lg:hidden">
                                            <div className="flex px-4 py-2 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] lg:bg-white">
                                                <p className="lg:font-bold text-[#007366] lg:text-black font-semibold">Sub Total</p>
                                                <p className="text-[#007366] font-semibold text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                    Rs. {overallTotalExclGST || 0}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="lg:hidden">
                                            <div className="flex px-4 py-2 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] lg:bg-white">
                                                <p className="lg:font-bold text-[#007366] lg:text-black font-semibold">GST Total</p>
                                                <p className="text-[#007366] font-semibold text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                    Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((overallTotal - overallTotalExclGST).toFixed(2))}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="lg:hidden">
                                            <div className="flex px-4 py-2 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                <p className="lg:font-bold text-[#007366] lg:text-black font-semibold">Total Quotation Value (inc. GST)</p>
                                                <p className="bg-[#E0F0EE] text-[#007366] font-semibold text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                    Rs. {overallTotal || 0}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentStep === 3 && (
                            <div>
                                {/* Supporting Documents Section */}
                                <div className="mb-8 lg:mb-[40px]">
                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Supporting Documents</h3>
                                    <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                    {attachmentsData.length > 0 ? (
                                        <>
                                            <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4">
                                                {attachmentsData.map((doc, index) => (
                                                    <div key={index} className="flex justify-between items-center">
                                                        <div className="flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-2 px-2 space-x-2 text-[#434343]">
                                                            {/* Dynamic icon based on file type */}
                                                            <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                            {/* Document name clickable */}
                                                            <a
                                                                href={doc.Content}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                download={doc.Name}
                                                                className="text-xs text-[#434343] hover:underline"
                                                            >
                                                                {doc.Name}
                                                            </a>
                                                        </div>
                                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/download_solid.svg" alt="" className="lg:hidden" />
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ) :

                                        <>
                                            <div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>
                                        </>

                                    }
                                </div>
                            </div>
                        )}
                        {currentStep === 4 && (
                            <div>
                                {/* Additional Information Section */}
                                <div className="mb-8">

                                    <h3 className="text-xl lg:mx-0 font-semibold text-[#3B3B3B] lg:hidden">Additional Information</h3>
                                    <p className="text-sm font-normal lg:mx-0 py-4 lg:hidden">Below are our payment Terms. </p>

                                    <div className="lg:flex justify-between items-center">
                                        <div>
                                            <div className="bg-[#F6F6F4] py-2 px-4 lg:w-[390px] lg:h-[182px] lg:mx-0 " >
                                                <p
                                                    className="w-full border-none p-0 bg-[#F6F6F4] text-[#3D3D3D] text-sm"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            rfqData?.paymentTerms.length > 210
                                                                ? rfqData.paymentTerms.slice(0, 210) + "..."
                                                                : rfqData.paymentTerms,
                                                    }}
                                                ></p>

                                                {rfqData?.paymentTerms.length > 210 && <button
                                                    onClick={togglePopup}
                                                    type="button"
                                                    className="text-[#8E8E85] text-sm italic underline pt-2"
                                                >
                                                    Read More
                                                </button>}

                                                {/* Popup Modal */}
                                                {isPopupOpen && (
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                          scrollbar-width: none; /* Firefox */
                                                                             }
                                                                          .hide-scrollbar::-webkit-scrollbar {
                                                                           display: none; /* Safari and Chrome */
                                                                           }
                                                                         `}
                                                            </style>
                                                            <div className="sticky top-0 bg-white pb-4">
                                                                {/* Close Button */}
                                                                <button
                                                                    onClick={togglePopup}
                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                >
                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </button>
                                                                <h2 className="text-lg font-semibold">Payment Details</h2>
                                                                <hr />
                                                            </div>
                                                            <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="lg:hidden mx-4 justify-between items-center py-4">
                                            <p >
                                                Do you agree ?
                                            </p>
                                            <div className="flex space-x-12 mt-2">
                                                <label className="inline-flex items-center">
                                                    <input type="radio"
                                                        name="paymentTerms"
                                                        checked={values.agreedToPaymentTerms === true}
                                                        onChange={() => {
                                                            setFieldValue('agreedToPaymentTerms', true);
                                                            setIsCounterTerm(false); // Update counter term
                                                        }}
                                                        className="form-radio" />
                                                    <span className="ml-2">Yes</span>
                                                </label>
                                                <label className="inline-flex items-center">
                                                    <input type="radio"
                                                        name="paymentTerms"
                                                        checked={values.agreedToPaymentTerms === false}
                                                        onChange={() => {
                                                            setFieldValue('agreedToPaymentTerms', false);
                                                            setIsCounterTerm(true); // Update counter term
                                                        }}
                                                        className="form-radio" />
                                                    <span className="ml-2">Counter Offer</span>
                                                </label>
                                            </div>
                                        </div>
                                        {isCounterTerm && <div>
                                            <p className="font-semibold text-[#4F4F42]">Counter Payment Terms</p>
                                            <Field
                                                as="textarea"
                                                name="counterPaymentTerms"
                                                className="lg:w-[390px] w-full h-[144px] my-2 border border-gray-300 placeholder:italic"
                                                placeholder="Please write your counter payment terms Rich Text "
                                            />
                                        </div>}
                                    </div>
                                    <div className=" lg:flex mt-4 lg:mt-8 lg:mx-0">
                                        <p className="text-[#434343] mb-2 lg:mb-0">Any Remark for Client?</p>
                                        <Field
                                            as="textarea"
                                            name="notes"
                                            placeholder="Add Notes (Optional) "
                                            className="border-[#CDCDB8] w-full lg:ml-5 lg:w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                        />
                                    </div>
                                </div>

                                {/* Upload Supporting Documents */}
                                <div className="mb-8 ">
                                    <h3 className="whitespace-nowrap text-[#434343] text-xl font-bold pb-4 lg:pb-0 lg:mt-[60px] lg:mb-[30px]">Upload Supporting Documents</h3>

                                    {attachmentsSkeleton.length > 0 && (
                                        <p className="text-xs pl-3">
                                            Your file is being scanned for upload, be patient and continue to fill
                                            the rest of the form.
                                        </p>
                                    )}

                                    <div className="">
                                        {/* Display the selected file names */}
                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                            {selectedFilesBase.map((file, index) => (
                                                <a
                                                    href={file.Content}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                    key={file.Name}
                                                >
                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                        {file.Name}
                                                    </span>
                                                    <span
                                                        className="ml-1 cursor-pointer"
                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event, setSelectedFilesBase, setSelectedFilesTotalSize)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 48 48"
                                                        >
                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                            <line
                                                                x1="15"
                                                                y1="15"
                                                                x2="33"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                            <line
                                                                x1="33"
                                                                y1="15"
                                                                x2="15"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                        </svg>
                                                    </span>
                                                </a>
                                            ))}

                                            {/* Skeleton loaders for files being processed */}
                                            {attachmentsSkeleton.map((_, index) => (
                                                <Skeleton
                                                    key={index}
                                                    variant="rounded"
                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                    width={150}
                                                    height={32}
                                                />
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        {attachmentsUploadsErrors && (
                                            <div className="mt-3">
                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                    {attachmentsUploadsErrors}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    {/* Error handling for file size */}
                                    <div
                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                            }`}
                                    >
                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                            <p>Total size of attachments is not more than 10 MB</p>
                                            <button
                                                onClick={handleCloseFileSizeError}
                                                className="px-4 py-2 focus:outline-none"
                                            >
                                                <svg
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>


                                    {/* drag and drop file */}
                                    <div className="lg:ml-14 lg:mr-0 flex items-center justify-center h-[175px] lg:h-[301px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                        <div className="lg:hidden flex flex-col items-center justify-center">
                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" alt="" className="mx-auto" />
                                            <p className="text-center mt-4">Upload your files here</p>
                                            <label htmlFor="file" className="text-[#1D28FA] underline text-center cursor-pointer">
                                                Browse
                                            </label>
                                        </div>
                                        <input
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            onClick={handleFileUploadClick}
                                            onChange={(event) =>
                                                handleFileUpload(
                                                    event,
                                                    selectedFilesTotalSize,
                                                    setAttachmentsSkeleton,
                                                    setSelectedFilesBase,
                                                    setSelectedFilesTotalSize,
                                                    setOpenFilesSizeError,
                                                    setAttachmentsUploadsErrors
                                                )
                                            }
                                            multiple // Allow multiple files to be selected
                                        />

                                    </div>
                                </div>
                            </div>
                        )}

                        {currentStep === 5 &&

                            (<div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>)

                        }
                    </div>
                </div>


            </div>
        </div>
    )
}

export default MaterialMobileQuotatoinForm