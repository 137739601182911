import ExcelJS from "exceljs";
import { htmlToText } from "html-to-text";


export const handleDownloadExcelForContractor = async (isEditing, rfqData, contractorDetails, setExcelLoading, setLoadingMessage) => {
    console.log("contractorDetails", contractorDetails);
    try {
        setExcelLoading(true);
        setLoadingMessage("Preparing the Excel file for download...");

        // Load the Excel template
        const templatePath = "/assets/general/bulk_template_boq_rates.xlsx";
        const response = await fetch(templatePath);
        const buffer = await response.arrayBuffer();

        // Load workbook from the buffer
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);

        // Access the sheet
        const sheet = workbook.getWorksheet("bulk_material_rates");

        // Determine the data source
        const providingFixingDetails = isEditing ? contractorDetails : rfqData.providingFixingDetails;

        let rowIndex = 3; // Start from row 3

        providingFixingDetails.forEach((section, sectionIndex) => {
            // Add section row
            const sectionRow = sheet.getRow(rowIndex++);
            sectionRow.getCell(1).value = `S${sectionIndex + 1}`;
            sectionRow.getCell(2).value = section.id || section._id;
            sectionRow.getCell(3).value = section.sectionName;
            sectionRow.getCell(4).value = '';
            sectionRow.getCell(5).value = ''; // Qty
            sectionRow.getCell(6).value = ''; // Unit
            sectionRow.getCell(7).value = ''; // Supply_Rate
            sectionRow.getCell(8).value = ''; // Installation_Rate
            sectionRow.getCell(9).value = ''; // GST
            sectionRow.getCell(10).value = ''; // Vendor_Remarks
            sectionRow.getCell(11).value = ''; // SupplyCost formula placeholder
            sectionRow.getCell(12).value = ''; // InstallationCost formula placeholder
            sectionRow.getCell(13).value = ''; // TotalBasicCost formula placeholder
            sectionRow.getCell(14).value = section.sectionTotalWithGst;

            // Process scopes in the section
            section.scopes.forEach((scope, scopeIndex) => {
                const currentRowIndex = rowIndex;
                const scopeRow = sheet.getRow(rowIndex++);
                scopeRow.getCell(1).value = `${scopeIndex + 1}`;
                scopeRow.getCell(2).value = scope.id || scope._id;
                scopeRow.getCell(3).value = htmlToText(scope.scopeOfWork);
                scopeRow.getCell(4).value = htmlToText(scope.preferredBrands.join(", "));
                scopeRow.getCell(5).value = scope.quantity;
                scopeRow.getCell(6).value = scope.unit;
                scopeRow.getCell(7).value = scope.basePrice;
                scopeRow.getCell(8).value = scope.installationRate;
                scopeRow.getCell(9).value = `${scope.gst}%`;
                scopeRow.getCell(10).value = htmlToText(scope.comments);

                // Apply formulas for calculations
                scopeRow.getCell(11).value = { formula: `E${currentRowIndex}*G${currentRowIndex}` }; // SupplyCost
                scopeRow.getCell(12).value = { formula: `E${currentRowIndex}*H${currentRowIndex}` }; // InstallationCost
                scopeRow.getCell(13).value = { formula: `K${currentRowIndex}+L${currentRowIndex}` }; // TotalBasicCost
                scopeRow.getCell(14).value = { formula: `M${currentRowIndex}*(1+I${currentRowIndex})` }; // Total_Amount_with_GST

                // Process materials in the scope
                scope.materials.forEach((material, materialIndex) => {
                    const materialRowIndex = rowIndex;
                    const materialRow = sheet.getRow(rowIndex++);
                    materialRow.getCell(1).value = `${scopeIndex + 1}.${materialIndex + 1}`;
                    materialRow.getCell(2).value = material.id || material._id;
                    materialRow.getCell(3).value = htmlToText(material.name);
                    materialRow.getCell(4).value = material.brands.join(", ");
                    materialRow.getCell(5).value = material.quantity;
                    materialRow.getCell(6).value = material.unit;
                    materialRow.getCell(7).value = material.basePrice;
                    materialRow.getCell(8).value = material.installationRate;
                    materialRow.getCell(9).value = `${material.gst}%`;
                    materialRow.getCell(10).value = htmlToText(material.remarks);

                    // Apply formulas for calculations
                    materialRow.getCell(11).value = { formula: `E${materialRowIndex}*G${materialRowIndex}` }; // SupplyCost
                    materialRow.getCell(12).value = { formula: `E${materialRowIndex}*H${materialRowIndex}` }; // InstallationCost
                    materialRow.getCell(13).value = { formula: `K${materialRowIndex}+L${materialRowIndex}` }; // TotalBasicCost
                    materialRow.getCell(14).value = { formula: `M${materialRowIndex}*(1+I${materialRowIndex})` }; // Total_Amount_with_GST
                });
            });
        });

        // Generate and download the updated Excel file
        const blob = await workbook.xlsx.writeBuffer();
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Contractor_Quotation_${rfqData?.rfqId}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);

    } catch (error) {
        console.error("Error processing Contractor Excel file:", error);
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};




export const handleUploadExcelForContractor = async (
    event,
    setFieldValue,
    values,
    rfqData,
    setExcelLoading,
    setLoadingMessage,
    enqueueSnackbar,
    calculateOverallTotals,
    calculateMaterialTotals,
    calculateScopeTotals
) => {
    try {
        setExcelLoading(true);
        setLoadingMessage("Processing the uploaded Excel file...");

        const file = event.target.files[0];
        if (!file) return;

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(await file.arrayBuffer());

        // Check if the correct sheet is present
        const sheet = workbook.getWorksheet("bulk_material_rates");
        if (!sheet) {
            enqueueSnackbar("Invalid file. 'bulk_material_rates' sheet is missing.", { variant: "error" });
            return;
        }

        let updatedFixingDetails = [...values.providingFixingDetails];
        const errors = [];

        sheet.eachRow((row, rowNumber) => {
            if (rowNumber < 3) return; // Skip header rows

            const neevayId = row.getCell(2).value; // Unique ID (Section/Scope/Material)

            // Find the matching section
            let section = updatedFixingDetails.find(sec => sec.id === neevayId || sec._id === neevayId);
            if (section) {
                // section.basePrice = row.getCell(7).value;
                // section.installationRate = row.getCell(8).value;
                // section.gst = row.getCell(9).value;
                // section.remarks = row.getCell(10).value;
                return;
            }

            // Find the matching scope within sections
            for (let sec of updatedFixingDetails) {
                let scope = sec.scopes.find(scp => scp.id === neevayId || scp._id === neevayId);
                if (scope) {
                    scope.basePrice = row.getCell(7).value;
                    scope.installationRate = row.getCell(8).value;
                    scope.gst = parseFloat(row.getCell(9).value) || 0;
                    scope.gst = scope.gst > 1 ? scope.gst : scope.gst * 100;
                    scope.comments = row.getCell(10).value;
                    return;
                }

                // Find the matching material within scopes
                for (let scp of sec.scopes) {
                    let material = scp.materials.find(mat => mat.id === neevayId || mat._id === neevayId);
                    if (material) {
                        material.basePrice = row.getCell(7).value;
                        material.installationRate = row.getCell(8).value;
                        material.gst = parseFloat(row.getCell(9).value) || 0;
                        material.gst = material.gst > 1 ? material.gst : material.gst * 100; // Fix GST issue
                        material.remarks = row.getCell(10).value;

                        // Recalculate material totals
                        const { netPrice, totalExclGst, totalWithGst, InstallationCost } = calculateMaterialTotals(material);
                        material.netPrice = netPrice;
                        material.totalExclGst = totalExclGst;
                        material.totalWithGst = totalWithGst;
                        material.installationCost = InstallationCost;
                        return;
                    }
                }
            }

            errors.push(`Invalid ID at row ${rowNumber}: No matching section, scope, or material found.`);
        });

        if (errors.length > 0) {
            enqueueSnackbar(errors.join("\n"), { variant: "error" });
            return;
        }

        // Recalculate all totals
        updatedFixingDetails = updatedFixingDetails.map(section => {
            section.scopes = section.scopes.map(scope => {
                const scopeTotals = calculateScopeTotals(scope.materials, scope);
                return { ...scope, ...scopeTotals };
            });
            return section;
        });

        console.log("Updated Fixing Details before setting:", updatedFixingDetails);
        setFieldValue("providingFixingDetails", updatedFixingDetails);
        console.log("Formik values after setting:", values.providingFixingDetails);

        const overallTotals = calculateOverallTotals(values.providingFixingDetails, values.miscellaneousCosts);

        // Update Formik values

        setFieldValue("overallExclGst", overallTotals.overallExclGst);
        setFieldValue("overallWithGst", overallTotals.overallWithGst);



        enqueueSnackbar("Excel uploaded and data updated successfully!", { variant: "success" });
    } catch (error) {
        console.error("Error processing Excel file:", error);
        enqueueSnackbar("Failed to upload Excel file.", { variant: "error" });
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};