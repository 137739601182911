import { useState } from "react";

const SearchableDropdown = ({ value, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setTimeout(() => setIsOpen(false), 200)} // Delay to allow option click
        onChange={(e) => {
          setSearchTerm(e.target.value);
          onChange(e.target.value);
        }}
        className="border border-gray-300 focus:ring-0 p-1 h-[28px] w-full text-xs"
        // placeholder="unit"
      />

       {/* Clickable Rotating Arrow Icon */}
    <img
      src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
      alt="toggle"
      onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown
      className={`w-2 h-2 cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2 transition-transform duration-200 ${
        isOpen ? "rotate-180" : "rotate-0"
      }`}
    />
      {isOpen && (
        <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg max-h-40 overflow-y-auto w-full">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="px-2 py-1 cursor-pointer hover:bg-gray-100 text-xs"
                onMouseDown={() => {
                  onChange(option);
                  setIsOpen(false);
                  setSearchTerm("");
                }}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="px-2 py-1 text-xs text-gray-500">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;