import React, { useEffect, useState, useRef } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StaticPopup from 'components/StaticPopup';
import * as XLSX from 'xlsx';
import { UNITOPTIONS } from 'config'
const downloatTemplate = "https://storagereponeevaydevcdn.blob.core.windows.net/business/laborBulkUpload.xlsx"


const RfqTypeLabour = () => {
    const { values, setFieldValue } = useFormikContext();
    const labourDetails = values.labourDetails;

    const [filteredUnits, setFilteredUnits] = useState(UNITOPTIONS);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (labourDetails.length === 0) {
            setFieldValue('labourDetails', [{ skill: '', quantity: '', unit: '' }]);
        }
    }, []);

    const handleBulkUpload = () => {
        setOpenUploadFile(!openUploadFile)
    }

    const addInputField = () => {
        const newLabourDetails = [...labourDetails, { skill: '', quantity: '', unit: '' }];
        setFieldValue('labourDetails', newLabourDetails);
    };

    const removeInputField = (index) => {
        if (labourDetails.length > 1) {
            const newLabourDetails = labourDetails.filter((_, i) => i !== index);
            setFieldValue('labourDetails', newLabourDetails);
        }
    };

    const handleRemarkChange = (value, index) => {
        // Only update if the value is different to avoid unnecessary state updates
        if (labourDetails[index]?.skill !== value) {
            const updatedLabourDetails = labourDetails.map((detail, i) => (
                i === index ? { ...detail, skill: value } : detail
            ));
            setFieldValue('labourDetails', updatedLabourDetails);
        }
    };


    const handleUnitChange = (value, index) => {
        const updatedLabourDetails = labourDetails.map((detail, i) => (
            i === index ? { ...detail, unit: value } : detail
        ));
        setFieldValue('labourDetails', updatedLabourDetails);
    };

    const handleUnitFocus = (index) => {
        // When the unit input field is focused, reset the filtered options to show all
        setFilteredUnits(UNITOPTIONS);
        setActiveDropdownIndex(index); // Activate the dropdown for this index
    };

    const handleUnitSearch = (value, index) => {
        const filtered = UNITOPTIONS.filter(option => option.toLowerCase().includes(value.toLowerCase()));
        setFilteredUnits(filtered);
        handleUnitChange(value, index);
    };


    const mapSheetDataToLabour = (sheetData, unitMapping) => {
        // Skip the header row and map data rows to labour details structure
        return sheetData.slice(1).map((row, index) => {
            const unitCustomName = row[2]?.toLowerCase().trim(); // `Unit_Code` is at index 2
            const standardUnit = unitMapping[unitCustomName] || unitCustomName; // Map to standard unit or fallback

            // Validate required fields: `Work_Description`, `Quantity`, and `Unit_Code`
            if (!row[0] || !row[1] || !row[2]) {
                throw new Error(
                    `Row ${index + 2}: Missing required fields (Work_Description, Quantity, Unit_Code)`
                );
            }

            return {
                skill: row[0] || "", // Work_Description column
                quantity: row[1] || "", // Quantity column
                unit: standardUnit, // Standardized unit
            };
        });
    };

    const handleFileUploadLabour = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // Parse the Instructions Sheet
                const instructionsSheet = workbook.Sheets["Instructions (Must Read)"];
                if (!instructionsSheet) {
                    alert("Instructions sheet is missing.");
                    return;
                }

                const instructionsData = XLSX.utils.sheet_to_json(instructionsSheet, {
                    header: 1,
                    range: "A14:B37", // Parse rows 14–37
                });

                // Create a mapping from standard units to custom names
                const unitMapping = {};
                instructionsData.forEach((row) => {
                    if (row[0] && row[1]) {
                        unitMapping[row[1].toLowerCase()] = row[0];
                    }
                });

                // Parse the Labour Details Sheet
                const labourSheet = workbook.Sheets["Labor Details"];
                if (!labourSheet) {
                    alert("Labour Details sheet is missing.");
                    return;
                }

                const labourData = XLSX.utils.sheet_to_json(labourSheet, {
                    header: 1, // Include headers
                });

                // Validate Labour Data
                const requiredHeaders = ["work_description", "quantity", "unit_code"];
                const fileHeaders = labourData[0]?.map((header) => header.toLowerCase().trim());
                const hasValidHeaders = requiredHeaders.every((header) => fileHeaders.includes(header));

                if (!hasValidHeaders) {
                    alert("Invalid file format. Please ensure headers include Work_Description, Quantity, and Unit_Code.");
                    return;
                }

                if (labourData.length <= 1) {
                    alert("The uploaded sheet contains no data rows. Please provide labour details.");
                    return;
                }

                // Convert and Save Labour Data
                const uploadedLabourDetails = mapSheetDataToLabour(labourData, unitMapping);
                console.log("Final Labour Data with Converted Units:", uploadedLabourDetails);

                if (
                    labourDetails.length === 1 &&
                    labourDetails[0].skill === "" &&
                    labourDetails[0].quantity === "" &&
                    labourDetails[0].unit === ""
                ) {
                    labourDetails.pop(); // Remove initial empty field
                }

                const updatedLabourDetails = [...labourDetails, ...uploadedLabourDetails];
                if (JSON.stringify(updatedLabourDetails) !== JSON.stringify(labourDetails)) {
                    setFieldValue("labourDetails", updatedLabourDetails);
                }
                setOpenUploadFile(false)
            };

            reader.readAsArrayBuffer(file); // Trigger file reading
        }
    };


    return (
        <div>
            <div className="space-y-6">
                <div>
                    <div className="flex justify-between mb-2">
                        <h3 className="text-sm font-medium self-end">Labour/Item Rate</h3>
                        <button
                            type="button"
                            className=" border-gray-300 rounded-sm border hover:border-black px-2 py-1 text-sm flex items-center justify-center text-left whitespace-nowrap"
                            onClick={handleBulkUpload}
                        >
                            <img
                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_bulk_upload.svg"
                                alt=""
                                className="pr-2"
                            />
                            Bulk Upload
                        </button>
                    </div>
                    <div className="border border-gray-300">
                        <div className="grid grid-cols-17 gap-2 p-2 mb-1 text-sm text-[#777777] bg-gray-100">
                            <p className="text-center">Sr#</p>
                            <p className="col-span-11 text-center">Work Description</p>
                            <p className="col-span-2">Qty</p>
                            <p className="col-span-2">Unit</p>
                            <p></p>
                        </div>
                        <div className="">
                            {labourDetails.map((field, index) => (
                                <div key={index} className="grid grid-cols-17 m-1 gap-1 items-start">
                                    <p className="text-center text-sm">{index + 1}</p>

                                    {/* Work Description */}
                                    <div className="col-span-11">
                                        <ReactQuill
                                            value={field?.skill}
                                            onChange={(value) => handleRemarkChange(value, index)}
                                            theme="snow"
                                            className="min-h-[10px] max-h-[60px] p-0 word-break whitespace-normal focus:outline-none focus:ring-0 text-sm w-full overflow-y-auto remove-quill-padding"
                                            modules={{ toolbar: false }}
                                        />
                                        <ErrorMessage
                                            name={`labourDetails[${index}].skill`}
                                            component="div"
                                            className="text-red-500 text-xs mt-1"
                                        />
                                    </div>

                                    {/* Quantity */}
                                    <div className="col-span-2">
                                        <Field
                                            type="text"
                                            name={`labourDetails[${index}].quantity`}
                                            className="h-[30px] px-1 text-sm border focus:outline-none focus:ring-0 border-gray-300 w-full"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                        />
                                        <ErrorMessage
                                            name={`labourDetails[${index}].quantity`}
                                            component="div"
                                            className="text-red-500 text-xs mt-1"
                                        />
                                    </div>

                                    {/* Unit with Dropdown */}
                                    <div className="col-span-2 relative text-xs" ref={dropdownRef}>
                                        <input
                                            type="text"
                                            value={field.unit}
                                            onChange={(e) => handleUnitSearch(e.target.value, index)}
                                            onFocus={() => handleUnitFocus(index)}
                                            onBlur={() => setTimeout(() => setActiveDropdownIndex(null), 200)}
                                            className="h-[30px] px-1 text-xs border focus:outline-none focus:ring-0 border-gray-300 w-full"
                                        />
                                        <img
                                            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                            alt="toggle"
                                            onClick={() => setActiveDropdownIndex(activeDropdownIndex === index ? null : index)} // Toggle dropdown
                                            className={`w-2 h-2 bg-white cursor-pointer absolute right-1 top-1/2 transform -translate-y-1/2 transition-transform duration-200 ${activeDropdownIndex === index
                                                    ? "rotate-180"
                                                    : "rotate-0"
                                                }`}
                                        />
                                        <ErrorMessage
                                            name={`labourDetails[${index}].unit`}
                                            component="div"
                                            className="text-red-500 text-xs mt-1"
                                        />

                                        {activeDropdownIndex === index && (
                                            <ul className="absolute z-50 bg-white border max-h-[120px] overflow-y-auto w-full mt-1 shadow-lg">
                                                {filteredUnits.map((option, i) => (
                                                    <li
                                                        key={i}
                                                        onMouseDown={() => handleUnitChange(option, index)}
                                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                                    >
                                                        {option}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                    {/* Add/Remove Buttons */}
                                    <div className="col-span-1 flex justify-evenly items-center">
                                        <button type="button" className="w-fit text-lg" onClick={addInputField}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v14m7-7H5" />
                                            </svg>
                                        </button>
                                        {labourDetails.length > 1 && (
                                            <button type="button" className="text-lg" onClick={() => removeInputField(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <StaticPopup
                openModal={openUploadFile}
                handleClose={() => setOpenUploadFile(false)}
                size={"sm"}
                width={"xl"}
                bodyStyle="pb-0 pt-0"
                className="px-0"
                header={
                    <div className='text-base'>
                        Upload Files
                    </div>
                }>

                <div className="flex flex-col justify-center items-center px-0">
                    <div className="w-[400px] h-[180px] my-4 flex flex-col justify-center items-center border-2 border-dashed border-[#004491] bg-[#F1F7FF]">
                        <input
                            id="file-upload-material"
                            type="file"
                            className="hidden"
                            onChange={handleFileUploadLabour}
                            accept=".xlsx, .xls"
                        />
                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" className='w-8 h-8 mb-2' />
                        <p className="text-sm whitespace-nowrap">
                            Drag & Drop or
                            <label htmlFor="file-upload-material" className="underline px-2 text-[#1D28FA] cursor-pointer">
                                Choose File
                            </label>
                            to upload
                        </p>
                    </div>

                    {fileError && <div className="text-red-500 text-sm">{fileError}</div>}

                    <div>
                        <p className='text-sm text-center w-full font-normal text-[#777777]'>Don't have the template? </p>
                        <div className='flex items-center justify-center gap-2'>
                            <a href={downloatTemplate} download className='text-sm text-center text-[#1D28FA] font-normal'>
                                Download Here
                            </a>
                            <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                                    fill="blue"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className='flex justify-end px-0' style={{
                    boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)', // Adjust the shadow as needed
                }}>
                    {/* <button className='w-[95px] h-[35px] text-sm mx-4 my-4 bg-black text-white' onClick={() => setOpenUploadFile(false)}>
                        Upload
                    </button> */}
                </div>
            </StaticPopup>
        </div>
    );
};

export default RfqTypeLabour;
