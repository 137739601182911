import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import EmailTextField from "./EmailTextfield";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import { Button } from "flowbite-react";
import StaticPopup from "components/StaticPopup";
import RfqTypeMaterial from "./RfqTypeMaterial";
import RfqTypeLabour from "./RfqTypeLabour";
import RfqTypeProviding from "./RfqTypeProviding";
import RfqDetailsSection from "./RfqDetailsSection";
import CreateNewProjectForm from "views/Project/components/CreateNewProjectForm";
import { CcMemberList } from "actions/rfqActions";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import AddIndividual from "./AddIndividual";
import ReactQuill from "react-quill";

const GeneralInformationNew = ({ projects, updateRfqFormValues, entityId }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
  // const [currentValues, setCurrentValues] = useState({});
  const dispatch = useDispatch();

  const [isFocusedCC, setIsFocusedCC] = useState(false);
  const [addScope, setAddScope] = useState(false);

  const handleAddScopeBox = () => {
    setAddScope(!addScope)
  }

  const handleOpenCreateProjectForm = () => {
    // setCurrentValues(values);
    setShowCreateProjectForm(true);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Function to handle project creation and close the form
  const handleProjectCreation = () => {
    setShowCreateProjectForm(false);

    // Restore the form values after the project is created
    // Object.keys(currentValues).forEach((key) => {
    //   setFieldValue(key, currentValues[key]);
    // });
  };

  let otherCharges = [];
  if (
    values.requirementType === "material" ||
    values.requirementType === "contractor"
  ) {
    otherCharges = [
      { label: "Transport", key: "transport" },
      { label: "Loading", key: "loading" },
      { label: "Unloading", key: "unLoading" },
      { label: "Mathade Charges", key: "mathadeCharges" },
      { label: "Misc. Charges", key: "miscCharges" },
    ];
  } else if (values.requirementType === "labour") {
    otherCharges = [
      { label: "Mathade Charges", key: "mathadeCharges" },
      { label: "Misc. Charges", key: "miscCharges" },
    ];
  }

  const handleRadioChange = (key, value) => {
    setFieldValue(`otherCharges.${key}`, value); // Use Formik's setFieldValue to update otherCharges
  };

  useEffect(() => {
    dispatch(CcMemberList(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    // Avoid resetting 'otherCharges' when it's already set
    if (!values?.otherCharges) {
      setFieldValue('otherCharges', {}); // Only reset if 'otherCharges' is undefined
    }

    // Reset values based on requirementType
    if (values.requirementType === 'labour') {
      setFieldValue('providingFixingDetails', []); // Reset providing & fixing details
      setFieldValue('materials', []); // Reset material details
    } else if (values.requirementType === 'contractor') {
      setFieldValue('labourDetails', []); // Reset labour details
      setFieldValue('materials', []); // Reset materials
    } else if (values.requirementType === 'material') {
      setFieldValue('labourDetails', []); // Reset labour details
      setFieldValue('providingFixingDetails', []); // Reset providing & fixing details
    }
  }, [values.requirementType, setFieldValue]); // Don't include 'values.otherCharges' here



  return (
    <div className="w-full px-8 h-full pt-2 pb-10 overflow-y-auto">
      <div className="grid grid-cols-7 gap-x-2">
        <div className="col-span-3 space-y-4">
          {/* project address */}
          {/* <div >
          <Field
          as={CustomInput}
          type="text"
          id="projectAddress"
          name="projectAddress"
          placeholder="Enter Credit Project Address"
          label="Project Address"
         />
         <ErrorMessage
          name="projectAddress"
          component="div"
          className="text-red-500 text-xs"
         />
         </div> */}

          <div className="w-full">
            <label
              htmlFor="rfqName"
              className="block text-sm font-medium mb-1"
            >
              RFQ Summary (Subject)
            </label>
            <Field
              as={CustomInput}
              type="text"
              id="rfqName"
              name="rfqName"
              // label="RFQ Summary (Subject)"
              placeholder="RFQ Summary"
              className="bg-transparent h-[40px] text-sm font-normal"
            />
            <p className="text-[10px] text-[#4F4F42] font-normal mt-1 ">
              {/* <span className="font-semibold">Note :</span>  Provide the name of RFQ for your internal reference. e.g. <i>  steel, cement, RCC work, etc. </i> */}
            </p>
            <ErrorMessage
              name="rfqName"
              component="div"
              className="text-red-500 text-xs mt-1 "
            />
          </div>

          {/* project name */}
          <div className="">
            <label
              htmlFor="projectName"
              className="block text-sm font-medium mb-1"
            >
              Project Name
            </label>
            <Field
              component={CustomSelect}
              id="projectName"
              name="projectName"
              options={projects?.map((project) => ({
                value: project._id,
                label: project.name,
                description: `${project.location.city}, ${project.location.state}`,
              }))}
              placeholder="Project Name"
              selectedId={values?.projectName}
              selectedOption={
                projects.filter((project) => project._id === values?.projectName)[0]?.name ?? ""
              }
              onChange={(option) => setFieldValue("projectName", option.value)}
              onAddNew={handleOpenCreateProjectForm} // Add new project handler
            />
            <ErrorMessage
              name="projectName"
              component="div"
              className="text-red-500 text-xs mt-1"
            />
          </div>
        </div>

        <div className="col-span-1 space-y-4 mx-auto">

          {/* providing Date field */}
          <div className="w-1/2">
            <label
              htmlFor="providingDate"
              className="block text-sm w-full whitespace-nowrap font-medium mb-1"
            >
              {values.requirementType === 'material' ? "Material Delivery Date" : "Work Start Date"}
            </label>
            <Field
              // as={CustomInput}
              type="date"
              id="providingDate"
              name="providingDate"
              // label={values.requirementType === 'material' ? "Expected Delivery Date" : "Expected Work Start Date"}
              className="bg-transparent cursor-pointer mx-auto focus:outline-none focus:ring-0 text-sm placeholder:text-gray-300 placeholder:text-sm border border-gray-300 h-[40px]"
            />
            <ErrorMessage
              name="providingDate"
              component="div"
              className="text-red-500 text-xs mt-1"
            />
          </div>

          <div className="">
            <label
              htmlFor="requirementType"
              className="block text-sm w-full whitespace-nowrap font-medium mb-1"
            >
              Requirement Type
            </label>
            <div className="bg-gray-200 text-sm h-[40px] w-full border border-gray-300 text-gray-500 flex items-center p-2">
              {capitalizeFirstLetter(values?.requirementType)}
            </div>
          </div>
        </div>
        <div className="col-span-3 row-span-2">
          {/* email */}
          <div className="w-full min-h-[120px] max-h-[150px]">
            <label
              htmlFor="emailCopyRecipients"
              className="block text-sm font-medium mb-1"
            >
              RFQ Watchers (emails)
            </label>
            <Field
              as={EmailTextField}
              name="emailCopyRecipients"
              id="emailCopyRecipients"
              className="bg-transparent h-full text-sm placeholder:text-sm border border-gray-300 rounded-md flex-grow resize-none focus:outline-none focus:ring-0 w-full" // Increased min and max height
              onFocus={() => setIsFocusedCC(true)}
              onBlur={() => setIsFocusedCC(false)}
            />
            <ErrorMessage
              name="emailCopyRecipients"
              component="div"
              className="text-red-500 text-xs mt-1"
            />
          </div>

        </div>
      </div>
      {/* rfq type */}
      {/* <div>
        <Field
          className="cursor-pointer"
          component={CustomSelect}
          id="requirementType"
          name="requirementType"
          options={[
            {
              value: "contractor",
              label: "Contractor With Material",
              description: "Select this option if this requirement is for Turnkey Contractor",
            },
            {
              value: "labour",
              label: "Labour",
              description: "Select this option if this requirement is for Labour",
            },
            {
              value: "material",
              label: "Material",
              description: "Select this option if this requirement is for Material",
            },
          ]}
          placeholder="Requirement Type"
          selectedId={values?.requirementType}
          selectedOption={capitalizeFirstLetter(values?.requirementType)}
          onChange={(option) => setFieldValue("requirementType", option.value)}
          alwaysShowOptions={true} // This prop can be utilized in CustomSelect
        />
        <ErrorMessage
          name="requirementType"
          component="div"
          className="text-red-500 text-xs p-0 mt-1"
        />
      </div> */}

      <div className="grid grid-cols-7 mt-6 min-h-fit max-h-fit gap-x-2 w-full justify-between">
        {/* miscellaneous otherCharges */}
        {/* {values.requirementType === 'material' &&  */}
        {/* {(values.requirementType === 'labour' || values.requirementType === 'material' && */}
        {otherCharges?.length > 0 && <div className="col-span-3 w-full h-fit">
          <div>
            <p className="text-sm font-medium ">Additional Cost Components</p>
            <p className="text-[#8C8C8C] text-[10px]">Select whether additional cost is needed for the quotation. Vendors will fill them in when sending their offers.</p>
          </div>

          <div>
            {otherCharges?.map((charge) => (
              <div key={charge.key} className="mb-3 grid grid-cols-2">
                <span className=" text-[#434343] text-sm">
                  {charge.label}
                </span>

                <div className="flex items-center space-x-6">
                  <label className="inline-flex items-center cursor-pointer text-sm">
                    <input
                      type="radio"
                      name={`otherCharges.${charge.key}`}
                      value="yes"
                      className="h-3 w-3"
                      checked={values?.otherCharges?.[charge?.key] === true}
                      onChange={() => handleRadioChange(charge?.key, true)}
                    />
                    <span className="ml-2 text-sm text-[#505050]">Yes</span>
                  </label>

                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name={`otherCharges.${charge.key}`}
                      value="no"
                      className="h-3 w-3"
                      checked={
                        values.otherCharges?.[charge.key] === false ||
                        values.otherCharges[charge.key] === undefined
                      }
                      onChange={() => handleRadioChange(charge.key, false)}
                    />
                    <span className="ml-2 text-sm text-[#505050]">No</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>}
        <div className="ml-2 col-span-4 h-fit">
          <h3 className="text-sm font-medium mb-1">Payment Terms</h3>
          {/* <label className="inline-flex justify-start space-x-10 items-center w-full mb-3 cursor-pointer">
            <span className="text-sm font-normal text-[#505050] dark:text-gray-300">
              Vendor can offer counter terms
            </span>
            <div className="flex items-center space-x-4 ml-auto">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="acceptCounterTerms"
                  value="yes"
                  className="h-3 w-3" // Add margin for spacing
                  onChange={(e) => setFieldValue('acceptCounterTerms', e.target.value === 'yes')}
                  defaultChecked
                />
                <span className="ml-2 text-[#505050] text-sm">Yes</span>
              </label>

              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="acceptCounterTerms"
                  value="no"
                  className="h-3 w-3" // Add margin for spacing
                  onChange={(e) => setFieldValue('acceptCounterTerms', e.target.value === 'yes')}
                />
                <span className="ml-2 text-[#505050] text-sm">No</span>
              </label>
            </div>
          </label> */}
          <div >
            <RfqDetailsSection
              fieldName="paymentTerms"
              updateFormValues={updateRfqFormValues}
              showLabel={false}
              placeholder="Please write your payment terms here"
              className="placeholder:text-sm"
            />
          </div>
        </div>
      </div>

      <div >
        {/* Conditionally render components based on requirementType */}
        {values.requirementType === 'material' && 
        <div className="mb-16">
        <AddIndividual />
        </div>}
        {values.requirementType === 'labour' && <RfqTypeLabour />}
        {values.requirementType === 'contractor' && <RfqTypeProviding />}
      </div>

      <div className="mb-8">
        <p className="text-sm mt-6 font-medium pb-2">Terms & Conditions</p>
        <div>
          <ReactQuill
            value={values.termsAndConditions}
            onChange={(value) =>
              setFieldValue("termsAndConditions", value)
            }
            theme="snow"
            modules={{ toolbar: false }}
            placeholder="Enter overall RFQ's terms & conditions for vendor "
            className=' max-h-[150px] min-h-[80px] h-[80px] overflow-y-auto'
          />
          <ErrorMessage
            name={'termsAndConditions'}
            component="div"
            className="text-red-500 text-xs"
          />
        </div>
      </div>

      {values?.rfqDetails && <div className="pb-10 space-y-2">
        <h3 className="text-[#434343] font-medium">Old RFQ Details</h3>
        <div className="border px-4 py-2 bg-gray-100 text-gray-500">
          {values?.indentId && <p> <b>IndentId : </b> {values?.indentId} </p>}
          {values?.rfqDetails && <p> {ReactHtmlParser(values?.rfqDetails)} </p>}
        </div>
      </div>}

      {/* StaticPopup for adding a new project */}
      <StaticPopup
        openModal={showCreateProjectForm}
        handleClose={() => setShowCreateProjectForm(false)}
        header={"Add Project"}
        size={"sm"}
        width={"3xl"}
        bodyStyle={"pb-0 pt-2"}
      >
        {showCreateProjectForm && (
          <CreateNewProjectForm
            setShowCreateProjectForm={setShowCreateProjectForm}
            onProjectCreated={handleProjectCreation} // Callback to restore form values
          />
        )}
      </StaticPopup>
    </div>
  );
};

export default GeneralInformationNew;
