import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";

const RfqInformation = ({ rfqData, togglePopup, isPopupOpen }) => {
  const [expandedStates, setExpandedStates] = useState({}); // Track expanded states for each item
  const toggleExpanded = (index) => {
    setExpandedStates((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle specific item's state
    }));
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div className="p-4 lg:py-[24px] lg:px-8">
      <div className="shadow-md bg-white border p-4 lg:py-[20px]">
        <h3 className="text-sm font-semibold hidden lg:block lg:pb-2">
          RFQ Information
        </h3>
        <div className="text-xs lg:grid lg:grid-cols-3 lg:gap-x-8 space-y-4 lg:space-y-0 lg:gap-y-4 bg-white ">
          <div className="lg:space-y-5 space-y-4">
            <div>
              <p className="text-[#6B7280] font-normal  lg:block">RFQ Name</p>
              <p className="text-[#4F4F42] font-bold  lg:block">
                {rfqData?.name}
              </p>
            </div>
            <div>
              <p className="text-[#6B7280] font-normal  lg:block">
                Created Date
              </p>
              <p className="text-[#4F4F42] font-bold  lg:block">
                {new Date(rfqData?.createdAt).toLocaleDateString("en-GB")}
              </p>
            </div>
            <div>
              <p className="text-[#6B7280] font-normal  ">Client Name</p>
              <p className="text-[#4F4F42] font-bold ">
                {rfqData?.userBusinessName}
              </p>
            </div>
            {/* <div>
                                                            <p className="text-[#6B7280] font-normal">Payment Terms</p>
                                                            <p
                                                                className="text-[#4F4F42] font-bold bg-[#ECECEC] lg:bg-white mt-1 p-4 lg:m-0 lg:p-0"
                                                                dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}
                                                            ></p>
        
                                                        </div> */}
          </div>
          <div className="lg:space-y-5 space-y-4">
            <div>
              <p className="text-[#6B7280] font-normal   lg:block">
                Requirement Type
              </p>
              <p className="text-[#4F4F42] font-bold   lg:block">
                {capitalizeFirstLetter(rfqData?.rfqType)}
              </p>
            </div>
            <div>
              <p className="text-[#6B7280] font-normal">Project Name</p>
              <p className="text-[#4F4F42] font-bold">{rfqData?.projectName}</p>
            </div>
            <div>
              <p className="text-[#6B7280] font-normal   lg:block">
                Expected Delivery Date
              </p>
              <p className="text-[#4F4F42] font-bold  lg:block">
                {rfqData?.providingDate
                  ? new Date(rfqData?.providingDate).toLocaleDateString("en-GB")
                  : "-"}
              </p>
            </div>
          </div>
          <div className="lg:space-y-5 space-y-4">
            <div>
              <p className="text-[#6B7280] font-normal">Status</p>
              <div className="flex items-center space-x-2">
                <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                <p className="text-[#4F4F42] font-bold">{rfqData?.status}</p>
              </div>
            </div>
            <div>
              <p className="text-[#6B7280] font-normal  ">Contact</p>
              <p className="text-[#4F4F42] font-bold ">
                {rfqData?.requesterName} ({rfqData?.requesterMobile})
              </p>
            </div>
            <div>
              <p className="text-[#6B7280] font-normal">Delivery Location</p>
              <div className="flex items-center gap-2">
                <img
                  src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg"
                  alt=""
                  className="mb-1"
                />
                <p className="text-[#4F4F42] font-bold">{rfqData?.location}</p>
              </div>
            </div>
          </div>

          {/* Payment Terms Section */}
          {rfqData?.paymentTerms?.length > 0 && (
            <div>
              <p className="text-[#6B7280] font-normal">Payment Terms</p>
              <div className="">
                <p className="font-light whitespace-pre-line">
                  {ReactHtmlParser(
                    expandedStates[0]
                      ? rfqData?.paymentTerms
                      : rfqData?.paymentTerms.substring(0, 200) +
                          (rfqData?.paymentTerms.length > 200 ? "..." : "")
                  )}
                </p>
                {rfqData?.paymentTerms.length > 200 && (
                  <button
                    type="button"
                    className="text-[#8E8E85] text-xs underline italic"
                    onClick={() => toggleExpanded(0)}
                  >
                    {expandedStates[0] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>
          )}
          {/* Terms and Conditions Section */}
          {rfqData?.termsAndConditions?.length > 0 && (
            <div>
              <p className="text-[#6B7280] font-normal text-xs">
                Terms & Conditions
              </p>
              <div className="text-xs">
                <p className="font-light whitespace-pre-line">
                  {ReactHtmlParser(
                    expandedStates[1] // Use index 1 for Terms & Conditions
                      ? rfqData?.termsAndConditions
                      : rfqData?.termsAndConditions.substring(0, 200) +
                          (rfqData?.termsAndConditions.length > 200
                            ? "..."
                            : "")
                  )}
                </p>
                {rfqData?.termsAndConditions.length > 200 && (
                  <button
                    type="button"
                    className="text-[#8E8E85] text-xs underline italic"
                    onClick={() => toggleExpanded(1)} // Toggle for Terms & Conditions
                  >
                    {expandedStates[1] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RfqInformation;
