import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "utils/axios";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    submitLabourQuotation,
    submitProvidingQuotation,
} from "actions/vendorQuotationAction";
import { useSnackbar } from "notistack";
import CustomGstDropdown from "./CustomGstDropdown";
import { vendorQuotationService } from "services/vendorQuotationService";
import StaticPopup from "components/StaticPopup";
import ReactHtmlParser from "react-html-parser";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ReactQuill from "react-quill";
import { handleDownloadExcelForContractor, handleUploadExcelForContractor, } from "./excelHelpersContractors";
import LoadingModal from "views/Rfqs/LoadingModal";
import RfqInformation from "./RfqInformation";

const getFormattedUnit = (unit) => {
    switch (unit) {
        case "M² (Square Meter)":
            return "M²";
        case "Running Meter(RMT)":
            return "rmt";
        case "Sq. Meter":
            return "sq. mtr.";
        case "Numbers(Qty)":
            return "nos.";
        case "Cubic Meters(CUM)":
            return "cum";
        case "Kilograms(Kgs)":
            return "kgs";
        case "Metric Ton(M.Ton)":
            return "m. ton";
        case "Liters":
            return "ltrs";
        case "Bags":
            return "bags";
        case "Cubic Foot(CFT)":
            return "cft";
        case "PAC (Packet)":
            return "pkt";
        case "EA (Quantity)":
            return "ea";
        case "TON (Ton)":
            return "ton";
        case "M (Meter)":
            return "mtr";
        case "M³ (Cubic Meter)":
            return "cum";
        case "KG (Kilogram)":
            return "kg";
        case "PAA (Pair)":
            return "paa";
        case "FT² (Square Foot)":
            return "ft²";
        case "LUM (Lumsum)":
            return "lum";
        case "L (Litre)":
            return "ltr";
        case "FT (Square ft)":
            return "ft";
        case "DAY (Day)":
            return "day";
        case "Month (Months)":
            return "month";
        case "Year (Year)":
            return "yr";
        case "sqm (Square Metre)":
            return "sqm";
        case "Hrs (Hours)":
            return "hrs";
        case "Trip (Trip)":
            return "trip";
        case "Rft (Running Feet)":
            return "rft";
        case "RM (Running Meter)":
            return "rmt";
        case "Cum (Cubic Meter)":
            return "cum";
        case "NOS (Numbers (Count))":
            return "nos";
        case "Inch (Inch)":
            return "in";
        case "KM (kilometre)":
            return "km";
        default:
            return unit;
    }
};

// Yup validation schema
const miscellaneousSchema = Yup.object().shape({
    mrp: Yup.number()
        .typeError("Enter a valid MRP")
        .required("MRP is required")
        .min(0, "Invalid MRP"),
    gst: Yup.number().typeError("Enter a valid GST").required("GST is required"),
    totalCost: Yup.number().typeError("Total cost should be a number").required(),
});
const validationSchema = Yup.object().shape({
    providingFixingDetails: Yup.array()
        .of(
            Yup.object().shape({
                scopes: Yup.array().of(
                    Yup.object().shape({
                        comments: Yup.string(),
                        basePrice: Yup.number()
                            .nullable()
                            .when("materials", {
                                is: (materials) => materials.length === 0,
                                then: Yup.number()
                                    .required("Required")
                                    .min(0, "Invalid")
                                    .typeError("Invalid"),
                                otherwise: Yup.number().nullable(), // Optional when materials are not empty
                            }),
                        gst: Yup.number()
                            .required("Required")
                            .min(0, "Invalid")
                            .max(100, "Invalid")
                            .typeError("Invalid"),
                        // workStartDate: Yup.date(),
                        materials: Yup.array().of(
                            Yup.object().shape({
                                basePrice: Yup.number()
                                    .required("Required")
                                    .min(0, "Invalid")
                                    .typeError("Invalid"),
                                installationRate: Yup.number()
                                    .required("Required")
                                    .min(0, "Invalid")
                                    .typeError("Invalid"),
                                gst: Yup.number()
                                    .required("Required")
                                    .min(0, "Invalid")
                                    .max(100, "Invalid")
                                    .typeError("Invalid"),
                                remarks: Yup.string().nullable(),
                            })
                        ),
                    })
                ),
            })
        )
        .required("Providing Fixing Details are required"),
});

const ProvidingQuotationForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, error, errorMessage, quotation } = useSelector(
        (state) => state.vendorQuotation
    );
    const { enqueueSnackbar } = useSnackbar();
    const { isEdit } = location.state;
    const [rfqData, setRfqData] = useState(location.state?.rfqData || {});

    const supplierId = location.state?.supplierId || "";
    const quotationIndex = location.state?.quotationIndex;
    const isReadOnly = quotationIndex !== undefined;

    useEffect(() => {
        const fetchVendorQuotation = async () => {
            if (supplierId) {
                try {
                    const payload = { rfqId: rfqData?.rfqId, supplierId };
                    const response = await vendorQuotationService.getVendorQuotation(
                        payload
                    );

                    // Update rfqData.suppliers[0] with fetched vendorQuotations
                    if (response?.vendorQuotations) {
                        setRfqData((prevData) => ({
                            ...prevData,
                            suppliers:
                                prevData.suppliers?.map((supplier, index) =>
                                    index === 0
                                        ? {
                                            ...supplier,
                                            vendorQuotations: response.vendorQuotations,
                                        }
                                        : supplier
                                ) || [],
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching vendor quotation:", error);
                }
            }
        };

        fetchVendorQuotation();
    }, [supplierId, isEdit, rfqData?.rfqId]);

    useEffect(() => {
        if (rfqData?.suppliers?.[0]?.vendorQuotations) {
            const latestQuotationSet =
                quotationIndex !== undefined
                    ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[
                    quotationIndex
                    ]
                    : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(
                        -1
                    )[0];

            setTotalAmount(latestQuotationSet?.totalWithGst || 0);
            setIsCounterTerm(
                latestQuotationSet?.counterPaymentTerms?.length > 0 ? true : false
            );
            setSelectedFilesBase(latestQuotationSet?.attachments || []);
            setOverallTotal(
                quotationIndex !== undefined
                    ? latestQuotationSet?.othersTotalWithGst +
                    latestQuotationSet?.totalWithGst
                    : rfqData.suppliers[0].vendorQuotations.grandTotalWithGst || 0
            );
        }
    }, [rfqData, quotationIndex]);

    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;

    const isEditing = Boolean(rfqData?.suppliers?.[0]?.vendorQuotations);
    const lastQuotationSet = isEditing
        ? quotationIndex !== undefined
            ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[
            quotationIndex
            ]
            : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]
        : null;

    const contractorDetails =
        isEditing || quotationIndex !== undefined
            ? lastQuotationSet?.contractorDetails
            : {};

    const initialValues = useMemo(() => {
        if (isEditing) {
            return {
                providingFixingDetails: contractorDetails.map((section) => ({
                    id: section.id,
                    sectionName: section.sectionName || "",
                    sectionTotalExclGst: section.sectionTotalExclGst || "",
                    scopes: section.scopes.map((scope) => ({
                        id: scope.id,
                        scopeOfWork: scope.scopeOfWork || "",
                        quantity: scope.quantity || 0,
                        unit: scope.unit || "",
                        preferredBrands: scope.preferredBrands || [],
                        comments: scope.comments || "",
                        basePrice: scope.basePrice || 0,
                        supplyCost: scope.supplyCost || 0,
                        installationRate: scope.installationRate || 0,
                        installationCost: scope.installationCost || 0,
                        gst: scope.gst || 18,
                        totalAmount: scope.totalAmount || 0,
                        totalExclGst: scope.totalExclGst || 0,
                        materials: scope.materials.map((material) => ({
                            id: material.id,
                            name: material.name || "",
                            brands: material.brands || [],
                            quantity: material.quantity || 0,
                            unit: material.unit || "",
                            specifications: material.specifications || "",
                            basePrice: material.basePrice || "",
                            installationRate: material.installationRate || "",
                            discount: material.discount || 0,
                            gst: material.gst || 18,
                            netPrice: material.netPrice || 0,
                            installationCost: material.installationCost || 0,
                            totalExclGst: material.totalExclGst || 0,
                            remarks: material.remarks || "",
                        })),
                    })),
                })),
                miscellaneousCosts: {
                    mathadeCharges: {
                        mrp: lastQuotationSet?.otherCharges?.mathadeCharges?.mrp || 0,
                        gst: lastQuotationSet?.otherCharges?.mathadeCharges?.gst || 18,
                        totalCost:
                            lastQuotationSet?.otherCharges?.mathadeCharges?.totalCost || 0,
                        remark:
                            lastQuotationSet?.otherCharges?.mathadeCharges?.remark || "",
                    },
                    miscCharges: {
                        mrp: lastQuotationSet?.otherCharges?.miscCharges?.mrp || 0,
                        gst: lastQuotationSet?.otherCharges?.miscCharges?.gst || 18,
                        totalCost:
                            lastQuotationSet?.otherCharges?.miscCharges?.totalCost || 0,
                        remark: lastQuotationSet?.otherCharges?.miscCharges?.remark || "",
                    },
                    loading: {
                        mrp: lastQuotationSet?.otherCharges?.loading?.mrp || 0,
                        gst: lastQuotationSet?.otherCharges?.loading?.gst || 18,
                        totalCost: lastQuotationSet?.otherCharges?.loading?.totalCost || 0,
                        remark: lastQuotationSet?.otherCharges?.loading?.remark || '',
                    },
                    unLoading: {
                        mrp: lastQuotationSet?.otherCharges?.unLoading?.mrp || 0,
                        gst: lastQuotationSet?.otherCharges?.unLoading?.gst || 18,
                        totalCost: lastQuotationSet?.otherCharges?.unLoading?.totalCost || 0,
                        remark: lastQuotationSet?.otherCharges?.unLoading?.remark || '',
                    },
                    transport: {
                        mrp: lastQuotationSet?.otherCharges?.transport?.mrp || 0,
                        gst: lastQuotationSet?.otherCharges?.transport?.gst || 18,
                        totalCost: lastQuotationSet?.otherCharges?.transport?.totalCost || 0,
                        remark: lastQuotationSet?.otherCharges?.transport?.remark || '',
                    },
                },
                agreedToPaymentTerms:
                    lastQuotationSet?.counterPaymentTerms?.length > 0 ? false : true,
                counterPaymentTerms: lastQuotationSet?.counterPaymentTerms || "",
                notes: lastQuotationSet?.notes || "",
                overallExclGst:
                    lastQuotationSet?.totalWithoutGst +
                    lastQuotationSet?.othersTotalWithoutGst || 0,
                overallWithGst:
                    lastQuotationSet?.totalWithGst +
                    lastQuotationSet?.othersTotalWithGst || 0,
            };
        }

        // Default values if not editing
        return {
            providingFixingDetails: rfqData.providingFixingDetails.map((section) => ({
                id: section._id,
                sectionName: section.sectionName || "",
                scopes: section.scopes.map((scope) => ({
                    id: scope._id,
                    scopeOfWork: scope.scopeOfWork || "",
                    quantity: scope.quantity || 0,
                    unit: scope.unit || "",
                    preferredBrands: scope.preferredBrands || [],
                    comments: "",
                    basePrice: 0,
                    supplyCost: 0,
                    installationRate: 0,
                    installationCost: 0,
                    gst: scope.materials.length === 0 ? 18 : 0,
                    workStartDate: "",
                    totalAmount: 0,
                    totalExclGst: 0,
                    materials: scope.materials.map((material) => ({
                        id: material._id,
                        name: material.name || "",
                        brands: material.brands || [],
                        quantity: material.quantity || 0,
                        unit: material.unit || "",
                        specifications: material.specifications || "",
                        basePrice: material.basePrice || "",
                        installationRate: material.installationRate || "",
                        discount: material.discount || 0,
                        gst: 18,
                        netPrice: material.netPrice || 0,
                        totalExclGst: material.totalExclGst || 0,
                        installationCost: material.installationCost || 0,
                        remarks: material.remarks || "",
                    })),
                })),
            })),
            miscellaneousCosts: {
                mathadeCharges: { mrp: 0, gst: 18, totalCost: 0, remark: "" },
                miscCharges: { mrp: 0, gst: 18, totalCost: 0, remark: "" },
                loading: { mrp: 0, gst: 18, totalCost: 0, remark: '' },
                unLoading: { mrp: 0, gst: 18, totalCost: 0, remark: '' },
                transport: { mrp: 0, gst: 18, totalCost: 0, remark: '' },
            },
            agreedToPaymentTerms: true,
            counterPaymentTerms: "",
            notes: "",
            overallExclGst: 0,
            overallWithGst: 0,
        };
    }, [rfqData]);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDescPopupOpen, setIsDescPopupOpen] = useState(false);
    const [openDescPopup, setOpenDescPopup] = useState(false);
    const [popupHeader, setpopupHeader] = useState("");
    const [selectedDesc, setSelectedDesc] = useState(false);
    const [totalAmount, setTotalAmount] = useState(
        lastQuotationSet?.totalWithGst || 0
    );
    const [isCounterTerm, setIsCounterTerm] = useState();
    const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
    const [selectedFilesBase, setSelectedFilesBase] = useState(
        lastQuotationSet?.attachments || []
    );
    const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
    const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState("");
    const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
    const [overallTotal, setOverallTotal] = useState(
        rfqData?.suppliers?.[0]?.vendorQuotations?.grandTotalWithGst || 0
    );
    const [excelLoading, setExcelLoading] = useState(false);
    const [openAccordian, setOpenAccordian] = useState(null); // State to manage open accordion
    const [loadingMessage, setLoadingMessage] = useState("");
    const [excelError, setExcelError] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [expandedStates, setExpandedStates] = useState({}); // Track expanded states for each item

    const toggleExpanded = (index) => {
        setExpandedStates((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle specific item's state
        }));
    };

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const [openSections, setOpenSections] = useState(() => {
        // Set the default value of openSections to have all sections open
        const initialOpenState = {};
        rfqData.providingFixingDetails.forEach((_, sectionIndex) => {
            initialOpenState[sectionIndex] = true; // All sections open by default
        });
        return initialOpenState;
    });

    // const [miscellaneousCosts, setMiscellaneousCosts] = useState({
    //     transport: { mrp: 0, gst: 0, totalCost: 0 },
    //     loading: { mrp: 0, gst: 0, totalCost: 0 },
    //     mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
    //     miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
    // });

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

    const toggleAccordion = (index) => {
        setOpenAccordian(openAccordian === index ? null : index); // Toggle the open state
        setActiveIndex(index === activeIndex ? null : index);
    };

    const toggleSection = (sectionIndex) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [sectionIndex]: !prevState[sectionIndex], // Toggle the section's open/close state
        }));
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const toggleDescPopup = () => {
        setOpenDescPopup(!isDescPopupOpen);
    };

    const closeDescPopup = () => {
        setOpenDescPopup(false); // Close the popup
    };

    const openFullDesc = (specifications, title) => {
        setSelectedDesc(specifications); // Set the full specifications
        setpopupHeader(title);
        setOpenDescPopup(true); // Open the popup
    };

    const miscellaneousItems = [
        { label: "Transport", name: "transport" },
        { label: "Loading", name: "loading" },
        { label: "Unloading", name: "unLoading" },
        { label: "Mathade Charges", name: "mathadeCharges" },
        { label: "Misc. Charges", name: "miscCharges" },
    ];

    const { otherCharges } = rfqData || {};

    const visibleMiscellaneousItems = miscellaneousItems.filter(
        (item) => otherCharges?.[item.name]
    );

    // Check if any items are visible
    const hasVisibleMiscellaneousItems = visibleMiscellaneousItems.length > 0;

    const currentRank = rfqData?.suppliers[0]?.vendorQuotations?.rank;

    const attachmentsData = rfqData?.selectedFilesBase
        ? rfqData.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf("."); // Extract file extension
            const path =
                lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : [];

    const handleCloseForm = () => {
        if (supplierId) {
            navigate("/leads");
        } else {
            navigate(-1);
        }
    };

    const calculateOverallTotals = (
        providingFixingDetails,
        miscellaneousCosts
    ) => {
        let totalExclGst = 0;
        let totalWithGst = 0;

        // Calculate section totals and overall totals
        const updatedProvidingFixingDetails = providingFixingDetails.map(
            (section) => {
                const sectionTotalExclGst = section.scopes.reduce((total, scope) => {
                    return total + (parseFloat(scope.totalExclGst) || 0);
                }, 0);

                // Update the section with the calculated total
                section.sectionTotalExclGst = sectionTotalExclGst.toFixed(2);

                // Accumulate the overall totals
                totalExclGst += sectionTotalExclGst;
                totalWithGst += section.scopes.reduce((total, scope) => {
                    return total + (parseFloat(scope.totalAmount) || 0);
                }, 0);

                return section;
            }
        );

        // Add miscellaneous costs to the overall totals
        const miscTotals = Object.values(miscellaneousCosts).reduce(
            (totals, cost) => {
                totals.totalExclGst += parseFloat(cost.mrp) || 0;
                totals.totalWithGst += parseFloat(cost.totalCost) || 0;
                return totals;
            },
            { totalExclGst: 0, totalWithGst: 0 }
        );

        totalExclGst += miscTotals.totalExclGst;
        totalWithGst += miscTotals.totalWithGst;

        return {
            providingFixingDetails: updatedProvidingFixingDetails,
            overallExclGst: totalExclGst.toFixed(2),
            overallWithGst: totalWithGst.toFixed(2),
        };
    };

    const calculateMaterialTotals = (material) => {
        const basePrice = parseFloat(material.basePrice) || 0;
        const gst = parseFloat(material.gst) || 0;
        const installationRate = parseFloat(material.installationRate) || 0;

        const netPrice = basePrice * material.quantity;
        const InstallationCost = installationRate * material.quantity;
        const totalExclGst = basePrice * material.quantity + InstallationCost;
        const totalWithGst = totalExclGst + (totalExclGst * gst) / 100;

        return {
            netPrice: netPrice.toFixed(0),
            totalExclGst: totalExclGst.toFixed(0),
            totalWithGst: totalWithGst.toFixed(0),
            InstallationCost: InstallationCost.toFixed(0),
        };
    };

    const calculateScopeTotals = (materials, scope) => {
        if (materials && materials.length > 0) {
            return materials.reduce(
                (totals, material) => {
                    const { totalExclGst, totalWithGst } =
                        calculateMaterialTotals(material);
                    totals.totalExclGst += parseFloat(totalExclGst);
                    totals.totalAmount += parseFloat(totalWithGst);
                    return totals;
                },
                { totalExclGst: 0, totalAmount: 0 }
            );
        } else {
            // If no materials, calculate totals directly from scope-level fields
            const basePrice = parseFloat(scope.basePrice) || 0;
            const installationRate = parseFloat(scope.installationRate) || 0;
            const gst = parseFloat(scope.gst) || 0;
            const quantity = parseFloat(scope.quantity) || 0;
            const installationCost = installationRate * quantity || 0;
            const supplyCost = basePrice * quantity || 0;
            const totalExclGst = installationCost + supplyCost;
            const totalAmount = totalExclGst + (totalExclGst * gst) / 100;
            return { totalExclGst, totalAmount, supplyCost, installationCost };
        }
    };

    const handleFieldChange = (
        sectionIndex,
        scopeIndex,
        materialIndex,
        fieldName,
        value,
        values,
        setFieldValue
    ) => {
        const updatedFixingDetails = JSON.parse(
            JSON.stringify(values.providingFixingDetails)
        ); // Create a deep copy

        if (materialIndex !== null) {
            // Update specific material field
            const updatedMaterials =
                updatedFixingDetails[sectionIndex].scopes[scopeIndex].materials;
            updatedMaterials[materialIndex][fieldName] = value;

            if (["gst", "basePrice", "installationRate"].includes(fieldName)) {
                const { netPrice, totalExclGst, totalWithGst, InstallationCost } =
                    calculateMaterialTotals(updatedMaterials[materialIndex]);
                updatedMaterials[materialIndex].netPrice = netPrice;
                updatedMaterials[materialIndex].totalExclGst = totalExclGst;
                updatedMaterials[materialIndex].totalWithGst = totalWithGst;
                updatedMaterials[materialIndex].installationCost = InstallationCost;
            }

            updatedFixingDetails[sectionIndex].scopes[scopeIndex].materials =
                updatedMaterials;
        } else {
            // Update scope-level field if materials are not present
            updatedFixingDetails[sectionIndex].scopes[scopeIndex][fieldName] = value;
        }

        // Recalculate totals
        const scopeTotals = calculateScopeTotals(
            updatedFixingDetails[sectionIndex].scopes[scopeIndex].materials,
            updatedFixingDetails[sectionIndex].scopes[scopeIndex]
        );

        updatedFixingDetails[sectionIndex].scopes[scopeIndex].totalExclGst =
            scopeTotals?.totalExclGst.toFixed(2);
        updatedFixingDetails[sectionIndex].scopes[scopeIndex].totalAmount =
            scopeTotals?.totalAmount.toFixed(2);
        updatedFixingDetails[sectionIndex].scopes[scopeIndex].supplyCost = (
            scopeTotals?.supplyCost || 0
        ).toFixed(2);
        updatedFixingDetails[sectionIndex].scopes[scopeIndex].installationCost = (
            scopeTotals?.installationCost || 0
        ).toFixed(2);

        const overallTotals = calculateOverallTotals(
            updatedFixingDetails,
            values.miscellaneousCosts
        );

        // Update Formik values
        setFieldValue("providingFixingDetails", updatedFixingDetails);
        setFieldValue("overallExclGst", overallTotals.overallExclGst);
        setFieldValue("overallWithGst", overallTotals.overallWithGst);
    };

    const calculateTransportationTotalCost = (mrp, gst) => {
        const gstValue = parseFloat(gst) || 0;
        const mrpValue = parseFloat(mrp) || 0;
        return mrpValue + (mrpValue * gstValue) / 100;
    };

    // Handler for miscellaneous cost inputs
    const handleMiscellaneousChange = (
        field,
        value,
        setFieldValue,
        values,
        type
    ) => {
        const parsedValue =
            type === "remark" ? value : value === "" ? "" : parseFloat(value) || 0;
        const updatedMiscellaneousCosts = { ...values.miscellaneousCosts };

        updatedMiscellaneousCosts[field][type] = parsedValue;

        // Calculate total cost if MRP and GST are valid (exclude for remarks)
        if (
            type !== "remark" &&
            updatedMiscellaneousCosts[field].mrp !== "" &&
            updatedMiscellaneousCosts[field].gst !== ""
        ) {
            updatedMiscellaneousCosts[field].totalCost =
                calculateTransportationTotalCost(
                    updatedMiscellaneousCosts[field].mrp,
                    updatedMiscellaneousCosts[field].gst
                );
        } else if (type !== "remark") {
            updatedMiscellaneousCosts[field].totalCost = 0;
        }

        const overallTotals = calculateOverallTotals(
            values.providingFixingDetails,
            updatedMiscellaneousCosts
        );

        setFieldValue(`miscellaneousCosts.${field}.${type}`, parsedValue);
        if (type !== "remark") {
            setFieldValue("overallExclGst", overallTotals.overallExclGst);
            setFieldValue("overallWithGst", overallTotals.overallWithGst);
        }
    };

    const handleMiscGstChange = (field, value, setFieldValue, values) => {
        handleMiscellaneousChange(field, value, setFieldValue, values, "gst");
    };

    const handleFileUploadClick = (event) => {
        event.target.value = "";
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const sizeOfSelectedFiles = Array.from(files).reduce(
            (acc, obj) => acc + obj.size,
            0
        );
        const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce(
            (acc, obj) => acc + obj.FileSize,
            sizeOfSelectedFiles
        );

        if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
            setAttachmentsSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = Array.from(files).map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const fileContent = e.target.result;
                        const encryptedContent = btoa(fileContent); // Encrypt file content in Base64
                        let contentType = file.type;
                        if (!contentType && file.name.endsWith(".dwg")) {
                            contentType = "application/acad"; // MIME type for DWG files
                        }
                        const payload = {
                            Name: file.name,
                            Content: encryptedContent,
                            ContentType: contentType,
                            FileSize: file.size,
                        };
                        resolve(payload);
                    };
                    reader.readAsBinaryString(file);
                });
            });

            Promise.all(convertedFilesPromises)
                .then((convertedFiles) => attachmentBlobLink(convertedFiles))
                .catch((error) => console.error(error));
        } else {
            setOpenFilesSizeError(true);
            setTimeout(() => setOpenFilesSizeError(false), 6000);
        }
    };

    const handleDeleteSelectedFile = (name, event) => {
        event.preventDefault();
        setSelectedFilesBase((prevVal) =>
            prevVal.filter((data) => data.Name !== name)
        );
        setSelectedFilesTotalSize((prevVal) =>
            prevVal.filter((data) => data.Name !== name)
        );
    };

    const handleCloseFileSizeError = () => {
        setOpenFilesSizeError(false);
    };

    const attachmentBlobLink = (convertedFiles) => {
        axios
            .post("/api/rfqs/upload/attachments", convertedFiles)
            .then((response) => {
                setSelectedFilesBase((prevVal) => [...prevVal, ...response.data]);
                setSelectedFilesTotalSize((prevVal) => [...prevVal, ...convertedFiles]);
                setAttachmentsSkeleton([]);
            })
            .catch((error) => {
                setAttachmentsSkeleton([]);
                setAttachmentsUploadsErrors(error.message);
                setTimeout(() => setAttachmentsUploadsErrors(""), 5000);
                console.error(error);
            });
    };

    useEffect(() => {
        if (!loading && quotation && Object.keys(quotation).length > 0) {
            // Show success snackbar when quotation is successfully submitted
            enqueueSnackbar("Vendor quotation submitted successfully!", {
                variant: "success",
                autoHideDuration: 1000,
            });
        }

        if (!loading && error) {
            // Show error snackbar when there's an error
            enqueueSnackbar(`Error: ${errorMessage}`, {
                variant: "error",
                autoHideDuration: 3000,
            });
        }
    }, [loading, quotation, error, errorMessage, enqueueSnackbar]);

    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        "rfq info",
        "Input Fields",
        // "Miscellaneous",
        "Supporting Documents",
        "Additional Information & Upload",
        "Completion",
    ];

    const handleNextStep = async (validateForm, setTouched, values) => {
        const errors = await validateForm(); // Trigger Formik validation
        const hasErrors = Object.keys(errors).length > 0; // Check if there are any errors
        // Ensure all touched fields are set if there are errors
        if (hasErrors) {
            setTouched(errors, true); // This marks all error fields as touched
            return; // Prevent moving to the next step if there are errors
        }
        // If no errors, move to the next step
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleUploadMaterialExcel = async (event,
        setFieldValue,
        values,
        rfqData,
        setExcelLoading,
        setLoadingMessage,
        enqueueSnackbar,
        calculateOverallTotals,
        calculateMaterialTotals,
        calculateScopeTotals) => {
        await handleUploadExcelForContractor(
            event,
            setFieldValue,
            values,
            rfqData,
            setExcelLoading,
            setLoadingMessage,
            enqueueSnackbar,
            calculateOverallTotals,
            calculateMaterialTotals,
            calculateScopeTotals
        );
    };

    return (
        <div
            className="max-w-7xl lg:mx-auto min-h-screen bg-white shadow-md"
            style={{ fontFamily: "goldman_sans" }}
        >
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, errors) => {
                    const payload = {
                        providingFixingDetails: values.providingFixingDetails,
                        paymentTerms: values.paymentTerms,
                        counterPaymentTerms: values.counterPaymentTerms,
                        notes: values.notes,
                        rfqId: rfqData?.rfqId,
                        rfqType: rfqData?.rfqType,
                        supplierId: rfqData?.suppliers?.[0]?.supplierId,
                        submittedBy: userProfile?.name,
                        userId: userProfile?.userId,
                        otherCharges: values.miscellaneousCosts,
                        selectedFilesBase,
                    };
                    // const payload = { ...values, selectedFilesBase,  }
                    try {
                        await dispatch(submitProvidingQuotation({ values: payload }));
                        if (supplierId) {
                            navigate("/leads");
                        } else {
                            navigate(-1);
                        }
                        // Optionally handle success state here
                    } catch (error) {
                        // Handle errors here, such as showing an error message
                        console.error("Submission failed:", error);
                    }
                }}
            >
                {({ setFieldValue, values, validateForm, setTouched }) => (
                    <Form
                        autoComplete="off"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                        }}
                    >
                        {/* mobile  */}
                        <div className="block lg:hidden">
                            {/* Stepper */}
                            {currentStep === 0 ? (
                                " "
                            ) : (
                                <div className="z-20 sticky top-0 shadow-md bg-white flex justify-center items-center px-10 py-6 w-full lg:hidden">
                                    {/* Step 1 */}
                                    <div
                                        className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 0 ? "bg-[#313119]" : "bg-[#DCDCDC]"
                                            }`}
                                    >
                                        <p
                                            className={`text-xs font-medium ${currentStep > 0 ? "text-white" : "#313119"
                                                }`}
                                        >
                                            1
                                        </p>
                                    </div>
                                    <div
                                        className={`flex-grow h-[2px] ${currentStep > 1 ? "bg-[#313119]" : "bg-[#DCDCDC]"
                                            } mx-2`}
                                    ></div>

                                    {/* Step 2 */}
                                    <div
                                        className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 1 ? "bg-[#313119]" : "bg-[#DCDCDC]"
                                            }`}
                                    >
                                        <p
                                            className={`text-xs font-medium ${currentStep > 1 ? "text-white" : "#313119"
                                                }`}
                                        >
                                            2
                                        </p>
                                    </div>
                                    <div
                                        className={`flex-grow h-[2px] ${currentStep > 2 ? "bg-[#313119]" : "bg-[#DCDCDC]"
                                            } mx-2`}
                                    ></div>

                                    {/* Step 3 */}
                                    <div
                                        className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 2 ? "bg-[#313119]" : "bg-[#DCDCDC]"
                                            }`}
                                    >
                                        <p
                                            className={`text-xs font-medium ${currentStep > 2 ? "text-white" : "#313119"
                                                }`}
                                        >
                                            3
                                        </p>
                                    </div>
                                </div>
                            )}

                            {/* stepper content */}
                            <div className="h-full flex-grow overflow-y-auto">
                                <div className="flex justify-between">
                                    {steps.map((step, index) => (
                                        <div
                                            key={index}
                                            className={`step ${currentStep === index ? "active" : ""
                                                }`}
                                        >
                                            {/* {step} */}
                                        </div>
                                    ))}
                                </div>

                                <div className="step-content">
                                    {currentStep === 0 && (
                                        <div>
                                            {/* <div className="p-4 sticky top-0 flex flex-col items-center justify-center shadow-md">
                                                <div className="flex items-center space-x-2">
                                                    <span class="relative flex items-center justify-center h-4 w-4 ">
                                                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                                        <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                                    </span>
                                                    <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                                        Quotation Rank:{" "}
                                                        <span className="font-bold text-black">
                                                            {currentRank === "L1"
                                                                ? "L2"
                                                                : currentRank === undefined || currentRank === null
                                                                    ? "Quotation not submitted"
                                                                    : currentRank}
                                                        </span>
                                                    </h3>
                                                    <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p>
                                                </div>

                                                <span className="pt-1 text-[#737373] text-xs text-center group-hover:block">
                                                    {currentRank === undefined || currentRank === null
                                                        ? "Quotation rank is available after quotation submission"
                                                        : "Based on your submitted quotation"}
                                                </span>
                                            </div> */}

                                            {/* rfq information */}
                                            <div className="px-4 lg:py-[24px] lg:px-8">
                                                <div className="mt-4 mb-1 lg:flex lg:justify-between lg:space-x-10">
                                                    {/* <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.name}</h3> */}
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B]">
                                                        {" "}
                                                        <span className="text-[#787878]">
                                                            Requirement Name:
                                                        </span>{" "}
                                                        {rfqData?.name}
                                                    </h3>
                                                    {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" /> */}
                                                </div>
                                                <p className="text-sm font-normal text-[#787878] lg:hidden">
                                                    Create Date:{" "}
                                                    {new Date(rfqData?.createdAt).toLocaleDateString(
                                                        "en-GB"
                                                    )}
                                                </p>

                                                <p className="text-sm font-medium mt-2 lg:hidden">
                                                    RFQ Information
                                                </p>
                                            </div>
                                                <RfqInformation 
                                                    rfqData={rfqData}
                                                    togglePopup={togglePopup}
                                                    isPopupOpen={isPopupOpen}
                                                    />
                                        </div>
                                    )}
                                    <div className="px-4 py-4 pb-10 mb-18">
                                        {currentStep === 1 && (
                                            <>
                                                {" "}
                                                {values.providingFixingDetails.map(
                                                    (section, sectionIndex) => (
                                                        <div key={sectionIndex} className="mb-2">
                                                            {/* Accordion Header */}
                                                            <div
                                                                onClick={() => toggleSection(sectionIndex)}
                                                                className="flex justify-between items-center bg-gray-200 px-4 py-2 cursor-pointer text-sm"
                                                            >
                                                                <p className="font-semibold w-1/2">
                                                                    {section.sectionName}
                                                                </p>
                                                                <p className="text-xs w-1/2">
                                                                    Section Total (Excl. GST) :{" "}
                                                                    {section.sectionTotalExclGst || 0}
                                                                </p>
                                                                <img
                                                                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                                                    alt="toggle"
                                                                    className={`transition-transform duration-200 ${openSections[sectionIndex]
                                                                        ? "rotate-180"
                                                                        : "rotate-0"
                                                                        }`}
                                                                />
                                                            </div>

                                                            {/* Accordion Body */}
                                                            {openSections[sectionIndex] && (
                                                                <div className="border-2 bg-white">
                                                                    {section.scopes.map((scope, scopeIndex) => (
                                                                        <div key={scopeIndex} className="mb-6 px-4">
                                                                            {scope?.materials?.length === 0 ? (
                                                                                <>
                                                                                    {/* <p className=" mb-[10px] mt-2 font-medium text-[#434343]">Your bid value is the sum of these line items</p> */}
                                                                                    <div className=" my-2 font-normal border-y py-2">
                                                                                        <div className="space-y-2">
                                                                                            {/* description */}
                                                                                            <div className="w-full flex space-x-2">
                                                                                                <p>{scopeIndex + 1}.</p>
                                                                                                <p className=" font-light whitespace-pre-line">
                                                                                                    {ReactHtmlParser(
                                                                                                        scope.scopeOfWork.length >
                                                                                                            220
                                                                                                            ? scope.scopeOfWork.substring(
                                                                                                                0,
                                                                                                                220
                                                                                                            ) + "..."
                                                                                                            : scope.scopeOfWork
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>

                                                                                            {scope.scopeOfWork.length >
                                                                                                220 && (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="text-[#8E8E85] text-xs pl-4 underline italic"
                                                                                                        onClick={() =>
                                                                                                            openFullDesc(
                                                                                                                ReactHtmlParser(
                                                                                                                    scope.scopeOfWork
                                                                                                                ),
                                                                                                                "Scope of Work"
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Read More
                                                                                                    </button>
                                                                                                )}
                                                                                            <div className="flex space-x-2">
                                                                                                <div className="w-1/2">
                                                                                                    <label className="block font-semibold">
                                                                                                        Brands
                                                                                                    </label>
                                                                                                    <p className="break-words">
                                                                                                        {ReactHtmlParser(
                                                                                                            scope?.preferredBrands.join(
                                                                                                                ", "
                                                                                                            ) || "NA"
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <label className="block font-semibold">
                                                                                                        Qty
                                                                                                    </label>
                                                                                                    <p className="cursor-pointer mr-1 break-words">
                                                                                                        {scope.quantity}{" "}
                                                                                                        {getFormattedUnit(
                                                                                                            scope.unit
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* comments/methods */}
                                                                                            <div>
                                                                                                <label className="block font-semibold ">
                                                                                                    Comments / Methods
                                                                                                </label>
                                                                                                <ReactQuill
                                                                                                    value={
                                                                                                        values
                                                                                                            .providingFixingDetails[
                                                                                                            sectionIndex
                                                                                                        ]?.scopes[scopeIndex]
                                                                                                            ?.comments || ""
                                                                                                    }
                                                                                                    name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].comments`}
                                                                                                    onChange={(value) => {
                                                                                                        setFieldValue(
                                                                                                            `providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].comments`,
                                                                                                            value
                                                                                                        );
                                                                                                    }}
                                                                                                    disabled={isReadOnly}
                                                                                                    theme="snow"
                                                                                                    className="min-h-[30px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white w-full overflow-y-auto remove-quill-padding"
                                                                                                    modules={{ toolbar: false }}
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].comments`}
                                                                                                    component="div"
                                                                                                    className="text-red-500 text-xs"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="flex space-x-2">
                                                                                                <div>
                                                                                                    <label className="block font-semibold ">
                                                                                                        Material Rate
                                                                                                    </label>
                                                                                                    {/* base price */}
                                                                                                    <div>
                                                                                                        <Field
                                                                                                            type="text"
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].basePrice`}
                                                                                                            className={`focus:ring-0 text-right h-[28px] border text-xs border-gray-300 placeholder-[#373737] ${isReadOnly &&
                                                                                                                "bg-gray-100"
                                                                                                                }`}
                                                                                                            // value={values.basePrice}
                                                                                                            // onChange={(e) => handleBasePriceChange(e, setFieldValue, values, setTotalAmount)} // Update and calculate
                                                                                                            onChange={(e) =>
                                                                                                                handleFieldChange(
                                                                                                                    sectionIndex,
                                                                                                                    scopeIndex,
                                                                                                                    null,
                                                                                                                    "basePrice",
                                                                                                                    e.target.value,
                                                                                                                    values,
                                                                                                                    setFieldValue
                                                                                                                )
                                                                                                            }
                                                                                                            disabled={isReadOnly}
                                                                                                        />
                                                                                                        <div className="w-fit">
                                                                                                            <ErrorMessage
                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].basePrice`}
                                                                                                                component="div"
                                                                                                                className="text-red-500 text-xs absolute mt-1"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <label className="block font-semibold text-right">
                                                                                                        GST (%)
                                                                                                    </label>
                                                                                                    {/* gst */}
                                                                                                    <div className="w-full h-fit">
                                                                                                        <CustomGstDropdown
                                                                                                            options={[
                                                                                                                0, 5, 12, 18, 28,
                                                                                                            ]} // GST options
                                                                                                            value={scope.gst}
                                                                                                            onChange={(newValue) =>
                                                                                                                handleFieldChange(
                                                                                                                    sectionIndex,
                                                                                                                    scopeIndex,
                                                                                                                    null,
                                                                                                                    "gst",
                                                                                                                    newValue,
                                                                                                                    values,
                                                                                                                    setFieldValue
                                                                                                                )
                                                                                                            }
                                                                                                            className="lg:w-full h-[28px]"
                                                                                                            disabled={isReadOnly}
                                                                                                        />
                                                                                                        {/* <ErrorMessage name={lineItems[${index}].gst} component="div" className="text-red-500 text-sm h-fit" /> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div>
                                                                                                <label className="block font-semibold ">
                                                                                                    Installation Rate
                                                                                                </label>
                                                                                                {/* base price */}
                                                                                                <div>
                                                                                                    <Field
                                                                                                        type="text"
                                                                                                        name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].installationRate`}
                                                                                                        className={`focus:ring-0 text-right h-[28px] border text-xs border-gray-300 placeholder-[#373737] ${isReadOnly &&
                                                                                                            "bg-gray-100"
                                                                                                            }`}
                                                                                                        // value={values.installationRate}
                                                                                                        // onChange={(e) => handleinstallationRateChange(e, setFieldValue, values, setTotalAmount)} // Update and calculate
                                                                                                        onChange={(e) =>
                                                                                                            handleFieldChange(
                                                                                                                sectionIndex,
                                                                                                                scopeIndex,
                                                                                                                null,
                                                                                                                "installationRate",
                                                                                                                e.target.value,
                                                                                                                values,
                                                                                                                setFieldValue
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={isReadOnly}
                                                                                                    />
                                                                                                    <div className="w-fit">
                                                                                                        <ErrorMessage
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].installationRate`}
                                                                                                            component="div"
                                                                                                            className="text-red-500 text-xs absolute mt-1"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* total amount */}
                                                                                        <div className=" mt-4 flex justify-between lg:justify-end items-center w-full lg:border-none">
                                                                                            <label className="block   text-center">
                                                                                                Supply cost
                                                                                            </label>
                                                                                            <div className="font-bold lg:font-normal text-xs text-right lg:text-black ">
                                                                                                Rs.
                                                                                                {new Intl.NumberFormat(
                                                                                                    "en-IN",
                                                                                                    { minimumFractionDigits: 2 }
                                                                                                ).format(
                                                                                                    scope?.supplyCost || 0
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className=" mt-1 flex justify-between lg:justify-end items-center w-full lg:border-none">
                                                                                            <label className="block   text-center">
                                                                                                Installation Cost
                                                                                            </label>
                                                                                            <div className="font-bold lg:font-normal text-xs text-right lg:text-black ">
                                                                                                Rs.
                                                                                                {new Intl.NumberFormat(
                                                                                                    "en-IN",
                                                                                                    { minimumFractionDigits: 2 }
                                                                                                ).format(
                                                                                                    scope?.installationCost || 0
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className=" mt-1 p-2 flex justify-between bg-blue-50 lg:justify-end items-center w-full border border-t-[#004491] lg:border-none">
                                                                                            <label className="block text-[#004491] font-semibold text-center">
                                                                                                Total (Excl. GST)
                                                                                            </label>
                                                                                            <div className="font-bold lg:font-normal text-xs text-right lg:text-black text-[#004491]">
                                                                                                Rs.
                                                                                                {new Intl.NumberFormat(
                                                                                                    "en-IN",
                                                                                                    { minimumFractionDigits: 2 }
                                                                                                ).format(
                                                                                                    scope?.totalExclGst || 0
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="text-sm border-y py-2 space-y-2">
                                                                                        <div className="w-full flex space-x-2">
                                                                                            <p>{scopeIndex + 1}.</p>
                                                                                            <p className=" font-light whitespace-pre-line">
                                                                                                {ReactHtmlParser(
                                                                                                    scope.scopeOfWork.length > 220
                                                                                                        ? scope.scopeOfWork.substring(
                                                                                                            0,
                                                                                                            220
                                                                                                        ) + "..."
                                                                                                        : scope.scopeOfWork
                                                                                                )}
                                                                                            </p>
                                                                                        </div>

                                                                                        {scope.scopeOfWork.length > 220 && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="text-[#8E8E85] text-xs pl-4 underline italic"
                                                                                                onClick={() =>
                                                                                                    openFullDesc(
                                                                                                        ReactHtmlParser(
                                                                                                            scope.scopeOfWork
                                                                                                        ),
                                                                                                        "Scope of Work"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Read More
                                                                                            </button>
                                                                                        )}
                                                                                        <div className="flex space-x-2">
                                                                                            <div className="w-1/2 break-words">
                                                                                                <label className="font-semibold">
                                                                                                    Brands
                                                                                                </label>
                                                                                                {ReactHtmlParser(
                                                                                                    scope.preferredBrands
                                                                                                )}
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="font-semibold">
                                                                                                    Qty
                                                                                                </label>
                                                                                                <p className="">
                                                                                                    {scope.quantity} {scope.unit}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}

                                                                            {scope.materials.length > 0 && (
                                                                                <div>
                                                                                    {/* material */}
                                                                                    <div className="flex items-center text-sm mt-4 ">
                                                                                        <h3 className="whitespace-nowrap text-[#434343]">
                                                                                            Material list for scope -{" "}
                                                                                            {scopeIndex + 1} (
                                                                                            {scope?.materials?.length || 0}{" "}
                                                                                            {scope?.materials?.length === 1
                                                                                                ? "item"
                                                                                                : "items"}
                                                                                            )
                                                                                        </h3>
                                                                                        <hr className="flex-grow border-gray-300 ml-4" />
                                                                                    </div>

                                                                                    <div className="space-y-2 pt-2">
                                                                                        {scope?.materials.map(
                                                                                            (material, matIndex) => (
                                                                                                <div key={matIndex}>
                                                                                                    <div
                                                                                                        className="flex justify-between bg-gray-100 px-1 items-center cursor-pointer"
                                                                                                        onClick={() =>
                                                                                                            toggleExpand(matIndex)
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="flex text-sm space-x-2">
                                                                                                            <p>
                                                                                                                {scopeIndex + 1}.
                                                                                                                {matIndex + 1}
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                {" "}
                                                                                                                {ReactHtmlParser(
                                                                                                                    material.name
                                                                                                                )}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <img
                                                                                                            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                                                                                            alt="toggle"
                                                                                                            className={`w-4 h-4 transition-transform duration-200 ${expandedIndex ===
                                                                                                                matIndex
                                                                                                                ? "rotate-180"
                                                                                                                : "rotate-0"
                                                                                                                }`}
                                                                                                        />{" "}
                                                                                                    </div>
                                                                                                    {expandedIndex ===
                                                                                                        matIndex && (
                                                                                                            <div className="text-sm space-y-2 px-1 pt-2">
                                                                                                                <div className="flex space-x-2">
                                                                                                                    <div className="w-1/2">
                                                                                                                        <label className="font-semibold">
                                                                                                                            Brands
                                                                                                                        </label>
                                                                                                                        <p className="break-words whitespace-pre-line">
                                                                                                                            {material?.brands.join(
                                                                                                                                ", "
                                                                                                                            ) || "NA"}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="relative group">
                                                                                                                        <label className="font-semibold">
                                                                                                                            Qty
                                                                                                                        </label>
                                                                                                                        <p className="cursor-pointer mr-1 break-words">
                                                                                                                            {material.quantity}{" "}
                                                                                                                            {getFormattedUnit(
                                                                                                                                material.unit
                                                                                                                            )}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="flex items-center justify-between space-x-2">
                                                                                                                    <div>
                                                                                                                        <label className="flex text-center font-semibold items-center">
                                                                                                                            Material Rate
                                                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                                                i
                                                                                                                                <span className="absolute bottom-[130%] left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                                                    Material rate
                                                                                                                                    per quantity in
                                                                                                                                    INR.
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                        <div>
                                                                                                                            <Field
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].basePrice`}
                                                                                                                                type="text"
                                                                                                                                className={` focus:ring-0 h-[30px] w-[150px] p-1 text-right border-[#DCDCD0] border ${isReadOnly &&
                                                                                                                                    "bg-gray-100"
                                                                                                                                    }`}
                                                                                                                                // onChange={(e) => handleFieldChange(matIndex, 'basePrice', e.target.value, values)}
                                                                                                                                onChange={(e) =>
                                                                                                                                    handleFieldChange(
                                                                                                                                        sectionIndex,
                                                                                                                                        scopeIndex,
                                                                                                                                        matIndex,
                                                                                                                                        "basePrice",
                                                                                                                                        e.target
                                                                                                                                            .value,
                                                                                                                                        values,
                                                                                                                                        setFieldValue
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                disabled={
                                                                                                                                    isReadOnly &&
                                                                                                                                    true
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <ErrorMessage
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].basePrice`}
                                                                                                                                component="div"
                                                                                                                                className="text-red-500 text-xs "
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <label className="flex text-center items-center font-semibold self-end">
                                                                                                                            Installation Rate
                                                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                                                i
                                                                                                                                <span className="absolute bottom-[130%] left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                                                    Installation
                                                                                                                                    rate of material
                                                                                                                                    per quantity in
                                                                                                                                    INR.
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                        <div>
                                                                                                                            <Field
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].installationRate`}
                                                                                                                                type="text"
                                                                                                                                className={`lg:w-full focus:ring-0  w-[150px] h-[30px] p-1 text-right border-[#DCDCD0] border ${isReadOnly &&
                                                                                                                                    "bg-gray-100"
                                                                                                                                    }`}
                                                                                                                                onChange={(e) =>
                                                                                                                                    handleFieldChange(
                                                                                                                                        sectionIndex,
                                                                                                                                        scopeIndex,
                                                                                                                                        matIndex,
                                                                                                                                        "installationRate",
                                                                                                                                        e.target
                                                                                                                                            .value,
                                                                                                                                        values,
                                                                                                                                        setFieldValue
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                disabled={
                                                                                                                                    isReadOnly &&
                                                                                                                                    true
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <ErrorMessage
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].installationRate`}
                                                                                                                                component="div"
                                                                                                                                className="text-red-500 text-xs "
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="flex space-x-2 w-full">
                                                                                                                    <div>
                                                                                                                        <label className="block font-semibold">
                                                                                                                            Remarks
                                                                                                                        </label>
                                                                                                                        <div className="w-full">
                                                                                                                            <ReactQuill
                                                                                                                                value={
                                                                                                                                    values
                                                                                                                                        .providingFixingDetails[
                                                                                                                                        sectionIndex
                                                                                                                                    ]?.scopes[
                                                                                                                                        scopeIndex
                                                                                                                                    ]?.materials[
                                                                                                                                        matIndex
                                                                                                                                    ]?.remark || ""
                                                                                                                                }
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].remark`}
                                                                                                                                onChange={(
                                                                                                                                    value
                                                                                                                                ) => {
                                                                                                                                    handleFieldChange(
                                                                                                                                        sectionIndex,
                                                                                                                                        scopeIndex,
                                                                                                                                        matIndex,
                                                                                                                                        "remark",
                                                                                                                                        value,
                                                                                                                                        values,
                                                                                                                                        setFieldValue
                                                                                                                                    ); // Handle remark change for the material
                                                                                                                                }}
                                                                                                                                disabled={
                                                                                                                                    isReadOnly &&
                                                                                                                                    true
                                                                                                                                }
                                                                                                                                theme="snow"
                                                                                                                                className="min-h-[30px] max-h-[100px] min-w-[255px] word-break whitespace-normal focus:outline-none focus:ring-0 bg-white overflow-y-auto remove-quill-padding"
                                                                                                                                modules={{
                                                                                                                                    toolbar: false,
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <ErrorMessage
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].remark`}
                                                                                                                                component="div"
                                                                                                                                className="text-red-500 text-xs"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <label className=" text-center font-semibold self-end">
                                                                                                                            GST(%)
                                                                                                                        </label>
                                                                                                                        <div>
                                                                                                                            <CustomGstDropdown
                                                                                                                                options={[
                                                                                                                                    0, 5, 12, 18,
                                                                                                                                    28,
                                                                                                                                ]} // GST options
                                                                                                                                value={
                                                                                                                                    values
                                                                                                                                        .providingFixingDetails[
                                                                                                                                        sectionIndex
                                                                                                                                    ].scopes[
                                                                                                                                        scopeIndex
                                                                                                                                    ].materials[
                                                                                                                                        matIndex
                                                                                                                                    ]?.gst || 0
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    newValue
                                                                                                                                ) =>
                                                                                                                                    handleFieldChange(
                                                                                                                                        sectionIndex,
                                                                                                                                        scopeIndex,
                                                                                                                                        matIndex,
                                                                                                                                        "gst",
                                                                                                                                        newValue,
                                                                                                                                        values,
                                                                                                                                        setFieldValue
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                className={`w-fit h-[30px]`}
                                                                                                                                disabled={
                                                                                                                                    isReadOnly &&
                                                                                                                                    true
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <ErrorMessage
                                                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].gst`}
                                                                                                                                component="div"
                                                                                                                                className="text-red-500 h-fit"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <div className="flex items-center justify-between">
                                                                                                                        <label className="font-medium text-right col-span-3 items-center flex text-xs">
                                                                                                                            Material Cost
                                                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                                                i
                                                                                                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                                                    Total cost of
                                                                                                                                    material for
                                                                                                                                    required
                                                                                                                                    quantity
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                        <p className="col-span-3 text-right mt-3 text-xs">
                                                                                                                            {new Intl.NumberFormat(
                                                                                                                                "en-IN",
                                                                                                                                {
                                                                                                                                    minimumFractionDigits: 0,
                                                                                                                                }
                                                                                                                            ).format(
                                                                                                                                values
                                                                                                                                    .providingFixingDetails[
                                                                                                                                    sectionIndex
                                                                                                                                ]?.scopes[
                                                                                                                                    scopeIndex
                                                                                                                                ]?.materials[
                                                                                                                                    matIndex
                                                                                                                                ]?.netPrice
                                                                                                                            ) || 0}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="flex items-center justify-between">
                                                                                                                        <label className="font-medium text-right col-span-3 items-center flex text-xs">
                                                                                                                            Installation Cost
                                                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                                                i
                                                                                                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                                                    Total cost for
                                                                                                                                    installation of
                                                                                                                                    material for
                                                                                                                                    required
                                                                                                                                    quantity
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                        <p className="col-span-3 text-right mt-3 text-xs">
                                                                                                                            {new Intl.NumberFormat(
                                                                                                                                "en-IN",
                                                                                                                                {
                                                                                                                                    minimumFractionDigits: 0,
                                                                                                                                }
                                                                                                                            ).format(
                                                                                                                                values
                                                                                                                                    .providingFixingDetails[
                                                                                                                                    sectionIndex
                                                                                                                                ]?.scopes[
                                                                                                                                    scopeIndex
                                                                                                                                ]?.materials[
                                                                                                                                    matIndex
                                                                                                                                ]
                                                                                                                                    ?.installationCost ||
                                                                                                                                0
                                                                                                                            )}
                                                                                                                        </p>
                                                                                                                    </div>

                                                                                                                    <div className="flex items-center justify-between">
                                                                                                                        <label className="font-medium col-span-3 text-center flex text-xs">
                                                                                                                            Total (Excl. GST)
                                                                                                                        </label>
                                                                                                                        <p className="col-span-3 text-right mt-3 text-xs">
                                                                                                                            {new Intl.NumberFormat(
                                                                                                                                "en-IN",
                                                                                                                                {
                                                                                                                                    minimumFractionDigits: 0,
                                                                                                                                }
                                                                                                                            ).format(
                                                                                                                                values
                                                                                                                                    .providingFixingDetails[
                                                                                                                                    sectionIndex
                                                                                                                                ]?.scopes[
                                                                                                                                    scopeIndex
                                                                                                                                ]?.materials[
                                                                                                                                    matIndex
                                                                                                                                ]?.totalExclGst ||
                                                                                                                                0
                                                                                                                            )}
                                                                                                                        </p>
                                                                                                                    </div>

                                                                                                                    <div className="flex items-center">
                                                                                                                        <label className="block font-medium text-xs">
                                                                                                                            Attach.
                                                                                                                        </label>
                                                                                                                        <div className="relative mt-2">
                                                                                                                            <AttachFileIcon
                                                                                                                                className="hover:cursor-pointer"
                                                                                                                                sx={{
                                                                                                                                    width: "0.7em",
                                                                                                                                    ml: "2px",
                                                                                                                                    transform:
                                                                                                                                        "rotate(45deg)",
                                                                                                                                    transition:
                                                                                                                                        "transform 0.3s ease",
                                                                                                                                }}
                                                                                                                            // onClick={() => {
                                                                                                                            //     setSelectedMaterialIndex(matIndex); // Set the material index for file upload
                                                                                                                            //     setOpenUploadFile(true); // Open the file upload modal
                                                                                                                            // }}
                                                                                                                            />

                                                                                                                            {/* {values.providingFixingDetails[index]?.materials[matIndex]?.attachments?.length > 0 && (
                                                                                             <span
                                                                                            className="absolute top-0 right-4 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                                                 style={{ transform: 'translate(50%, -50%)' }}
                                                                                               >
                                                                                             {values.providingFixingDetails[index].materials[matIndex].attachments.length}
                                                                                                    </span>
                                                                                            )} */}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    {matIndex <
                                                                                                        scope?.materials?.length -
                                                                                                        1 && (
                                                                                                            <hr className="hidden lg:block  lg:mt-4" />
                                                                                                        )}
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="mt-4">
                                                                                        <hr />
                                                                                        <div className="flex px-2 py-2 text-xs bg-[#f7f8fa] justify-between items-center ">
                                                                                            <p className="text-[#004491] w-1/2">
                                                                                                Total Material and Installation
                                                                                                cost of scope {scopeIndex + 1}{" "}
                                                                                                (Excl. GST)
                                                                                            </p>
                                                                                            <p className="text-[#004491]">
                                                                                                Rs.{" "}
                                                                                                {new Intl.NumberFormat(
                                                                                                    "en-IN",
                                                                                                    { minimumFractionDigits: 2 }
                                                                                                ).format(
                                                                                                    values.providingFixingDetails[
                                                                                                        sectionIndex
                                                                                                    ]?.scopes[scopeIndex]
                                                                                                        ?.totalExclGst || 0
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )}

                                        {/* miscellaneous */}
                                        {/* {currentStep === 2 && (
                                            <div>
                                                

                                            </div>
                                        )} */}
                                        {currentStep === 2 && (
                                            <div>
                                                {/* Supporting Documents Section */}
                                                <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden  mt-4">
                                                    Supporting Documents
                                                </h3>
                                                <p className="text-sm font-normal text-[#9D9D9D]  pb-4">
                                                    Below are the supporting documents uploaded by the
                                                    buyer. Please review them.
                                                </p>
                                                <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 ">
                                                    {attachmentsData.length > 0 ? (
                                                        attachmentsData.map((doc, index) => (
                                                            <div
                                                                key={index}
                                                                className="flex justify-between items-center mx-2"
                                                            >
                                                                <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                                    {/* Dynamic icon based on file type */}
                                                                    <img
                                                                        src={`/assets/icons/${doc.Path}.png`}
                                                                        alt={doc.Path}
                                                                        className="h-5 w-5"
                                                                    />
                                                                    {/* Document name now clickable */}
                                                                    <a
                                                                        href={doc.Content}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download={doc.Name}
                                                                        className="text-xs text-[#434343] hover:underline"
                                                                    >
                                                                        {doc.Name}
                                                                    </a>
                                                                </div>
                                                                <img
                                                                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/download_solid.svg"
                                                                    alt=""
                                                                    className="lg:hidden"
                                                                />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <div className="text-gray-400 italic">
                                                                No Supporting Documents provided by Buyer, move
                                                                to next step{" "}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                <div className=" bg-white shadow-md">
                                                    {hasVisibleMiscellaneousItems && (
                                                        <div className="">
                                                            <h3 className="text-sm font-semibold text-[#3B3B3B] mb-2 mt-4">
                                                                Additional Cost Components
                                                            </h3>

                                                            <div className="text-xs border space-y-4 bg-white py-4 shadow-md">

                                                                {/* Render visible miscellaneous items */}
                                                                {visibleMiscellaneousItems.map((item) => (
                                                                    <div
                                                                        key={item.name}
                                                                        className="space-y-3 items-center"
                                                                    >
                                                                        <label className="block px-4 col-span-1 font-semibold lg:font-medium ">
                                                                            {item.label}
                                                                        </label>


                                                                        <div className="flex justify-between items-center px-4">
                                                                            {/* MRP Input Field */}
                                                                            <div>
                                                                                <label className="font-medium col-span-1 flex relative">
                                                                                    Cost (Excl. GST)
                                                                                    <span className="bg-[#cac9c9] text-white text-xs h-[12px] w-[12px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                                        i
                                                                                        <span className="absolute bottom-[130%] text-xs left-1/2 w-[140px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                            enter total amount of additional cost component.
                                                                                        </span>
                                                                                    </span>
                                                                                </label>
                                                                                <div className="col-span-1 w-full">
                                                                                    <Field
                                                                                        name={`miscellaneousCosts.${item.name}.mrp`}
                                                                                        type="text"
                                                                                        className={`w-fit text-xs text-right lg:block lg:w-full focus:ring-0 h-[30px] placeholder-[#373737] p-2 border border-gray-300 ${isReadOnly && "bg-gray-100"
                                                                                            }`}
                                                                                        placeholder="Rs."
                                                                                        onChange={(e) =>
                                                                                            handleMiscellaneousChange(
                                                                                                item.name,
                                                                                                e.target.value,
                                                                                                setFieldValue,
                                                                                                values,
                                                                                                "mrp"
                                                                                            )
                                                                                        }
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`miscellaneousCosts.${item.name}.mrp`}
                                                                                        component="div"
                                                                                        className="text-red-500 text-xs mt-1"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {/* GST Dropdown */}
                                                                            <div>
                                                                                <label className="mb-1">
                                                                                    GST (%)
                                                                                </label>
                                                                                <div className="flex justify-center w-[64px] h-fit">
                                                                                    <CustomGstDropdown
                                                                                        options={[0, 5, 12, 18, 28]}
                                                                                        value={
                                                                                            values.miscellaneousCosts[item.name]?.gst
                                                                                        }
                                                                                        onChange={(value) =>
                                                                                            handleMiscGstChange(
                                                                                                item.name,
                                                                                                value,
                                                                                                setFieldValue,
                                                                                                values
                                                                                            )
                                                                                        }
                                                                                        className=" w-[262px] justify-between h-[30px]"
                                                                                        disabled={isReadOnly}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`miscellaneousCosts.${item.name}.gst`}
                                                                                        component="div"
                                                                                        className="text-red-500 text-xs mt-1"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* Remark */}
                                                                        <div className="px-4">
                                                                            <label className="block mb-1 font-medium col-span-1">
                                                                                Remarks
                                                                            </label>
                                                                            <div className="col-span-3 flex justify-center h-fit w-full">
                                                                                <ReactQuill
                                                                                    value={
                                                                                        values.miscellaneousCosts[item.name]
                                                                                            ?.remark || ""
                                                                                    }
                                                                                    name={`miscellaneousCosts.${item.name}.remark`}
                                                                                    onChange={(value) =>
                                                                                        handleMiscellaneousChange(
                                                                                            item.name,
                                                                                            value,
                                                                                            setFieldValue,
                                                                                            values,
                                                                                            "remark"
                                                                                        )
                                                                                    }
                                                                                    disabled={isReadOnly}
                                                                                    theme="snow"
                                                                                    className="min-h-[20px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white border-[#DCDCD0] border w-full overflow-y-auto remove-quill-padding"
                                                                                    modules={{ toolbar: false }}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`miscellaneousCosts.${item.name}.remark`}
                                                                                    component="div"
                                                                                    className="text-red-500 text-xs"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* Total Cost */}
                                                                        {/* <div className="flex justify-between lg:justify-end items-center border border-t-[#004491] py-3 bg-[#E7EAEF] lg:border-none lg:bg-white">
                                                            <p className="text-[#004491] font-bold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2)) || '0.00'}
                                                            </p>
                                                        </div> */}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* Total Amount Section */}
                                                    <div className="">
                                                        <hr />
                                                        <div className="flex px-4 py-1 justify-between items-center">
                                                            <p className="text-sm">
                                                                Total Basic Amount
                                                            </p>
                                                            <p className="text-xs text-end lg:font-normal text-black w-fit min-w-[164px] py-2">
                                                                Rs.{" "}
                                                                {new Intl.NumberFormat("en-IN", {
                                                                    minimumFractionDigits: 2,
                                                                }).format(values?.overallExclGst || 0)}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* GST Amount Section */}
                                                    <div className="">
                                                        <hr className="hidden lg:block" />
                                                        <div className="flex px-4 pb-2 justify-between items-center">
                                                            <p className="text-sm">
                                                                GST Total
                                                            </p>
                                                            <p className="text-xs text-end w-fit min-w-[164px]  py-2">
                                                                Rs.{" "}
                                                                {new Intl.NumberFormat("en-IN", {
                                                                    minimumFractionDigits: 2,
                                                                }).format(
                                                                    values?.overallWithGst - values?.overallExclGst || 0
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Total Quotation Value Amount Section */}
                                                    <div className="">
                                                        <hr className="hidden lg:block" />
                                                        <div className="flex px-4 py-2 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                            <p className="text-[#007366] lg:text-black text-sm">
                                                                Total Quotation Value (inc. GST)
                                                            </p>
                                                            <p className="bg-[#E0F0EE] text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                                Rs.{" "}
                                                                {new Intl.NumberFormat("en-IN", {
                                                                    minimumFractionDigits: 2,
                                                                }).format(values?.overallWithGst)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {currentStep === 3 && (
                                            <div>
                                                {/* Additional Information Section */}
                                                <div className="mb-8">
                                                    <h3 className="text-sm mt-4 font-semibold text-[#3B3B3B] lg:hidden">
                                                        Additional Information
                                                    </h3>
                                                    <p className="text-sm font-normal lg:mx-0 pb-2 pt-4 lg:hidden">
                                                        Below are our payment Terms.{" "}
                                                    </p>

                                                    <div className="lg:flex justify-between items-center">
                                                        <div>
                                                            <div className="bg-[#F6F6F4] py-2 px-4 lg:w-[390px] lg:h-[182px] lg:mx-0 ">
                                                                <p
                                                                    className="w-full border-none p-0 bg-[#F6F6F4] text-[#3D3D3D] text-sm"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            rfqData?.paymentTerms.length > 180
                                                                                ? rfqData.paymentTerms.slice(0, 180) +
                                                                                "..."
                                                                                : rfqData.paymentTerms,
                                                                    }}
                                                                ></p>

                                                                {rfqData?.paymentTerms.length > 180 && (
                                                                    <button
                                                                        onClick={togglePopup}
                                                                        type="button"
                                                                        className="text-[#8E8E85] text-xs underline pt-2 italic"
                                                                    >
                                                                        Read More
                                                                    </button>
                                                                )}

                                                                {/* Popup Modal */}
                                                                {isPopupOpen && (
                                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
                                                                        <div
                                                                            className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full max-h-[320px] relative"
                                                                            style={{
                                                                                overflowY: "scroll",
                                                                                msOverflowStyle: "none",
                                                                                scrollbarWidth: "none",
                                                                            }}
                                                                        >
                                                                            <style>
                                                                                {` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                          scrollbar-width: none; /* Firefox */
                                                                             }
                                                                          .hide-scrollbar::-webkit-scrollbar {
                                                                           display: none; /* Safari and Chrome */
                                                                           }
                                                                         `}
                                                                            </style>
                                                                            <div className="sticky top-0 bg-white pb-4">
                                                                                {/* Close Button */}
                                                                                <button
                                                                                    onClick={togglePopup}
                                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                                >
                                                                                    <svg
                                                                                        className="w-6 h-6"
                                                                                        aria-hidden="true"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                    >
                                                                                        <path
                                                                                            stroke="currentColor"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M6 18L18 6M6 6l12 12"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                <h2 className="text-lg font-semibold">
                                                                                    Payment Details
                                                                                </h2>
                                                                                <hr />
                                                                            </div>
                                                                            <p
                                                                                className="text-sm text-[#3D3D3D] overflow-y-auto "
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: rfqData?.paymentTerms,
                                                                                }}
                                                                            ></p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="lg:hidden text-sm mx-4 justify-between items-center py-4">
                                                            <p>Do you agree ?</p>
                                                            <div className="flex space-x-12 mt-2">
                                                                <label className="inline-flex items-center">
                                                                    <input
                                                                        type="radio"
                                                                        name="paymentTerms"
                                                                        className="form-radio"
                                                                    />
                                                                    <span className="ml-2 ">Yes</span>
                                                                </label>
                                                                <label className="inline-flex items-center">
                                                                    <input
                                                                        type="radio"
                                                                        name="paymentTerms"
                                                                        className="form-radio"
                                                                    />
                                                                    <span className="ml-2">Counter Offer</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="font-semibold text-sm text-[#4F4F42]">
                                                                Counter Payment Terms
                                                            </p>
                                                            <Field
                                                                as="textarea"
                                                                name="counterPaymentTerms"
                                                                className="lg:w-[390px] w-full text-sm h-[144px] my-2 border border-gray-300 placeholder:italic"
                                                                placeholder="Please write your counter payment terms Rich Text "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" mt-4">
                                                        <p className="text-[#434343] mb-2 lg:mb-0">Notes</p>
                                                        <Field
                                                            as="textarea"
                                                            name="notes"
                                                            placeholder="Add Notes (Optional) "
                                                            className="border-[#CDCDB8] w-full placeholder:text-sm lg:ml-5 lg:w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Upload Supporting Documents */}
                                                <div className="pb-12 ">
                                                    <h3 className="whitespace-nowrap text-[#434343] text-sm font-bold pb-4">
                                                        Upload Supporting Documents
                                                    </h3>

                                                    {attachmentsSkeleton.length > 0 && (
                                                        <p className="text-xs pl-3">
                                                            Your file is being scanned for upload, be patient
                                                            and continue to fill the rest of the form.
                                                        </p>
                                                    )}

                                                    <div className="">
                                                        {/* Display the selected file names */}
                                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {selectedFilesBase.map((file, index) => (
                                                                <a
                                                                    href={file.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                                    key={file.Name}
                                                                >
                                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                                        {file.Name}
                                                                    </span>
                                                                    <span
                                                                        className="ml-1 cursor-pointer"
                                                                        onClick={(event) =>
                                                                            handleDeleteSelectedFile(file.Name, event)
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 48 48"
                                                                        >
                                                                            <circle
                                                                                cx="24"
                                                                                cy="24"
                                                                                r="22"
                                                                                fill="gray"
                                                                            />
                                                                            <line
                                                                                x1="15"
                                                                                y1="15"
                                                                                x2="33"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                            <line
                                                                                x1="33"
                                                                                y1="15"
                                                                                x2="15"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            ))}

                                                            {/* Skeleton loaders for files being processed */}
                                                            {attachmentsSkeleton.map((_, index) => (
                                                                <Skeleton
                                                                    key={index}
                                                                    variant="rounded"
                                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                                    width={150}
                                                                    height={32}
                                                                />
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div>
                                                        {attachmentsUploadsErrors && (
                                                            <div className="mt-3">
                                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                                    {attachmentsUploadsErrors}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Error handling for file size */}
                                                    <div
                                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                                            }`}
                                                    >
                                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                                            <p>
                                                                Total size of attachments is not more than 10 MB
                                                            </p>
                                                            <button
                                                                onClick={handleCloseFileSizeError}
                                                                className="px-4 py-2 focus:outline-none"
                                                            >
                                                                <svg
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {/* drag and drop file */}
                                                    <div className="mb-10 flex items-center justify-center h-[175px] bg-[#F1F7FF] border-2 border-dashed border-[#004491] relative">
                                                        <div className="lg:hidden flex flex-col items-center justify-center">
                                                            <img
                                                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg"
                                                                alt=""
                                                                className="mx-auto"
                                                            />
                                                            <p className="text-center text-sm mt-4">
                                                                Upload your files here
                                                            </p>
                                                            <label
                                                                htmlFor="file"
                                                                className="text-[#1D28FA] text-sm underline text-center cursor-pointer"
                                                            >
                                                                Browse
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                            onClick={handleFileUploadClick}
                                                            onChange={handleFileUpload}
                                                            multiple // Allow multiple files to be selected
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* desktop */}
                        <div className="hidden lg:block h-full bg-[#F6F6F4] pb-8">
                            {/* Header Section */}
                            <div className="z-20 sticky top-0 lg:grid lg:grid-cols-3 flex items-center flex-col-reverse lg:px-8 lg:py-2 shadow-md bg-white">
                                <div className="lg:flex items-center hidden ">
                                    {/* <h1 className="text-[28px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)} </h1>
                                        <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                    <button
                                        type="button"
                                        onClick={() =>
                                            isEdit
                                                ? navigate(-1)
                                                : supplierId
                                                    ? navigate("/leads")
                                                    : navigate(-1)
                                        }
                                        className="text-sm bg-[#E9E9E9] cursor:pointer px-4 py-1 my-1 text-[#434343]"
                                    >
                                        Back
                                    </button>
                                </div>
                                {/* <div className="flex justify-center items-center lg:items-start lg:justify-start my-4">

                                    <div className="lg:w-9 lg:h-9 w-6 h-6 mx-3 flex justify-center items-center rounded-full bg-[#DFF1C8] opacity-95">
                                        <div className="lg:w-[18px] lg:h-[18px] w-3 h-3 rounded-full bg-lime-500"></div>
                                    </div>

                                    <h3 className="lg:text-2xl lg:mt-1 text-center text-sm text-[#737373]">Live Rank: <span className="font-bold text-black">L2</span></h3>
                                </div> */}
                                {/* <div className="flex items-center justify-center space-x-2">
                                    <span class="relative flex items-center justify-center h-4 w-4 ">
                                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                        <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                    </span>
                                    <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                        Quotation Rank:{" "}
                                        <span className="font-bold text-black">
                                            {currentRank === "L1"
                                                ? "L2"
                                                : currentRank === undefined || currentRank === null
                                                    ? "Quotation not submitted"
                                                    : currentRank}
                                        </span>
                                    </h3>
                                    <span className="bg-[#cac9c9] text-white text-xs h-[12px] w-[12px] pt-1 flex justify-center items-center rounded-full relative group hover:cursor-pointer">
                                        i
                                        <span className="absolute -bottom-5 hidden w-max  text-black text-xs  group-hover:block">
                                            {currentRank === undefined || currentRank === null
                                                ? "Quotation rank is available after quotation submission"
                                                : "Based on your submitted quotation"}
                                        </span>
                                    </span>
                                    <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p>
                                </div> */}
                            </div>
                            <div className="h-full overflow-y-auto overflow-x-hidden">
                                {/* rfq information */}
                                                <RfqInformation 
                                                    rfqData={rfqData}
                                                    togglePopup={togglePopup}
                                                    isPopupOpen={isPopupOpen}
                                                    />
                                <div className="lg:px-8 space-y-6 px-4">
                                    {/* line items */}
                                    <div>
                                        {/* Providing & Fixing Section */}
                                        <div className="flex items-center mb-2">
                                            <h3 className="whitespace-nowrap text-[#434343] hidden lg:block uppercase text-sm font-semibold">
                                                Cost Components
                                            </h3>
                                            <hr className="flex-grow border-t hidden lg:block border-gray-300 mx-4" />
                                            <div className="flex flex-col items-end space-y-2">
                                                <div className="flex space-x-4">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDownloadExcelForContractor(isEditing, rfqData, contractorDetails, setExcelLoading, setLoadingMessage)}
                                                        className="bg-blue-500 text-white px-3 py-1 text-xs"
                                                    >
                                                        Download Excel
                                                    </button>
                                                    <label className="border border-gray-800 px-3 py-1 cursor-pointer text-xs">
                                                        Upload Excel
                                                        <input
                                                            type="file"
                                                            accept=".xlsx"
                                                            onChange={(event) => handleUploadMaterialExcel(event,
                                                                setFieldValue,
                                                                values,
                                                                rfqData,
                                                                setExcelLoading,
                                                                setLoadingMessage,
                                                                enqueueSnackbar,
                                                                calculateOverallTotals,
                                                                calculateMaterialTotals,
                                                                calculateScopeTotals)}
                                                            className="hidden"
                                                        />
                                                    </label>
                                                </div>
                                                <p className="text-[10px] italic text-gray-500">Note: Download Excel to upload material list</p>
                                            </div>
                                        </div>


                                        {/* <p className="text-xl font-bold text-[#434343] hidden lg:block">Line Items</p> */}

                                        {/* scope of work*/}
                                        <div>
                                            {values.providingFixingDetails.map(
                                                (section, sectionIndex) => (
                                                    <div key={sectionIndex} className="mb-4">
                                                        {/* Accordion Header */}
                                                        <div
                                                            onClick={() => toggleSection(sectionIndex)}
                                                            className="flex justify-between items-center bg-gray-200 px-4 py-2 cursor-pointer text-sm"
                                                        >
                                                            <div>{section.sectionName}</div>
                                                            <div className="flex justify-end items-center space-x-4">
                                                                <p>
                                                                    Section Total (Excl. GST) :{" "}
                                                                    {new Intl.NumberFormat("en-IN", {
                                                                        minimumFractionDigits: 2,
                                                                    }).format(section.sectionTotalExclGst || 0)}
                                                                </p>
                                                                <img
                                                                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                                                    alt="toggle"
                                                                    className={`transition-transform duration-200 ${openSections[sectionIndex]
                                                                        ? "rotate-180"
                                                                        : "rotate-0"
                                                                        }`}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Accordion Body */}
                                                        {openSections[sectionIndex] && (
                                                            <div className="border-2 bg-white">
                                                                {section.scopes.map((scope, scopeIndex) => (
                                                                    <div key={scopeIndex} className="mb-6 px-4">
                                                                        {scope?.materials?.length === 0 ? (
                                                                            <>
                                                                                {/* <p className="text-xs mb-[10px] mt-2 font-medium text-[#434343] hidden lg:block ">Your bid value is the sum of these line items</p> */}
                                                                                <div className=" my-2 hidden lg:grid lg:grid-cols-22 lg:gap-1 text-[#777777] text-xs font-normal border-y py-2">
                                                                                    <label className="block self-end font-medium col-span-1 ">
                                                                                        Sr#
                                                                                    </label>
                                                                                    <label className="block self-end font-medium col-span-3 ">
                                                                                        Description
                                                                                    </label>
                                                                                    <label className="block self-end font-medium col-span-2 ">
                                                                                        Brands
                                                                                    </label>
                                                                                    <label className="block self-end font-medium col-span-1 ">
                                                                                        Qty
                                                                                    </label>
                                                                                    <label className="block self-end font-medium col-span-2 text-center ">
                                                                                        Material<br /> Rate
                                                                                    </label>
                                                                                    <label className="block self-end font-medium col-span-2 text-center ">
                                                                                        Installation <br />Rate
                                                                                    </label>
                                                                                    <label className="block self-end font-medium text-right col-span-1 ">
                                                                                        GST (%)
                                                                                    </label>
                                                                                    <label className="block self-end text-center font-medium col-span-4 ">
                                                                                        Comments / Methods
                                                                                    </label>
                                                                                    <label className="block self-end text-right font-medium col-span-2 ">
                                                                                        Supply<br /> Cost
                                                                                    </label>
                                                                                    <label className="block self-end text-right font-medium col-span-2 ">
                                                                                        Installation<br /> Cost
                                                                                    </label>

                                                                                    {/* <label className="block font-medium text-right col-span-1 uppercase">Delivery Date</label> */}
                                                                                    {/* <label className="block font-medium text-center col-span-2 uppercase">Work Start Date</label> */}
                                                                                    <label className="block self-end font-medium text-right col-span-2">
                                                                                        Total<br /> (Excl. GST)
                                                                                    </label>
                                                                                </div>

                                                                                <div className="lg:grid lg:grid-cols-22 text-xs lg:gap-1">
                                                                                    <p className="self-start">
                                                                                        SoW {scopeIndex + 1}
                                                                                    </p>
                                                                                    {/* description */}
                                                                                    <div className="col-span-3 w-full">
                                                                                        <p className="font-light whitespace-pre-line">
                                                                                            {ReactHtmlParser(
                                                                                                expandedStates[`scopeOfWork${scopeIndex}`] // Use "scopeOfWork" as the key
                                                                                                    ? scope.scopeOfWork // Show full text if expanded
                                                                                                    : scope.scopeOfWork.substring(
                                                                                                        0,
                                                                                                        130
                                                                                                    ) +
                                                                                                    (scope.scopeOfWork.length >
                                                                                                        130
                                                                                                        ? "..."
                                                                                                        : "")
                                                                                            )}
                                                                                        </p>
                                                                                        {scope.scopeOfWork.length > 130 && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="text-[#8E8E85] text-xs underline italic"
                                                                                                onClick={() =>
                                                                                                    toggleExpanded(`scopeOfWork${scopeIndex}`)
                                                                                                } // Pass the key to toggle the state
                                                                                            >
                                                                                                {expandedStates[`scopeOfWork${scopeIndex}`]
                                                                                                    ? "Read Less"
                                                                                                    : "Read More"}
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                    <p className="col-span-2 break-words">
                                                                                        {ReactHtmlParser(
                                                                                            scope?.preferredBrands.join(", ")
                                                                                        )}
                                                                                    </p>
                                                                                    <p className="col-span-1 text-xs cursor-pointer mr-1 break-words">
                                                                                        {scope.quantity}{" "}
                                                                                        {getFormattedUnit(scope.unit)}
                                                                                    </p>

                                                                                    {/* base price */}
                                                                                    <div className="col-span-2 px-4 lg:px-0">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].basePrice`}
                                                                                            className={`p - 2 lg:w-full w-[262px] focus:ring-0 text-right h-[28px] border text-xs border-gray-300 placeholder-[#373737] ${isReadOnly && "bg-gray-100"
                                                                                                }`}
                                                                                            // value={values.basePrice}
                                                                                            onChange={(e) =>
                                                                                                handleFieldChange(
                                                                                                    sectionIndex,
                                                                                                    scopeIndex,
                                                                                                    null,
                                                                                                    "basePrice",
                                                                                                    e.target.value,
                                                                                                    values,
                                                                                                    setFieldValue
                                                                                                )
                                                                                            }
                                                                                            disabled={isReadOnly}
                                                                                        />
                                                                                        <div className="w-fit">
                                                                                            <ErrorMessage
                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].basePrice`}
                                                                                                component="div"
                                                                                                className="text-red-500 text-xs absolute mt-1"
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Installation price */}
                                                                                    <div className="col-span-2 px-4 lg:px-0">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].installationRate`}
                                                                                            className={`p - 2 lg:w-full w-[262px] focus:ring-0 text-right h-[28px] border text-xs border-gray-300 placeholder-[#373737] ${isReadOnly && "bg-gray-100"
                                                                                                }`}
                                                                                            // value={values.installationRate}
                                                                                            onChange={(e) =>
                                                                                                handleFieldChange(
                                                                                                    sectionIndex,
                                                                                                    scopeIndex,
                                                                                                    null,
                                                                                                    "installationRate",
                                                                                                    e.target.value,
                                                                                                    values,
                                                                                                    setFieldValue
                                                                                                )
                                                                                            }
                                                                                            disabled={isReadOnly}
                                                                                        />
                                                                                        <div className="w-fit">
                                                                                            <ErrorMessage
                                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].installationRate`}
                                                                                                component="div"
                                                                                                className="text-red-500 text-xs absolute mt-1"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* gst */}
                                                                                    <div className="col-span-1 w-full h-fit items-center">
                                                                                        <CustomGstDropdown
                                                                                            options={[0, 5, 12, 18, 28]} // GST options
                                                                                            value={scope.gst}
                                                                                            onChange={(newValue) =>
                                                                                                handleFieldChange(
                                                                                                    sectionIndex,
                                                                                                    scopeIndex,
                                                                                                    null,
                                                                                                    "gst",
                                                                                                    newValue,
                                                                                                    values,
                                                                                                    setFieldValue
                                                                                                )
                                                                                            }
                                                                                            className="lg:w-full h-[28px]"
                                                                                            disabled={isReadOnly}
                                                                                        />
                                                                                        {/* <ErrorMessage name={lineItems[${index}].gst} component="div" className="text-red-500 text-sm h-fit" /> */}
                                                                                    </div>
                                                                                    {/* comments/methods */}
                                                                                    <div className="col-span-4 px-4 lg:px-0">
                                                                                        <ReactQuill
                                                                                            value={
                                                                                                values.providingFixingDetails[
                                                                                                    sectionIndex
                                                                                                ]?.scopes[scopeIndex]?.comments ||
                                                                                                ""
                                                                                            }
                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].comments`}
                                                                                            onChange={(value) => {
                                                                                                setFieldValue(
                                                                                                    `providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].comments`,
                                                                                                    value
                                                                                                );
                                                                                            }}
                                                                                            disabled={isReadOnly}
                                                                                            theme="snow"
                                                                                            className="min-h-[30px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white w-full overflow-y-auto remove-quill-padding"
                                                                                            modules={{ toolbar: false }}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].comments`}
                                                                                            component="div"
                                                                                            className="text-red-500 text-xs"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-span-2 flex justify-between lg:justify-end w-full border border-t-[#004491] lg:border-none">
                                                                                        <div className="font-bold lg:font-normal text-xs text-right lg:text-black text-[#004491]">
                                                                                            {new Intl.NumberFormat("en-IN", {
                                                                                                minimumFractionDigits: 2,
                                                                                            }).format(scope?.supplyCost || 0)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-span-2 flex justify-between lg:justify-end w-full border border-t-[#004491] lg:border-none">
                                                                                        <div className="font-bold lg:font-normal text-xs text-right lg:text-black text-[#004491]">
                                                                                            {new Intl.NumberFormat("en-IN", {
                                                                                                minimumFractionDigits: 2,
                                                                                            }).format(
                                                                                                scope?.installationCost || 0
                                                                                            )}
                                                                                        </div>
                                                                                    </div>

                                                                                    {/*work start date */}
                                                                                    {/* <div className="col-span-2 relative">
                                                                            <Field
                                                                                type="date"
                                                                                name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].workStartDate`}
                                                                                className={`lg:w-full focus:ring-0 p-1 h-[30px] text-xs border border-gray-300 placeholder-[#373737] ${isReadOnly && "bg-gray-100"}`}
                                                                                disabled={isReadOnly}
                                                                            />
                                                                            <div className="absolute mt-1 text-red-500">
                                                                                <ErrorMessage name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].workStartDate`} component="div" className="text-red-500 text-xs mt-1" />
                                                                            </div>
                                                                        </div> */}
                                                                                    {/* total amount */}
                                                                                    <div className="col-span-2 flex justify-between lg:justify-end w-full border border-t-[#004491] lg:border-none">
                                                                                        <div className="h-[40px] font-bold lg:font-normal text-xs text-right lg:text-black text-[#004491]">
                                                                                            {new Intl.NumberFormat("en-IN", {
                                                                                                minimumFractionDigits: 2,
                                                                                            }).format(scope?.totalExclGst || 0)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="hidden lg:grid lg:grid-cols-22 lg:gap-2 text-[#777777] text-xs font-normal border-y py-2">
                                                                                    <label className="block font-medium col-span-1 ">
                                                                                        Sr#
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-11 ">
                                                                                        Description (Work Details)
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-5 ">
                                                                                        Brands
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-5 ">
                                                                                        Qty
                                                                                    </label>
                                                                                    {/* <label className="block font-medium col-span-2 text-center">Attachments</label> */}
                                                                                </div>
                                                                                <div className="lg:grid lg:grid-cols-22 lg:gap-2 text-xs pt-2">
                                                                                    <p>SoW {scopeIndex + 1}</p>

                                                                                    <div className="col-span-11 w-full">
                                                                                        <p className="font-light whitespace-pre-line">
                                                                                            {ReactHtmlParser(
                                                                                                expandedStates[
                                                                                                    "scopeOfWorkWithMaterial"
                                                                                                ] // Use "scopeOfWork" as the key
                                                                                                    ? scope.scopeOfWork // Show full text if expanded
                                                                                                    : scope.scopeOfWork.substring(
                                                                                                        0,
                                                                                                        250
                                                                                                    ) +
                                                                                                    (scope.scopeOfWork.length >
                                                                                                        250
                                                                                                        ? "..."
                                                                                                        : "")
                                                                                            )}
                                                                                        </p>
                                                                                        {scope.scopeOfWork.length > 250 && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="text-[#8E8E85] text-xs underline italic"
                                                                                                onClick={() =>
                                                                                                    toggleExpanded(
                                                                                                        "scopeOfWorkWithMaterial"
                                                                                                    )
                                                                                                } // Pass the key to toggle the state
                                                                                            >
                                                                                                {expandedStates[
                                                                                                    "scopeOfWorkWithMaterial"
                                                                                                ]
                                                                                                    ? "Read Less"
                                                                                                    : "Read More"}
                                                                                            </button>
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="col-span-5 break-words">
                                                                                        {ReactHtmlParser(
                                                                                            scope.preferredBrands
                                                                                        )}
                                                                                    </div>

                                                                                    <p className="col-span-5">
                                                                                        {scope.quantity}{" "}
                                                                                        {getFormattedUnit(scope.unit)}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {scope.materials.length > 0 && (
                                                                            <div>
                                                                                {/* material */}
                                                                                <div className="lg:flex items-center hidden mt-4 ">
                                                                                    <h3 className="whitespace-nowrap text-[#434343] text-xs">
                                                                                        Material list for scope -{" "}
                                                                                        {scopeIndex + 1} (
                                                                                        {scope?.materials?.length || 0}{" "}
                                                                                        {scope?.materials?.length === 1
                                                                                            ? "item"
                                                                                            : "items"}
                                                                                        )
                                                                                    </h3>
                                                                                    <hr className="flex-grow border-gray-300 ml-4" />
                                                                                </div>

                                                                                <div className="hidden lg:grid lg:grid-cols-22 gap-1 text-end self-end  text-[#777777] text-sm font-normal border-b py-2">
                                                                                    <label className="block font-medium col-span-1 text-start self-end text-xs">
                                                                                        Sr#
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-3 text-start self-end text-xs">
                                                                                        Material
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-2 text-start self-end text-xs">
                                                                                        Brands
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-1 text-start self-end text-xs">
                                                                                        Qty
                                                                                    </label>
                                                                                    {/* <label className="block font-medium col-span-7 text-center text-xs">Specifications</label> */}
                                                                                    <label className="font-medium text-center items-center col-span-2 self-end text-xs">
                                                                                        Material <br />{" "}
                                                                                        <span className="flex justify-center items-center">
                                                                                            {" "}
                                                                                            Rate
                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                i
                                                                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                    Material rate per quantity in
                                                                                                    INR.
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <label className="font-medium text-center items-center col-span-2 self-end text-xs">
                                                                                        Installation <br />{" "}
                                                                                        <span className="flex justify-center items-center">
                                                                                            {" "}
                                                                                            Rate
                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                i
                                                                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                    Installation rate of material
                                                                                                    per quantity in INR.
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <label className="block font-medium text-center col-span-1 self-end text-xs">
                                                                                        GST(%)
                                                                                    </label>
                                                                                    <label className="block font-medium col-span-4 text-center self-end text-xs">
                                                                                        Remarks
                                                                                    </label>
                                                                                    <label className="font-medium text-right col-span-2 items-center self-end justify-end text-xs">
                                                                                        Material <br />{" "}
                                                                                        <span className="flex text-right justify-end items-center">
                                                                                            {" "}
                                                                                            Cost
                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                i
                                                                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                    Total cost of material for
                                                                                                    required quantity
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <label className="font-medium text-right col-span-2 items-center self-end justify-end text-xs">
                                                                                        Installation <br />{" "}
                                                                                        <span className="flex text-right justify-end items-center">
                                                                                            {" "}
                                                                                            Cost
                                                                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center pt-1 rounded-full relative group hover:cursor-pointer">
                                                                                                i
                                                                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                                    Total cost for installation of
                                                                                                    material for required quantity
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <label className=" font-medium col-span-2 text-center justify-end self-end flex text-xs">
                                                                                        Total <br /> (Excl. GST)
                                                                                    </label>
                                                                                    {/* <label className="block font-medium col-span-2 text-center self-end text-xs">Attach.</label> */}
                                                                                </div>

                                                                                <div className="lg:space-y-2 space-y-6 pt-2">
                                                                                    {scope?.materials.map(
                                                                                        (material, matIndex) => (
                                                                                            <div >
                                                                                                {/* desktop */}
                                                                                                <div key={matIndex} className="hidden lg:grid lg:grid-cols-22 gap-1 text-[#373737]">
                                                                                                    <p className="text-xs col-span-1 text-start whitespace-pre-line ">
                                                                                                        M {scopeIndex + 1}.
                                                                                                        {matIndex + 1}
                                                                                                    </p>
                                                                                                    <p className="text-xs col-span-3 whitespace-normal word-break">
                                                                                                        {ReactHtmlParser(
                                                                                                            material.name
                                                                                                        )}
                                                                                                    </p>
                                                                                                    <p className="text-xs col-span-2 break-words whitespace-pre-line">
                                                                                                        {material?.brands.join(
                                                                                                            ", "
                                                                                                        ) || "NA"}
                                                                                                    </p>
                                                                                                    <div className="relative group col-span-1">
                                                                                                        <p className="text-xs cursor-pointer mr-1 break-words">
                                                                                                            {material.quantity}{" "}{getFormattedUnit(material.unit)}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    {/* <div className="col-span-7 min-h-fit">
                                                                                        <p className="text-xs font-light whitespace-pre-line">
                                                                                            {ReactHtmlParser(material.specifications.length > 85 ? material.specifications.substring(0, 110) + "..." : material.specifications)}
                                                                                        </p>
                                                                                        {material.specifications.length > 85 && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="text-[#8E8E85] text-xs underline italic"
                                                                                                onClick={() => openFullDesc(ReactHtmlParser(material.specifications))}
                                                                                            >
                                                                                                Read More
                                                                                            </button>
                                                                                        )}
                                                                                    </div> */}
                                                                                                    <div className="col-span-2 flex flex-col">
                                                                                                        <Field
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].basePrice`}
                                                                                                            type="text"
                                                                                                            className={`lg:w-full focus:ring-0 w-[220px] h-[30px] p-1 text-right text-xs border-[#DCDCD0] border ${isReadOnly &&
                                                                                                                "bg-gray-100"
                                                                                                                }`}
                                                                                                            // onChange={(e) => handleFieldChange(matIndex, 'basePrice', e.target.value, values)}
                                                                                                            onChange={(e) =>
                                                                                                                handleFieldChange(
                                                                                                                    sectionIndex,
                                                                                                                    scopeIndex,
                                                                                                                    matIndex,
                                                                                                                    "basePrice",
                                                                                                                    e.target.value,
                                                                                                                    values,
                                                                                                                    setFieldValue
                                                                                                                )
                                                                                                            }
                                                                                                            disabled={
                                                                                                                isReadOnly && true
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].basePrice`}
                                                                                                            component="div"
                                                                                                            className="text-red-500 text-xs "
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col-span-2 flex flex-col">
                                                                                                        <Field
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].installationRate`}
                                                                                                            type="text"
                                                                                                            className={`lg:w-full focus:ring-0 w-[220px] h-[30px] p-1 text-right text-xs border-[#DCDCD0] border ${isReadOnly &&
                                                                                                                "bg-gray-100"
                                                                                                                }`}
                                                                                                            onChange={(e) =>
                                                                                                                handleFieldChange(
                                                                                                                    sectionIndex,
                                                                                                                    scopeIndex,
                                                                                                                    matIndex,
                                                                                                                    "installationRate",
                                                                                                                    e.target.value,
                                                                                                                    values,
                                                                                                                    setFieldValue
                                                                                                                )
                                                                                                            }
                                                                                                            disabled={
                                                                                                                isReadOnly && true
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].installationRate`}
                                                                                                            component="div"
                                                                                                            className="text-red-500 text-xs "
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col-span-1 ">
                                                                                                        <CustomGstDropdown
                                                                                                            options={[0, 5, 12, 18, 28]} // GST options
                                                                                                            value={
                                                                                                                values
                                                                                                                    .providingFixingDetails[
                                                                                                                    sectionIndex
                                                                                                                ].scopes[scopeIndex]
                                                                                                                    .materials[matIndex]
                                                                                                                    ?.gst || 0
                                                                                                            }
                                                                                                            onChange={(newValue) =>
                                                                                                                handleFieldChange(
                                                                                                                    sectionIndex,
                                                                                                                    scopeIndex,
                                                                                                                    matIndex,
                                                                                                                    "gst",
                                                                                                                    newValue,
                                                                                                                    values,
                                                                                                                    setFieldValue
                                                                                                                )
                                                                                                            }
                                                                                                            className={`w-full text-xs h-[30px]`}
                                                                                                            disabled={
                                                                                                                isReadOnly && true
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].gst`}
                                                                                                            component="div"
                                                                                                            className="text-red-500 text-xs h-fit"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col-span-4 ">
                                                                                                        <ReactQuill
                                                                                                            value={
                                                                                                                values
                                                                                                                    .providingFixingDetails[
                                                                                                                    sectionIndex
                                                                                                                ]?.scopes[scopeIndex]
                                                                                                                    ?.materials[matIndex]
                                                                                                                    ?.remarks || ""
                                                                                                            }
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].remarks`}
                                                                                                            onChange={(value) => {
                                                                                                                handleFieldChange(
                                                                                                                    sectionIndex,
                                                                                                                    scopeIndex,
                                                                                                                    matIndex,
                                                                                                                    "remarks",
                                                                                                                    value,
                                                                                                                    values,
                                                                                                                    setFieldValue
                                                                                                                ); // Handle remark change for the material
                                                                                                            }}
                                                                                                            disabled={
                                                                                                                isReadOnly && true
                                                                                                            }
                                                                                                            theme="snow"
                                                                                                            className="min-h-[40px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white  w-full overflow-y-auto remove-quill-padding"
                                                                                                            modules={{ toolbar: false }}
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name={`providingFixingDetails[${sectionIndex}].scopes[${scopeIndex}].materials[${matIndex}].remarks`}
                                                                                                            component="div"
                                                                                                            className="text-red-500 text-xs"
                                                                                                        />
                                                                                                    </div>
                                                                                                    {/* <p className="col-span-3 text-right mt-3 text-xs">{values.providingFixingDetails[index]?.materials[matIndex]?.netPrice || 0} </p> */}
                                                                                                    <p className="col-span-2 text-right mt-3 text-xs">
                                                                                                        {new Intl.NumberFormat(
                                                                                                            "en-IN",
                                                                                                            { minimumFractionDigits: 0 }
                                                                                                        ).format(
                                                                                                            values
                                                                                                                .providingFixingDetails[
                                                                                                                sectionIndex
                                                                                                            ]?.scopes[scopeIndex]
                                                                                                                ?.materials[matIndex]
                                                                                                                ?.netPrice
                                                                                                        ) || 0}
                                                                                                    </p>
                                                                                                    <p className="col-span-2 text-right mt-3 text-xs">
                                                                                                        {new Intl.NumberFormat(
                                                                                                            "en-IN",
                                                                                                            { minimumFractionDigits: 0 }
                                                                                                        ).format(
                                                                                                            values
                                                                                                                .providingFixingDetails[
                                                                                                                sectionIndex
                                                                                                            ]?.scopes[scopeIndex]
                                                                                                                ?.materials[matIndex]
                                                                                                                ?.installationCost || 0
                                                                                                        )}
                                                                                                    </p>
                                                                                                    <p className="col-span-2 text-right mt-3 text-xs">
                                                                                                        {new Intl.NumberFormat(
                                                                                                            "en-IN",
                                                                                                            { minimumFractionDigits: 0 }
                                                                                                        ).format(
                                                                                                            values
                                                                                                                .providingFixingDetails[
                                                                                                                sectionIndex
                                                                                                            ]?.scopes[scopeIndex]
                                                                                                                ?.materials[matIndex]
                                                                                                                ?.totalExclGst || 0
                                                                                                        )}
                                                                                                    </p>
                                                                                                    <div className="col-span-2 relative text-center mt-2">
                                                                                                        {/* <AttachFileIcon
                                                                                            className="hover:cursor-pointer"
                                                                                            sx={{
                                                                                                width: "0.7em", ml: "2px",
                                                                                                transform: 'rotate(45deg)',
                                                                                                transition: 'transform 0.3s ease',
                                                                                            }}
                                                                                        onClick={() => {
                                                                                            setSelectedMaterialIndex(matIndex); // Set the material index for file upload
                                                                                            setOpenUploadFile(true); // Open the file upload modal
                                                                                        }}
                                                                                        /> */}

                                                                                                        {/* {values.providingFixingDetails[index]?.materials[matIndex]?.attachments?.length > 0 && (
                                                                            <span
                                                                                className="absolute top-0 right-4 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                                style={{ transform: 'translate(50%, -50%)' }}
                                                                            >
                                                                                {values.providingFixingDetails[index].materials[matIndex].attachments.length}
                                                                            </span>
                                                                        )} */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {matIndex <
                                                                                                    scope?.materials?.length -
                                                                                                    1 && (
                                                                                                        <hr className="hidden lg:block  lg:mt-4" />
                                                                                                    )}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                                <div className="">
                                                                                    <hr className="hidden lg:block" />
                                                                                    <div className="flex px-4 py-2 text-xs bg-[#f7f8fa] justify-between items-center ">
                                                                                        <p className="text-[#004491]">
                                                                                            Total Material and Installation cost
                                                                                            of scope {scopeIndex + 1} (Excl.
                                                                                            GST)
                                                                                        </p>
                                                                                        <p className="text-[#004491]">
                                                                                            Rs.{" "}
                                                                                            {new Intl.NumberFormat("en-IN", {
                                                                                                minimumFractionDigits: 2,
                                                                                            }).format(
                                                                                                values.providingFixingDetails[
                                                                                                    sectionIndex
                                                                                                ]?.scopes[scopeIndex]
                                                                                                    ?.totalExclGst || 0
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="">
                                                        <hr className="hidden lg:block" />
                                                        <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] ">
                                                            <p className="text-[#007366] lg:text-black text-xs pl-0 px-4">GST Total</p>
                                                            <p className="text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((values.providingFixingDetails[index].totalAmount - values.providingFixingDetails[index].totalExclGst))}</p>
                                                        </div>
                                                    </div> */}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    {/* other charges */}
                                    <div className="hidden lg:block bg-white p-8 shadow-md">
                                        {hasVisibleMiscellaneousItems && (
                                            <div className="pb-6">
                                                <h3 className="text-sm font-semibold text-[#434343] hidden lg:block mb-3">
                                                    Additional Cost Components
                                                </h3>

                                                <div className="text-xs bg-white border py-4 shadow-md lg:border-none lg:mx-0 lg:shadow-none lg:py-0">
                                                    <div className="mb-3 hidden lg:grid lg:grid-cols-6 items-center gap-2 text-[#777777] font-normal border-y py-2">
                                                        <label className="block font-medium col-span-1">
                                                            Cost Component
                                                        </label>
                                                        <label className="block font-medium col-span-3">
                                                            Remarks (Cost Defination)
                                                        </label>
                                                        <label className="block font-medium text-center col-span-1">
                                                            GST (%)
                                                        </label>
                                                        <label className="font-medium col-span-1 flex text-right justify-end relative">
                                                            Cost (Excl. GST)
                                                            <span className="bg-[#cac9c9] text-white text-xs h-[12px] w-[12px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                i
                                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[140px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                    enter total amount of additional cost component.
                                                                </span>
                                                            </span>
                                                        </label>
                                                        {/* <label className="block font-medium text-right col-span-1">Total Cost</label> */}
                                                    </div>

                                                    {/* Render visible miscellaneous items */}
                                                    {visibleMiscellaneousItems.map((item) => (
                                                        <div
                                                            key={item.name}
                                                            className="lg:grid lg:grid-cols-6 mb-8 lg:mb-0 space-y-3 lg:space-y-2 gap-4 items-center"
                                                        >
                                                            <label className="block lg:mb-2 col-span-1 font-semibold lg:font-medium ">
                                                                {item.label}
                                                            </label>

                                                            {/* Remark */}
                                                            <div className="col-span-3 lg:flex hidden justify-center h-fit lg:w-full w-[262px]">
                                                                <ReactQuill
                                                                    value={
                                                                        values.miscellaneousCosts[item.name]
                                                                            ?.remark || ""
                                                                    }
                                                                    name={`miscellaneousCosts.${item.name}.remark`}
                                                                    onChange={(value) =>
                                                                        handleMiscellaneousChange(
                                                                            item.name,
                                                                            value,
                                                                            setFieldValue,
                                                                            values,
                                                                            "remark"
                                                                        )
                                                                    }
                                                                    disabled={isReadOnly}
                                                                    theme="snow"
                                                                    className="min-h-[20px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white border-[#DCDCD0] border w-full overflow-y-auto remove-quill-padding"
                                                                    modules={{ toolbar: false }}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.remark`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs"
                                                                />
                                                            </div>

                                                            {/* GST Dropdown */}
                                                            <div className="col-span-1 lg:flex hidden justify-center h-fit lg:w-full w-[262px]">
                                                                <CustomGstDropdown
                                                                    options={[0, 5, 12, 18, 28]}
                                                                    value={
                                                                        values.miscellaneousCosts[item.name]?.gst
                                                                    }
                                                                    onChange={(value) =>
                                                                        handleMiscGstChange(
                                                                            item.name,
                                                                            value,
                                                                            setFieldValue,
                                                                            values
                                                                        )
                                                                    }
                                                                    className="lg:w-[64px] w-[262px] justify-between h-[30px]"
                                                                    disabled={isReadOnly}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.gst`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs mt-1"
                                                                />
                                                            </div>

                                                            {/* MRP Input Field */}
                                                            <div className="col-span-1 w-full">
                                                                <Field
                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                    type="text"
                                                                    className={`w-fit hidden text-xs text-right lg:block lg:w-full focus:ring-0 h-[30px] placeholder-[#373737] p-2 border border-gray-300 ${isReadOnly && "bg-gray-100"
                                                                        }`}
                                                                    placeholder="Rs."
                                                                    onChange={(e) =>
                                                                        handleMiscellaneousChange(
                                                                            item.name,
                                                                            e.target.value,
                                                                            setFieldValue,
                                                                            values,
                                                                            "mrp"
                                                                        )
                                                                    }
                                                                    disabled={isReadOnly}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs mt-1"
                                                                />
                                                            </div>

                                                            {/* Total Cost */}
                                                            {/* <div className="flex justify-between lg:justify-end items-center border border-t-[#004491] py-3 bg-[#E7EAEF] lg:border-none lg:bg-white">
                                                            <p className="text-[#004491] font-bold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2)) || '0.00'}
                                                            </p>
                                                        </div> */}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {/* Total Amount Section */}
                                        <div className="">
                                            <hr className="hidden lg:block" />
                                            <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                <p className="text-[#007366] lg:text-black text-sm">
                                                    Total Basic Amount
                                                </p>
                                                <p className="text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                    Rs.{" "}
                                                    {new Intl.NumberFormat("en-IN", {
                                                        minimumFractionDigits: 2,
                                                    }).format(values?.overallExclGst || 0)}
                                                </p>
                                            </div>
                                        </div>

                                        {/* GST Amount Section */}
                                        <div className="">
                                            <hr className="hidden lg:block" />
                                            <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                <p className="text-[#007366] lg:text-black text-sm">
                                                    GST Total
                                                </p>
                                                <p className="text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                    Rs.{" "}
                                                    {new Intl.NumberFormat("en-IN", {
                                                        minimumFractionDigits: 2,
                                                    }).format(
                                                        values?.overallWithGst - values?.overallExclGst || 0
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        {/* Total Quotation Value Amount Section */}
                                        <div className="">
                                            <hr className="hidden lg:block" />
                                            <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                <p className="text-[#007366] lg:text-black text-sm">
                                                    Total Quotation Value (inc. GST)
                                                </p>
                                                <p className="bg-[#E0F0EE] text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                    Rs.{" "}
                                                    {new Intl.NumberFormat("en-IN", {
                                                        minimumFractionDigits: 2,
                                                    }).format(values?.overallWithGst)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Supporting Documents Section */}
                                    {attachmentsData.length > 0 && (
                                        <div className="mb-4 hidden lg:block bg-white p-8 shadow-md">
                                            <>
                                                <h3 className="text-sm font-semibold text-[#434343] hidden lg:block">
                                                    Supporting Documents
                                                </h3>
                                                <p className="text-xs font-normal text-[#9D9D9D] ">
                                                    {" "}
                                                    <span className="font-bold text-gray-500">
                                                        Note:{" "}
                                                    </span>{" "}
                                                    Below are the supporting documents uploaded by the
                                                    buyer. Please review them.
                                                </p>
                                                <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 mx-auto">
                                                    {attachmentsData.map((doc, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex justify-between items-center mx-2"
                                                        >
                                                            <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                                {/* Dynamic icon based on file type */}
                                                                <img
                                                                    src={`/assets/icons/${doc.Path}.png`}
                                                                    alt={doc.Path}
                                                                    className="h-5 w-5"
                                                                />
                                                                {/* Document name now clickable */}
                                                                <a
                                                                    href={doc.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    download={doc.Name}
                                                                    className="text-xs text-[#434343] hover:underline"
                                                                >
                                                                    {doc.Name}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        </div>
                                    )}

                                    {/* Additional Information Section */}
                                    <div className="mb-4 bg-white p-8 shadow-md">
                                        {/* <div className="flex items-center mt-[60px] mb-[30px]">
                                    <h3 className="whitespace-nowrap text-[#434343] uppercase text-sm font-medium">ADDITIONAL INFORMATION</h3>
                                    <hr className="flex-grow border-t border-gray-300 ml-4" />
                                     </div> */}
                                        <h3 className="text-sm font-semibold text-[#434343] hidden lg:block ">
                                            Additional Information
                                        </h3>

                                        <div className="hidden space-x-8 lg:flex items-center w-full mb-2">
                                            <p className=" text-sm ">
                                                Do you agree with payment terms?
                                            </p>
                                            <div className="flex space-x-4">
                                                <label className="inline-flex items-center">
                                                    <Field
                                                        type="radio"
                                                        name="agreedToPaymentTerms"
                                                        value="true"
                                                        checked={values.agreedToPaymentTerms === true} // Controlled check status
                                                        onChange={() => {
                                                            setFieldValue("agreedToPaymentTerms", true);
                                                            setIsCounterTerm(false); // Update counter term
                                                            setFieldValue("counterPaymentTerms", "");
                                                        }}
                                                        className="form-radio"
                                                    />
                                                    <span className="ml-2 mt-1 text-sm">Yes</span>
                                                </label>

                                                <label className="inline-flex items-center">
                                                    <Field
                                                        type="radio"
                                                        name="agreedToPaymentTerms"
                                                        value="false"
                                                        checked={values.agreedToPaymentTerms === false} // Controlled chec k status
                                                        onChange={() => {
                                                            setFieldValue("agreedToPaymentTerms", false);
                                                            setIsCounterTerm(true); // Update counter term
                                                        }}
                                                        disabled={isReadOnly}
                                                        className="form-radio"
                                                    />
                                                    <span className="ml-2 mt-1 text-sm">Counter Offer</span>
                                                </label>
                                            </div>
                                            <ErrorMessage
                                                name="agreedToPaymentTerms"
                                                component="div"
                                                className="text-red-500 text-xs"
                                            />
                                        </div>
                                        {isCounterTerm && (
                                            <div className=" mx-4 lg:mx-0">
                                                <p className="text-sm text-[#4F4F42]">
                                                    Counter Payment Terms
                                                </p>
                                                <Field
                                                    as="textarea"
                                                    name="counterPaymentTerms"
                                                    className={`w-full h-[120px] focus:ring-0 text-xs placeholder:text-xs border border-gray-300 placeholder:italic 
                                                    ${values.agreedToPaymentTerms ===
                                                            true
                                                            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                                                            : "bg-white"
                                                        }`}
                                                    placeholder="Please write your counter payment terms Rich Text"
                                                    disabled={
                                                        values.agreedToPaymentTerms === true || isReadOnly
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className=" lg:flex mt-4 mx-4 lg:mx-0">
                                            <p className="text-[#434343] text-sm mb-2 lg:mb-0">Notes</p>
                                            <Field
                                                as="textarea"
                                                name="notes"
                                                placeholder="Add Notes (Optional) "
                                                className={`border-[#CDCDB8] w-full lg:ml-5 focus:ring-0 placeholder:text-xs lg:w-full text-xs h-[120px] placeholder:italic placeholder-[#8C8C8C] ${isReadOnly && "bg-gray-100"
                                                    }`}
                                                disabled={isReadOnly}
                                            />
                                        </div>
                                    </div>

                                    {/* Upload Supporting Documents */}
                                    <div className=" mx-4 lg:mx-0 bg-white p-8 shadow-md">
                                        <h3 className="whitespace-nowrap text-[#434343] text-sm font-bold mb-4 lg:mb-[15px]">
                                            Upload Supporting Documents
                                        </h3>

                                        {attachmentsSkeleton.length > 0 && (
                                            <p className="text-xs pl-3">
                                                Your file is being scanned for upload, be patient and
                                                continue to fill the rest of the form.
                                            </p>
                                        )}

                                        <div className="px-3">
                                            {/* Display the selected file names */}
                                            <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                                {selectedFilesBase.map((file, index) => (
                                                    <a
                                                        href={file.Content}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                        key={file.Name}
                                                    >
                                                        <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                            {file.Name}
                                                        </span>
                                                        <span
                                                            className="ml-1 cursor-pointer"
                                                            onClick={(event) =>
                                                                handleDeleteSelectedFile(file.Name, event)
                                                            }
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 48 48"
                                                            >
                                                                <circle cx="24" cy="24" r="22" fill="gray" />
                                                                <line
                                                                    x1="15"
                                                                    y1="15"
                                                                    x2="33"
                                                                    y2="33"
                                                                    stroke="white"
                                                                    strokeWidth="2"
                                                                />
                                                                <line
                                                                    x1="33"
                                                                    y1="15"
                                                                    x2="15"
                                                                    y2="33"
                                                                    stroke="white"
                                                                    strokeWidth="2"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </a>
                                                ))}

                                                {/* Skeleton loaders for files being processed */}
                                                {attachmentsSkeleton.map((_, index) => (
                                                    <Skeleton
                                                        key={index}
                                                        variant="rounded"
                                                        sx={{ borderRadius: "20px", margin: "4px" }}
                                                        width={150}
                                                        height={32}
                                                    />
                                                ))}
                                            </ul>
                                        </div>

                                        <div>
                                            {attachmentsUploadsErrors && (
                                                <div className="mt-3">
                                                    <p className="text-red-500 font-semibold text-sm mt-14">
                                                        {attachmentsUploadsErrors}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        {/* Error handling for file size */}
                                        <div
                                            className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                                }`}
                                        >
                                            <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                                <p>Total size of attachments is not more than 10 MB</p>
                                                <button
                                                    onClick={handleCloseFileSizeError}
                                                    className="px-4 py-2 focus:outline-none"
                                                >
                                                    <svg
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M6 18L18 6M6 6l12 12"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                        {/* drag and drop file */}
                                        {!isReadOnly && (
                                            <div className="lg:ml-14 lg:mr-0 flex items-center justify-center h-[175px] lg:h-[180px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                                <input
                                                    type="file"
                                                    accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                    onClick={handleFileUploadClick}
                                                    onChange={handleFileUpload}
                                                    multiple // Allow multiple files to be selected
                                                />
                                                <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500 pointer-events-none">
                                                    <div className="relative mx-auto hidden lg:block">
                                                        {/* Overlapping rectangles */}
                                                        <div className="absolute top-10 -left-8 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                        <div className="absolute top-2 left-0 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                    </div>
                                                    {/* Centered text */}
                                                    <p className="mt-32 text-center text-sm hidden lg:block">
                                                        Drag & Drop or Click to upload your proposal instead
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="sticky bottom-0 z-20">
                            {/* action buttons for mobile */}
                            <div
                                style={{ boxShadow: "0 -4px 6px -1px rgba(0, 0, 0, 0.1)" }}
                                className="lg:hidden flex justify-end p-4 space-x-8 bg-white "
                            >
                                {currentStep === 0 ? (
                                    <div className="space-x-4">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                isEdit
                                                    ? navigate(-1)
                                                    : supplierId
                                                        ? navigate("/leads")
                                                        : navigate(-1)
                                            }
                                            className="text-xs bg-[#E9E9E9] cursor:pointer px-4 py-2 text-[#434343]"
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="button"
                                            className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                            onClick={() => setCurrentStep(currentStep + 1)}
                                        >
                                            Start Quotation
                                        </button>
                                    </div>
                                ) : (
                                    currentStep > 0 && (
                                        <button
                                            type="button"
                                            className="underline text-xs"
                                            onClick={handlePreviousStep}
                                        >
                                            Back
                                        </button>
                                    )
                                )}

                                {/* Show "Next" button for all steps except "Additional Information & Upload" and "Completion" */}
                                {currentStep > 0 && currentStep < steps.length - 2 && (
                                    <button
                                        type="button"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        onClick={() =>
                                            handleNextStep(validateForm, setTouched, values)
                                        }
                                    >
                                        Next
                                    </button>
                                )}

                                {/* Show "Submit" button on "Additional Information & Upload" step */}
                                {currentStep === steps.length - 2 && (
                                    <button
                                        type="submit"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </button>
                                )}

                                {/* No buttons displayed on "Completion" step */}
                            </div>
                            {/* action buttons for desktop */}
                            {!isReadOnly && (
                                <div
                                    style={{ boxShadow: "0 -4px 6px -1px rgba(0, 0, 0, 0.1)" }}
                                    className="sticky bottom-0 space-x-4 bg-white hidden lg:flex justify-end items-center py-3 lg:px-8 px-4"
                                >
                                    <button
                                        type="button"
                                        onClick={handleCloseForm}
                                        className=" w-[120px] border border-[#1E1E08] px-3 py-2 text-sm text-[#434343] font-bold"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="bg-black border border-[#1E1E08] text-white px-3 py-2 text-sm flex justify-center items-center"
                                    >
                                        {loading
                                            ? "Submitting..."
                                            : isEdit
                                                ? "Update Quotation"
                                                : "Submit Quotation"}
                                    </button>
                                </div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>

            <StaticPopup
                openModal={openDescPopup}
                handleClose={closeDescPopup}
                width={"xl"}
                bodyStyle="pb-0 pt-0"
                className="px-0"
                header={popupHeader}
            >
                <div className="py-4">
                    <p>{selectedDesc}</p> {/* Display the full specifications */}
                </div>
            </StaticPopup>

            <LoadingModal
                isOpen={excelLoading || excelError !== null}
                onClose={() => setExcelError(null)} // Close the modal on click
            >
                {excelLoading ? (
                    <div className="flex flex-col items-center justify-center">
                        <div className="animate-spin rounded-full border-4 border-t-4 border-orange-500 border-t-gray-200 h-8 w-8 mb-4"></div>
                        <p className="text-xl font-medium text-gray-600 italic">
                            {loadingMessage}
                        </p>
                    </div>
                ) : (
                    <div>
                        <p className="text-red-500 font-medium">
                            {excelError || "Operation completed successfully!"}
                        </p>
                    </div>
                )}
            </LoadingModal>
        </div>
    );
};

export default ProvidingQuotationForm;
