const fields = [
 
  {
    key: "businessName",
    title: "Business Name",
    mappedField: "businessName",
    component: "Drawer",
  },
  {
    key: "businessMobileNumber",
    title: "Contact Details",
    mappedField: "businessMobileNumber",
    component: "Text",
  },
  {
    key: "businessEmail",
    title: "Business Email",
    mappedField: "businessEmail",
    component: "Text",
  },
 
  // {
  //   key: "serviceNames",
  //   title: "Services",
  //   mappedField: "serviceNames",
  //   component: "ViewMore",
  // },
  {
    key: "clientNames",
    title: "Clients",
    mappedField: "clientNames",
    component: "ViewMore",
  },
  {
    key: "gstNumber",
    title: "GST Number",
    mappedField: "gstNumber",
    component: "Text",
  },
  {
    key: "annualTurnOver",
    title: "Turnover",
    mappedField: "annualTurnOver",
    component: "Text",
  },
  {
    key: "businessWebsite",
    title: "Website",
    mappedField: "businessWebsite",
    component: "Text",
  },
  {
    key: "establishYear",
    title: "Established Year",
    mappedField: "establishYear",
    component: "Text",
  },
 
  // {
  //   key: "serviceOffered",
  //   title: "Service Offered",
  //   mappedField: "serviceOffered",
  //   component: "ServicePopup",
  // },
  // {
  //   key: "domains",
  //   title: "Sector",
  //   mappedField: "domains",
  //   component: "ViewMore",
  // },
  // {
  //   key: "projectNames",
  //   title: "Projects",
  //   mappedField: "projectNames",
  //   component: "ViewMore",
  // },
  // {
  //   key: "gstCertified",
  //   title: "GST Status",
  //   mappedField: "gstCertified",
  //   component: "Text",
  // },
  // {
  //   key: "businessType",
  //   title: "Business Type",
  //   mappedField: "businessType",
  //   component: "Text",
  // },
];

export default fields;
