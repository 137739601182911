import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormikContext, ErrorMessage } from "formik";
import ReactQuill from "react-quill";
import AddMaterialInScope from "./AddMaterialInScope";
import SearchableDropdown from "./SearchableDropdown";
import StaticPopupNew from "components/StaticPopupNew";
import * as XLSX from "xlsx";
import SectionPopup from "./SectionPopup";
import { useSnackbar } from "notistack";
import { parseBOQTemplate, validateSections } from "./bulkUploadHelper";
import { UNITOPTIONS } from "config";
import debounce from "lodash/debounce";
import StaticPopup from "components/StaticPopup";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const downloadTemplate = "https://storagereponeevaydevcdn.blob.core.windows.net/business/contractor_boq_bulk_upload_template.xlsx";

const RfqTypeProviding = () => {
  const { values, setFieldValue, errors, isSubmitting } = useFormikContext();
  const inputRefs = useRef({}); // Store multiple input refs
  const [expandedSections, setExpandedSections] = useState([]);
  // const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null); // To track the section to delete
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [editIndexes, setEditIndexes] = useState(new Set()); // Allow multiple edits
  const [updatedSectionNames, setUpdatedSectionNames] = useState({});
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [fileError, setFileError] = useState("");
  const [validationErrors, setValidationErrors] = useState({}); // Track errors per section

  // Add a new section
  const handleAddSection = () => {
    const newSectionIndex = values.providingFixingDetails.length;
    const newSection = {
      sectionName: "",
      scopes: [
        {
          scopeOfWork: "",
          quantity: "",
          unit: "",
          preferredBrands: [],
          materials: [],
        },
      ],
    };

    // Add new section without expanding
    setFieldValue("providingFixingDetails", [
      ...values.providingFixingDetails,
      newSection,
    ]);
    setEditIndexes((prev) => new Set(prev.add(newSectionIndex))); // Open input field for new section
    setUpdatedSectionNames((prev) => ({ ...prev, [newSectionIndex]: "" }));
    setTimeout(() => inputRefs.current[newSectionIndex]?.focus(), 0);
  };

  // Delete a section
  const handleDeleteSection = (index) => {
    const updatedScopes = values.providingFixingDetails.filter(
      (_, i) => i !== index
    );
    setFieldValue("providingFixingDetails", updatedScopes);
    setExpandedSections((prev) => prev.filter((i) => i !== index)); // Remove from expanded list
  };

  // Toggle section expansion
  const handleToggleSection = (index) => {
    setExpandedSections((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleBulkUploadBtn = () => {
    setOpenUploadFile(!openUploadFile);
  };

  const handleBulkUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = "BOQ Template";
        const sheet = workbook.Sheets[sheetName];

        if (!sheet) {
          enqueueSnackbar(
            `Sheet "${sheetName}" not found in the uploaded file.`,
            { variant: "error" }
          );
          setFileError("The BOQ template sheet is missing.");
          return;
        }

        // Parse the sheet into JSON
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const parsedData = parseBOQTemplate(rows);

        // Validate the parsed data
        const validationErrors = validateSections(parsedData);
        if (validationErrors.length > 0) {
          validationErrors.forEach((error) =>
            enqueueSnackbar(error, { variant: "error", autoHideDuration: 3000 })
          );
          setFileError("There were errors in the file.");
        } else {
          enqueueSnackbar("BOQ Template uploaded successfully.", {
            variant: "success",
            autoHideDuration: 3000,
          });
          const existingSections = values.providingFixingDetails || [];
          const updatedSections = [...existingSections, ...parsedData];
          // Update the field value with the concatenated data
          setFieldValue("providingFixingDetails", updatedSections);
          setOpenUploadFile(false);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleEditSectionName = (index) => {
    setEditIndexes((prev) => new Set(prev.add(index))); // Allow multiple open fields
    setUpdatedSectionNames((prev) => ({
      ...prev,
      [index]: values.providingFixingDetails[index]?.sectionName || "",
    }));
    setTimeout(() => inputRefs.current[index]?.focus(), 0);
  };

  const handleCancelEdit = (index) => {
    setEditIndexes((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      return newSet;
    });

    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[index];
      return newErrors;
    });
  };

  // Handle saving section name
  const handleSaveSectionName = (index) => {
    if (!updatedSectionNames[index]?.trim()) {
      setValidationErrors((prev) => ({
        ...prev,
        [index]: "Section Name is required",
      }));
      return;
    }
    const updatedSections = values.providingFixingDetails.map((section, i) =>
      i === index ? { ...section, sectionName: updatedSectionNames[index].trim() } : section
    );
    setFieldValue("providingFixingDetails", updatedSections);
    setEditIndexes((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      return newSet;
    });
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[index];
      return newErrors;
    });
  };
  

  // useEffect(() => {
  //   if (editIndex !== null && inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [editIndex]);

  useEffect(() => {
    const initialEditIndexes = new Set();
    const initialUpdatedSectionNames = {};

    values.providingFixingDetails.forEach((section, index) => {
      if (!section.sectionName.trim()) {
        initialEditIndexes.add(index);
        initialUpdatedSectionNames[index] = "";
      }
    });

    setEditIndexes(initialEditIndexes);
    setUpdatedSectionNames(initialUpdatedSectionNames);
  }, [values.providingFixingDetails]);

  // Ensure all empty section names show errors when submitting
  useEffect(() => {
    if (isSubmitting) {
      const newErrors = {};

      values.providingFixingDetails.forEach((section, index) => {
        if (!section.sectionName.trim()) {
          newErrors[index] = "Section Name is required";
        }
      });

      setValidationErrors(newErrors);

      // Expand sections with errors
      setExpandedSections((prev) => [
        ...new Set([...prev, ...Object.keys(newErrors).map(Number)]),
      ]);
    }
  }, [isSubmitting, values.providingFixingDetails]);

  useEffect(() => {
    if (isSubmitting) {
      const sectionsWithErrors = values.providingFixingDetails
        .map((_, index) => index)
        .filter((index) => errors.providingFixingDetails?.[index]);

      setExpandedSections((prev) => [
        ...new Set([...prev, ...sectionsWithErrors]),
      ]);
    }
  }, [isSubmitting, errors, values.providingFixingDetails]);

  return (
    <div>
      <div className="flex justify-between pb-2">
        <p className="text-sm font-medium self-end">Work & Material Details</p>
        <div className="flex space-x-2">
          <button
            type="button"
            onClick={handleBulkUploadBtn}
            className="text-xs flex items-center border border-gray-300 hover:border-black rounded-sm p-2 cursor-pointer"
          >
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_bulk_upload.svg"
              alt=""
              className="pr-2"
            />
            Upload BOQ
          </button>
          <button
            type="button"
            onClick={handleAddSection}
            className="text-xs border border-gray-300 hover:border-black flex items-center rounded-sm p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 5v14m7-7H5"
              />
            </svg>
            Add Part
          </button>
        </div>
      </div>

      <div>
        {values.providingFixingDetails.map((section, sectionIndex) => (
          <div key={sectionIndex} className="border border-gray-300 mb-2">
            <div
              className="py-1 px-4 flex justify-between bg-gray-100 cursor-pointer"
              onClick={(e) => {
                // Check if the sectionName exists and is not empty
                if (section.sectionName?.trim()) {
                  handleToggleSection(sectionIndex); // Toggle section if name is provided
                } else {
                  e.stopPropagation(); // Prevent toggle
                  enqueueSnackbar("Please provide a name for the part first.", {
                    variant: "error",
                  });
                }
              }}
            >
              <div
                className="flex items-center space-x-2 w-fit h-[28px]"
                onClick={(e) => e.stopPropagation()} // Prevent event from bubbling up to parent div
              >
                {editIndexes.has(sectionIndex) ? (
                  <div className="flex items-center space-x-2">
                    <span className="text-sm">{`Part ${sectionIndex + 1
                      } : `}</span>
                    <input
                      type="text"
                      value={updatedSectionNames[sectionIndex] || ""}
                      onChange={(e) =>
                        setUpdatedSectionNames((prev) => ({
                          ...prev,
                          [sectionIndex]: e.target.value,
                        }))
                      }
                      ref={(el) => (inputRefs.current[sectionIndex] = el)}
                      className={`h-[28px] w-[350px] text-sm border rounded-sm ${validationErrors[sectionIndex]
                          ? "border-red-500 placeholder-red-500"
                          : "border-gray-300"
                        }`}
                      onClick={(e) => e.stopPropagation()}
                      placeholder="Provide a name for this part"
                    />
                    <div className="flex space-x-2 items-center">
                      {/* Check icon to save changes */}
                      <CheckCircleOutlineRoundedIcon
                        onClick={() => handleSaveSectionName(sectionIndex)}
                        className="text-gray-500 cursor-pointer hover:text-green-600"
                        sx={{ fontSize: "20px" }}
                      />
                      {/* Cancel icon to cancel editing */}
                      {section?.sectionName && (
                        <CancelOutlinedIcon
                          onClick={() => handleCancelEdit(sectionIndex)}
                          className="text-gray-500 cursor-pointer hover:text-red-600"
                          sx={{ fontSize: "20px" }}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <p className="text-sm w-fit my-auto">
                    {" "}
                    {`Part ${sectionIndex + 1} : `} {section.sectionName}
                  </p>
                )}
                {/* Edit pencil icon placed after the section name */}
                {editIndexes.has(sectionIndex) ? null : (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent toggling on edit button click
                      handleEditSectionName(sectionIndex); // Edit icon click
                    }}
                    className="text-sm w-[30px] h-[30px]"
                  >
                    <svg
                      className="w-4 h-4 text-gray-600 outline-none"
                      focusable="false"
                      fill=""
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid=""
                      tabindex="-1"
                      title=""
                    >
                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41"></path>
                    </svg>
                  </button>
                )}
              </div>
              <div className="flex space-x-4 items-center">
                <span className="text-xs border border-gray-400 flex items-center rounded-sm px-2 py-1">
                  Add BOQ/Scope
                </span>

                {/* <img
                  src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                  alt="toggle"
                  className={`transition-transform duration-200 h-4 w-4 ${expandedSections.includes(sectionIndex) ? "rotate-180" : ""
                    }`}
                /> */}
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent toggling on delete button click
                    setDeleteIndex(sectionIndex); // Set the section to delete
                  }}
                >
                  <svg
                    className="w-5 h-5"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    tabIndex="-1"
                    title="Delete Section"
                  >
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path>
                  </svg>
                </button>
              </div>
            </div>
            {expandedSections.includes(sectionIndex) &&
              section.sectionName?.trim() && (
                <SectionPopup
                  section={values.providingFixingDetails[expandedSections[0]]}
                  sectionIndex={expandedSections[0]}
                  expandedSections={expandedSections}
                  // handleScopeChange={handleScopeChange}
                  // handleAddScopeToSection={handleAddScopeToSection}
                  // handleRemoveScopeFromSection={handleRemoveScopeFromSection}
                  handleClosePopup={(index) =>
                    setExpandedSections((prev) =>
                      prev.filter((i) => i !== index)
                    )
                  }
                  setFieldValue={setFieldValue}
                  values={values}
                  unitOptions={UNITOPTIONS}
                />
              )}
          </div>
        ))}
      </div>
      {/* Delete Confirmation Popup */}
      {deleteIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-10 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg space-y-4">
            {values.providingFixingDetails.length > 1 ? (
              <>
                <p>Are you sure you want to delete this part?</p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setDeleteIndex(null)}
                    className="px-4 py-1 bg-gray-300 rounded"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteSection(deleteIndex);
                      setDeleteIndex(null);
                    }}
                    className="px-4 py-1 bg-blue-500 text-white rounded"
                  >
                    Yes
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="text-red-500">
                  At least one part is required to create an RFQ.
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setDeleteIndex(null)}
                    className="px-4 py-1 bg-gray-300 rounded"
                  >
                    Okay
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <StaticPopup
        openModal={openUploadFile}
        handleClose={() => setOpenUploadFile(false)}
        size={"sm"}
        width={"xl"}
        bodyStyle="pb-0 pt-0"
        className="px-0"
        header={<div className="text-base">Upload Files</div>}
      >
        <div className="flex flex-col justify-center items-center px-0">
          <div className="w-[400px] h-[180px] my-4 flex flex-col justify-center items-center border-2 border-dashed border-[#004491] bg-[#F1F7FF]">
            <input
              id="file-upload-contractor"
              type="file"
              className="hidden"
              onChange={handleBulkUpload}
              accept=".xlsx, .xls"
            />
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg"
              className="w-8 h-8 mb-2"
            />
            <p className="text-sm whitespace-nowrap">
              Drag & Drop or
              <label
                htmlFor="file-upload-contractor"
                className="underline px-2 text-[#1D28FA] cursor-pointer"
              >
                Choose File
              </label>
              to upload
            </p>
          </div>

          {fileError && <div className="text-red-500 text-sm">{fileError}</div>}

          <div>
            <p className="text-sm text-center w-full font-normal text-[#777777]">
              Don't have the template?{" "}
            </p>
            <div className="flex items-center justify-center gap-2">
              <a
                href={downloadTemplate}
                download
                className="text-sm text-center text-[#1D28FA] font-normal"
              >
                Download Here
              </a>
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                  fill="blue"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          className="flex justify-end px-0"
          style={{
            boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
          }}
        >
          {/* <button className='w-[95px] h-[35px] text-sm mx-4 my-4 bg-black text-white' onClick={() => setOpenUploadFile(false)}>
                        Upload
                    </button> */}
        </div>
      </StaticPopup>
    </div>
  );
};

export default RfqTypeProviding;
