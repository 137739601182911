import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { tr } from "date-fns/locale";
import StaticPopupNew from "components/StaticPopupNew";
import StaticPopup from "components/StaticPopup";

const getFormattedUnit = (unit) => {
    switch (unit) {
        case "M² (Square Meter)":
            return "M²";
        case "Running Meter(RMT)":
            return "rmt";
        case "Sq. Meter":
            return "sq. mtr.";
        case "Numbers(Qty)":
            return "nos.";
        case "Cubic Meters(CUM)":
            return "cum";
        case "Kilograms(Kgs)":
            return "kgs";
        case "Metric Ton(M.Ton)":
            return "m. ton";
        case "Liters":
            return "ltrs";
        case "Bags":
            return "bags";
        case "Cubic Foot(CFT)":
            return "cft";
        case "PAC (Packet)":
            return "pkt";
        case "EA (Quantity)":
            return "ea";
        case "TON (Ton)":
            return "ton";
        case "M (Meter)":
            return "mtr";
        case "M³ (Cubic Meter)":
            return "cum";
        case "KG (Kilogram)":
            return "kg";
        case "PAA (Pair)":
            return "paa";
        case "FT² (Square Foot)":
            return "ft²";
        case "LUM (Lumsum)":
            return "lum";
        case "L (Litre)":
            return "ltr";
        case "FT (Square ft)":
            return "ft";
        case "DAY (Day)":
            return "day";
        case "Month (Months)":
            return "month";
        case "Year (Year)":
            return "yr";
        case "sqm (Square Metre)":
            return "sqm";
        case "Hrs (Hours)":
            return "hrs";
        case "Trip (Trip)":
            return "trip";
        case "Rft (Running Feet)":
            return "rft";
        case "RM (Running Meter)":
            return "rmt";
        case "Cum (Cubic Meter)":
            return "cum";
        case "NOS (Numbers (Count))":
            return "nos";
        case "Inch (Inch)":
            return "in";
        case "KM (kilometre)":
            return "km";
        default:
            return unit;
    }
};

const formatChargeKey = (key) => {
    // Add space before uppercase letters and convert the string to proper case
    return key
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

const FullComparison = () => {
    const location = useLocation();
    const rfqData = location.state?.rfqData;
    const { rfqType, suppliers, otherCharges } = rfqData || {};
    const [expandedSections, setExpandedSections] = useState(() => {
        const initialOpenState = {};
        rfqData?.providingFixingDetails?.forEach((_, sectionIndex) => {
            initialOpenState[sectionIndex] = true; // Set all sections to true (open)
        });
        return initialOpenState;
    });

    const [openSpecsPopup, setOpenSpecsPopup] = useState(false);
    const [selectedSpecs, setSelectedSpecs] = useState("");
    const [popupHeader, setPopupHeader] = useState("");
    const [expandedStates, setExpandedStates] = useState({}); // Track expanded states for each item

    const toggleExpanded = (index) => {
        setExpandedStates((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle specific item's state
        }));
    };
    const handleOpenPopup = () => {
        setOpenSpecsPopup(true);
    };

    const toggleSection = (sectionIdx) => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [sectionIdx]: !prevState[sectionIdx], // Toggle the section's state (expand/collapse)
        }));
    };

    // Function to open the popup with full specifications
    const openFullSpecs = (specifications, popupHeader) => {
        setSelectedSpecs(specifications); // Set the full specifications
        setOpenSpecsPopup(true); // Open the popup
        setPopupHeader(popupHeader);
    };

    // Function to close the popup
    const closeSpecsPopup = () => {
        setOpenSpecsPopup(false); // Close the popup
    };

    const sortedSuppliers = rfqData.suppliers
        .filter((supplier) => supplier?.quotationReceived)
        .sort((a, b) => {
            const rankA = a.vendorQuotations?.rank; // Only keep rank
            const rankB = b.vendorQuotations?.rank;

            // Convert ranks like "L1", "L2" to numerical values for comparison
            const numA = parseInt(rankA.substring(1)); // Extract the number part (e.g., "1" from "L1")
            const numB = parseInt(rankB.substring(1));

            return numA - numB; // Ascending order
        });

    const navigate = useNavigate();

    const getLineItems = () => {
        if (rfqType === "material") {
            return rfqData?.materials?.map((item) => ({
                name: item.name,
                spec: item.specifications,
                quantity: item.quantity,
                unit: item.unit,
                brands: item.brands,
            }));
        } else if (rfqType === "labour") {
            return rfqData?.labourDetails.map((item) => ({
                name: item.skill,
                quantity: item.quantity,
                unit: item.unit,
                remarks: item.remarks,
            }));
        } else if (rfqType === "contractor") {
            return rfqData?.providingFixingDetails || [];
        }
        return [];
    };

    const getExtraCharges = () => {
        return Object.keys(otherCharges || {}).filter(
            (charge) => otherCharges[charge]
        );
    };

    const lineItems = getLineItems();
    const extraChargesKeys = getExtraCharges();

    const getVendorQuotationData = (supplier, index) => {
        const vendorQuotation =
            supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        let vendorItem = {};
        if (rfqType === "material") {
            vendorItem = vendorQuotation?.materialDetails?.[index] || {};
        } else if (rfqType === "labour") {
            vendorItem = vendorQuotation?.labourItems?.[index] || {};
        } else if (rfqType === "contractor") {
            vendorItem = vendorQuotation?.contractorDetails || [];
        }

        return {
            ...vendorItem,
            subTotal: vendorQuotation?.totalWithoutGst,
            grandTotal: supplier.vendorQuotations?.grandTotalWithGst,
            gstTotal:
                supplier.vendorQuotations?.grandTotalWithGst -
                supplier.vendorQuotations?.grandTotalWithoutGst,
            totalBaseAmount:
                vendorQuotation?.totalWithoutGst +
                vendorQuotation?.othersTotalWithoutGst,
            otherCharges: vendorQuotation?.otherCharges || {},
        };
    };

    const getVendorQuotationDataContractor = (
        supplier,
        sectionIdx,
        scopeIdx,
        materialIdx
    ) => {
        const vendorQuotation =
            supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        if (!vendorQuotation) return {}; // Handle missing quotations

        const contractorDetails = vendorQuotation?.contractorDetails || [];
        const sectionData = contractorDetails[sectionIdx]; // Get the section by index

        // Handle Section Level
        if (scopeIdx === undefined && materialIdx === undefined) {
            return {
                sectionTotalExclGst: sectionData?.sectionTotalExclGst || 0,
            };
        }

        const scopeData = sectionData?.scopes[scopeIdx]; // Get the scope by index
        if (materialIdx === undefined) {
            return {
                basePrice: scopeData?.basePrice || "-",
                installRate: scopeData?.installationRate, // No installation rate for scopes
                materialCost: scopeData?.supplyCost, // No material cost for scopes
                installCost: scopeData?.installationCost, // No install cost for scopes
                gst: scopeData?.gst || "0",
                comments: scopeData?.comments || "-",
                totalExclGst: scopeData?.totalExclGst || "-",
            };
        }

        // Handle Material Level
        const materialData = scopeData?.materials[materialIdx]; // Get the material by index
        return {
            basePrice: materialData?.basePrice || "-",
            installRate: materialData?.installationRate || "-",
            gst: materialData?.gst || "-",
            materialCost: materialData?.netPrice || "-",
            installCost: materialData?.installationCost || "-",
            totalExclGst: materialData?.totalExclGst || "-",
            remarks: materialData?.remarks || "-",
        };
    };

    return (
        <div className=" h-screen bg-white ">
            {/* <div className="sticky top-0 shadow-md bg-white w-full z-50 py-3"> */}
                {/* <div className="grid grid-cols-5 ">
                    <div className="px-12 col-span-1">
                       
                    </div>
                    <div className="w-full col-span-3">
                        <h3 className="text-sm text-[#484848] text-center font-semibold pb-1">
                            Full Comparison
                        </h3>
                    </div>
                    <p></p>
                </div> */}
                {/* <div className="grid grid-cols-6 px-10 pt-2 w-full text-xs mx-auto">
          <p className="col-span-3">
            <span className="text-[#6b7280]">RFQ Name: </span>
            {rfqData?.name}
          </p>
          <p className="col-span-2">
            <span className="text-[#6b7280]">Project Name: </span>{" "}
            {rfqData?.projectName}
          </p>
          <p className="">
            <span className="text-[#6b7280]">Location: </span>{" "}
            {rfqData?.location}
          </p>
        </div> */}
            {/* </div> */}
            <div
                className=" overflow-x-auto  h-full"
                // style={{ height: "calc(100vh - 80px)" }}
            >
                <table className=" max-w-[1000px] min-w-[1000px]">
                    <thead className="sticky top-0 z-20 h-fit">
                        {/* Header for Vendor Names */}
                        <tr>
                            <td className="sticky left-0 top-0 pl-4 text-[10px] min-w-[500px] max-w-[500px] z-50 bg-white" >
                                <div className="flex items-center bg-white space-x-4" >
                            <button
                            onClick={() => navigate(-1)}
                            className="border border-gray-400 hover:border-gray-600 py-1 px-3 text-xs"
                             >
                            Back
                            </button>
                             <div className="my-auto bg-white">
                                <p className="col-span-3 break-words">
                                    <span className="text-[#6b7280]">RFQ Name: </span>
                                    {rfqData?.name}
                                </p>
                                <p className="col-span-2 break-words">
                                    <span className="text-[#6b7280]">Project Name: </span>{" "}
                                    {rfqData?.projectName}
                                </p>
                                <p className="break-words">
                                    <span className="text-[#6b7280]">Location: </span>{" "}
                                    {rfqData?.location}
                                </p>
                                </div>
                                </div>
                                {/* <p className='col-span-3 text-[10px]'><span className='text-[#6b7280]'>RFQ Name: </span>{rfqData?.name}</p> */}
                            </td>
                            {sortedSuppliers?.map((supplier, idx) => (
                                <th
                                    key={idx}
                                    className={`sticky top-0 bg-white max-w-[600px] min-w-[600px] py-4 px-2 m-2 text-left ${idx < sortedSuppliers.length - 1 ? "border-r border-r-[#707070]" : ""
                                        }`}
                                >
                                    <div className="flex items-center justify-center">
                                        {/* <hr className="border-t border-[#070707] flex-grow" /> */}
                                        <span className="mx-2 text-center font-bold text-xs">
                                            {supplier.businessName}
                                            <br /> (Rank: {supplier.vendorQuotations?.rank} - Rs.{" "}
                                            {new Intl.NumberFormat("en-IN").format(
                                                supplier.vendorQuotations?.grandTotalWithoutGst.toFixed(
                                                    2
                                                )
                                            )}
                                            <span className="text-[10px] font-normal">
                                                {" "}
                                                excl. GST
                                            </span>
                                            )
                                        </span>

                                        {/* <hr className="border-t border-[#070707] flex-grow" /> */}
                                    </div>
                                </th>
                            ))}
                        </tr>

                        {/* Line Item Headers */}
                        <tr>
                            <th
                                className="sticky left-0 top-0 bg-white text-[10px] border-l text-center font-semibold "
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            >
                                {rfqType === "material" && (
                                    <>
                                        <div
                                            className="grid grid-cols-14 border-r"
                                            style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                        >
                                            <p className="text-[10px] border-t border-x p-1 pl-1">
                                                Sr#
                                            </p>
                                            <p className="col-span-4 text-[10px] border-t border-r p-1">
                                                Material
                                            </p>
                                            <p className="text-[10px] col-span-3 border-t border-r p-1">
                                                Brand
                                            </p>
                                            <p className="text-[10px] col-span-4 border-t border-r p-1">
                                                Specifications
                                            </p>
                                            <p className="text-[10px] col-span-2 border-t  p-1">
                                                Qty
                                            </p>
                                        </div>
                                    </>
                                )}

                                {rfqType === "labour" && (
                                    <>
                                        <div
                                            className="grid grid-cols-7"
                                            style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                        >
                                            <p className="text-[10px] border-t border-x border-gray-300 p-1 pl-4">
                                                Sr#
                                            </p>
                                            <p className="col-span-5 border-t border-r border-gray-300 text-[10px] p-1">
                                                Work Description
                                            </p>
                                            <p className="col-span-1 border-t border-r border-gray-300 text-[10px] px-2 py-1">
                                                Qty
                                            </p>
                                        </div>
                                    </>
                                )}

                                {rfqType === "contractor" && (
                                    <div
                                        className="grid grid-cols-7 h-full"
                                        style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                    >
                                        <p className="col-span-4 text-center h-full pt-[7px] px-1 border-t border-x border-gray-300 text-[10px] pb-6">
                                            Description
                                        </p>
                                        <p className="col-span-1 text-center h-full py-2 px-1 border-t border-r border-gray-300 text-[10px]">
                                            Qty
                                        </p>
                                        <p className="col-span-2 text-center h-full py-2 px-1 border-t border-r border-gray-300 text-[10px]">
                                            Brands
                                        </p>
                                    </div>
                                )}
                            </th>
                            {sortedSuppliers?.map((_, idx) => (
                                <td key={idx} className="bg-white">
                                    <div className="grid grid-cols-20 min-w-[600px]  border-b text-center max-w-[600px] text-[10px] font-semibold">
                                        {rfqType === "material" && (
                                            <>
                                                <p className="col-span-8 p-1 border overflow-x-auto border-t border-l-0 border-b-0 border-r border-gray-300">
                                                    Remarks
                                                </p>
                                                <p className="col-span-3 p-1 border overflow-x-auto border-t border-l-0 border-b-0 border-r border-gray-300">
                                                    MRP
                                                </p>
                                                <p className="col-span-1 p-1 border border-gray-300 border-t  border-l-0 border-b-0 border-r">
                                                    Disc
                                                </p>
                                                <p className="col-span-3 p-1 border border-gray-300 border-t border-l-0 border-b-0 border-r">
                                                    Rate
                                                </p>
                                                {/* <p className='col-span-3 border border-gray-300'>Delivery Date</p> */}
                                                <p className="col-span-4 p-1 border border-gray-300 border-t border-l-0 border-b-0 border-r">
                                                    Basic Amt
                                                </p>
                                                <p
                                                    className={`col-span-1 p-1 border border-gray-300 border-t border-l-0 border-b-0 border-r ${idx < sortedSuppliers.length - 1
                                                        ? "border-r border-r-[#707070]"
                                                        : ""
                                                        }`}
                                                >
                                                    GST
                                                </p>
                                            </>
                                        )}
                                        {rfqType === "labour" && (
                                            <>
                                                <p className="col-span-12 border p-1 border-gray-300 border-b-0 border-t border-l-0">
                                                    Remarks
                                                </p>
                                                <p className="col-span-3 border p-1  border-gray-300 border-b-0 border-t border-l-0">
                                                    Amount
                                                </p>
                                                {/* <p className="col-span-3 border p-1 border-gray-300 border-b-0 border-t border-l-0">
                          Qty
                        </p> */}

                                                <p className="col-span-2 border p-1 border-gray-300 border-b-0 border-t border-l-0 ">
                                                    GST (%)
                                                </p>
                                                <p
                                                    className={`col-span-3 border p-1 border-gray-300 border-b-0 border-t border-l-0 ${idx < sortedSuppliers.length - 1
                                                        ? "border-r border-r-[#707070]"
                                                        : ""
                                                        }`}
                                                >
                                                    Total
                                                </p>

                                                {/* <p className='col-span-3 border border-white'>Start Date</p> */}
                                            </>
                                        )}
                                        {rfqType === "contractor" && (
                                            <>
                                                <p
                                                    className="col-span-8 px-1 py-2 border-gray-300 border-t border-r "
                                                >
                                                    Remarks
                                                </p>
                                                <p className="col-span-2 px-1 py-2 border-gray-300 border-t border-r">
                                                    Material
                                                    <br /> Rate
                                                </p>
                                                <p className="col-span-2 px-1 py-2 border-gray-300 border-t border-r">
                                                    Installation<br/> Rate
                                                </p>
                                                <p className="col-span-1 px-1 py-2 border-gray-300 border-t border-r">
                                                    GST
                                                </p>
                                                <p className="col-span-2 px-1 py-2 border-gray-300 border-t border-r">
                                                    Material
                                                    <br /> Cost
                                                </p>
                                                <p className="col-span-2 px-1 py-2 border-gray-300 border-t border-r">
                                                    Installation<br/> Cost
                                                </p>
                                                <p className={`col-span-3 py-2 border-gray-300 border-t border-r ${idx < sortedSuppliers.length - 1
                                                    ? "border-r border-r-[#707070]"
                                                    : ""
                                                    }`}>
                                                    Total
                                                    <br />
                                                    (excl.GST)
                                                </p>

                                            </>
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </thead>

                    <tbody className="bg-white overflow-y-auto z-0">
                        {/* Line Items Mapping */}
                        {rfqType === "material" &&
                            lineItems.map((item, index) => (
                                <tr key={index} className="bg-white">
                                    <td className="sticky left-0 bg-white text-[10px] align-top border">
                                        <div
                                            className="grid grid-cols-14 bg-white h-full "
                                            style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                        >
                                            <p className="px-1 py-1 col-span-1 border-r h-full pl-4">
                                                {index + 1}
                                            </p>
                                            <p className="px-1 py-1 col-span-4 border-r h-full ">
                                                {ReactHtmlParser(item.name)}
                                            </p>
                                            <p className="px-1 py-1 col-span-3 border-r break-words h-full ">
                                                {ReactHtmlParser(item.brands || "-")}
                                            </p>
                                            {item.spec.length > 0 ? (
                                                <div className="p-1 col-span-4 border-r">
                                                    <p className="whitespace-pre-line">
                                                        {ReactHtmlParser(
                                                            item.spec
                                                                ? expandedStates[index]
                                                                    ? item.spec // Show full content if expanded
                                                                    : item.spec.length > 80
                                                                        ? item.spec.substring(0, 80) + "..." // Show truncated content if not expanded
                                                                        : item.spec // Show full content if short
                                                                : "--" // Show a dash if no specifications
                                                        )}
                                                    </p>

                                                    {item.spec?.length > 80 && (
                                                        <button
                                                            type="button"
                                                            className="text-xs text-blue-500 underline italic"
                                                            onClick={() => toggleExpanded(index)} // Toggle specific item's state
                                                        >
                                                            <p className="text-[10px]">
                                                                {expandedStates[index]
                                                                    ? "Read Less"
                                                                    : "Read More"}
                                                            </p>
                                                        </button>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="h-full col-span-4 border-r flex items-center justify-center">
                                                    -
                                                </div>
                                            )}
                                            <p className="px-1 py-1 col-span-2 h-full break-words">
                                                {item.quantity} {getFormattedUnit(item.unit)}
                                            </p>
                                        </div>
                                    </td>
                                    {sortedSuppliers?.map((supplier, idx) => {
                                        const vendorData = getVendorQuotationData(supplier, index);
                                        return (
                                            <td
                                                key={idx}
                                                className={`border text-right align-top ${idx < sortedSuppliers.length - 1
                                                    ? "border-r border-r-[#707070]"
                                                    : ""
                                                    }`}
                                            >
                                                <div className="grid grid-cols-20 text-[10px]">
                                                    {/* <p className="col-span-5 border-r"> */}
                                                        {/* {vendorData.remark ? (
                                                            <>
                                                                <p className="text-left">
                                                                    {" "}
                                                                    {ReactHtmlParser(
                                                                        vendorData?.remark?.length > 50
                                                                            ? vendorData?.remark?.slice(0, 50) +
                                                                            "..."
                                                                            : vendorData?.remark || "-"
                                                                    )}
                                                                </p>
                                                                {vendorData.remark.length > 50 && (
                                                                    <button
                                                                        className="underline text-blue-500 self-start pl-2 flex items-start justify-start italic"
                                                                        onClick={() =>
                                                                            openFullSpecs(
                                                                                ReactHtmlParser(vendorData?.remark),
                                                                                "Remark"
                                                                            )
                                                                        }
                                                                    >
                                                                        Read More
                                                                    </button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )} */}
                                                         {vendorData?.remark?.length > 0 ? (
                                                <div className="h-full col-span-8 text-start border-r">
                                                    <p className="break-words pl-2">
                                                        {ReactHtmlParser(
                                                            vendorData?.remark
                                                                ? expandedStates[index]
                                                                    ? vendorData?.remark // Show full content if expanded
                                                                    : vendorData?.remark?.length > 130
                                                                        ? vendorData?.remark.substring(0, 130) + "..." // Show truncated content if not expanded
                                                                        : vendorData?.remark // Show full content if short
                                                                : "--" // Show a dash if no specifications
                                                        )}
                                                    </p>

                                                    {vendorData?.remark?.length > 130 && (
                                                        <button
                                                            type="button"
                                                            className="text-xs pl-2 text-blue-500 underline italic"
                                                            onClick={() => toggleExpanded(index)} // Toggle specific item's state
                                                        >
                                                            <p className="text-[10px]">
                                                                {expandedStates[index]
                                                                    ? "Read Less"
                                                                    : "Read More"}
                                                            </p>
                                                        </button>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="h-full col-span-8 border-r flex items-center justify-center">
                                                    -
                                                </div>
                                            )}
                                                    {/* </p> */}
                                                    <p className="col-span-3 border-r px-1">
                                                        {new Intl.NumberFormat("en-IN", {
                                                            minimumFractionDigits: 2,
                                                        }).format(vendorData.basePrice || 0)}
                                                    </p>
                                                    <p className="col-span-1 border-r px-1">
                                                        {vendorData.discount || 0}%
                                                    </p>
                                                    <p className="col-span-3 border-r px-1">
                                                        {vendorData?.basePrice &&
                                                            vendorData?.discount !== undefined
                                                            ? new Intl.NumberFormat("en-IN").format(
                                                                vendorData.basePrice -
                                                                (vendorData.basePrice *
                                                                    vendorData.discount) /
                                                                100
                                                            )
                                                            : 0}
                                                    </p>
                                                    <p className="col-span-4 border-r px-1">
                                                        {vendorData?.priceWithoutGst > 0
                                                            ? new Intl.NumberFormat("en-IN").format(
                                                                vendorData?.priceWithoutGst?.toFixed(2)
                                                            )
                                                            : 0}
                                                    </p>
                                                    <p className="col-span-1 px-1">
                                                        {vendorData.gst || 0}%
                                                    </p>
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}

                        {rfqType === "labour" &&
                            lineItems.map((item, index) => (
                                <tr key={index} className="bg-white sticky left-0">
                                        <td className="sticky left-0 align-top text-[10px] border border-r-0 bg-white h-full w-full">
                                    <div
                                        className="sticky left-0 grid grid-cols-7 bg-white w-full h-full"
                                        style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                    >
                                            {/* <div className=" bg-white  w-full"> */}
                                            <p className="pl-4 py-1 text-center border-r border-r-gray-300">
                                                {index + 1}
                                            </p>
                                            <p className="col-span-5 py-1 border-r border-r-gray-300 pl-1 break-words">
                                                {ReactHtmlParser(item.name)}
                                            </p>
                                            <p className="col-span-1 py-1 pl-2 break-words">
                                                {item.quantity} {getFormattedUnit(item.unit)}
                                            </p>
                                            {/*</div> */}
                                    </div>
                                        </td>
                                    {sortedSuppliers?.map((supplier, idx) => {
                                        const data = getVendorQuotationData(supplier, index);
                                        const hasData = data && Object.keys(data).length > 0;
                                        const remarksContent = data?.remarks || "";
                                        const truncatedRemarks =
                                            remarksContent.length > 200 && !expandedStates[index]
                                                ? `${remarksContent.substring(0, 200)}...`
                                                : remarksContent;

                                        return (
                                            <td
                                                key={idx}
                                                className={` border text-right  align-top ${idx < sortedSuppliers.length - 1
                                                    ? "border-r border-r-[#707070]"
                                                    : ""
                                                    }`}
                                            >
                                                <div className="grid grid-cols-20 text-[10px]">
                                                    {hasData && (
                                                        <div className="h-full col-span-12 border-r">
                                                            {remarksContent.length > 0 && (
                                                                <p className="pl-2 text-left word-break">
                                                                    {ReactHtmlParser(expandedStates[index] ? remarksContent : truncatedRemarks)}
                                                                </p>
                                                            )}

                                                            {remarksContent.length > 200 && (
                                                                <button
                                                                    type="button"
                                                                    className="text-xs text-blue-500 underline flex items-start justify-start text-left italic px-1 mt-1"
                                                                    onClick={() => toggleExpanded(index)} // Toggle specific item's state
                                                                >
                                                                    <p className="text-[10px]">
                                                                        {expandedStates[index] ? "Read Less" : "Read More"}
                                                                    </p>
                                                                </button>
                                                            )}
                                                        </div>
                                                    )}

                                                    <p className="col-span-3 p-1 border-r border-r-gray-300 border-gray-300">
                                                        {new Intl.NumberFormat("en-IN").format(
                                                            data.labourRate ? data.labourRate : 0
                                                        )}
                                                    </p>

                                                    <p className="col-span-2 p-1 border-r">
                                                        {data.gst || "0"}%
                                                    </p>

                                                    {/* <p className="col-span-3 p-1 border-r border-r-gray-300 border-gray-300">
                                                            {data.quantity || "-"}
                                                        </p> */}
                                                    <p className="col-span-3 p-1 border-r-gray-300 border-gray-300">
                                                        {new Intl.NumberFormat("en-IN").format(
                                                            (data?.priceWithoutGst ? parseFloat(data.priceWithoutGst).toFixed(2) : 0) || 0
                                                        )}
                                                    </p>

                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}

                        {rfqType === "contractor" &&
                            lineItems.map((section, sectionIdx) => (
                                <React.Fragment key={sectionIdx}>
                                    {/* Section Row */}
                                    <tr
                                        onClick={() => toggleSection(sectionIdx)}
                                        className="cursor-pointer border-r border-y border-gray-300 bg-gray-200"
                                    >
                                        <td
                                            className=" sticky left-0 flex justify-between items-center bg-gray-200 px-4 py-1 text-[10px] border-x border-gray-300 w-full"
                                            style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                        >
                                            <strong>{section.sectionName}</strong>
                                            <img
                                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                                alt="toggle"
                                                className={`w-2 h-2 transition-transform duration-200 ${expandedSections[sectionIdx]
                                                    ? "rotate-180"
                                                    : "rotate-0"
                                                    }`}
                                            />
                                        </td>
                                        {sortedSuppliers?.map((supplier, idx) => {
                                            const data = getVendorQuotationDataContractor(
                                                supplier,
                                                sectionIdx
                                            );
                                            return (
                                                <td
                                                    key={idx}
                                                    className={` bg-gray-200 text-[10px] text-right ${idx < sortedSuppliers.length - 1
                                                        ? "border-r border-r-[#707070]"
                                                        : ""
                                                        }`}
                                                >
                                                    <div className=" h-full">
                                                        {/* <p className="border-r col-span-4 border-gray-300"></p>
                                                        <p className="border-r col-span-1 border-gray-300"></p>
                                                        <p className="border-r col-span-2 border-gray-300"></p>
                                                        <p className="border-r col-span-1 border-gray-300"></p>
                                                        <p className="border-r col-span-2 border-gray-300"></p>
                                                        <p className="border-r col-span-2 border-gray-300"></p> */}
                                                        <p className="text-right font-semibold py-1 pr-1">
                                                            Total (excl. GST) : Rs.
                                                            {new Intl.NumberFormat("en-IN", {
                                                                minimumFractionDigits: 2,
                                                            }).format(data.sectionTotalExclGst ? data.sectionTotalExclGst : 0)}
                                                        </p>

                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>

                                    {/* Expanded Content */}
                                    {expandedSections[sectionIdx] &&
                                        section.scopes.map((scope, scopeIdx) => (
                                            <React.Fragment key={scopeIdx}>
                                                {/* Scope Rows */}
                                                <tr className="h-full bg-white">
                                                    {/* Left Sticky Column */}
                                                    <td className="h-full sticky left-0 bg-white border border-gray-300 text-[10px] w-full align-top"
                                                        style={{
                                                            boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)", // Shadow on the right side
                                                            zIndex: 1, // Keep it above other cells when scrolling
                                                        }}
                                                    >
                                                        <div className="grid grid-cols-7 w-full h-full"
                                                        style={{
                                                            boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)",
                                                        }}>
                                                            {/* Scope of Work */}
                                                            <div className="col-span-4 space-x-1 pl-4 p-1 border-r border-gray-300 h-full">
                                                                {scope?.scopeOfWork?.length > 0 && (
                                                                  <div className="">
                                                                       <p className="flex space-x-2">
                                                                            <span>{scopeIdx + 1}.</span>
                                                                            <span>
                                                                                {ReactHtmlParser(
                                                                                    expandedStates[sectionIdx]
                                                                                        ? scope?.scopeOfWork
                                                                                        : scope?.scopeOfWork?.substring(0, 200) +
                                                                                        (scope?.scopeOfWork?.length > 200 ? "..." : "")
                                                                                )}
                                                                            </span>
                                                                        </p>

                                                                                {scope?.scopeOfWork?.length > 200 && (
                                                                                <button
                                                                                    type="button"
                                                                                    className="text-blue-500 text-[10px] underline italic pl-4"
                                                                                    onClick={() => toggleExpanded(sectionIdx)}
                                                                                    >
                                                                                        {expandedStates[sectionIdx] ? "Read Less" : "Read More"}
                                                                                    </button>
                                                                                    )}
                                                                  </div>
                                                                 )}
                                                            </div>

                                                            {/* Quantity */}
                                                            <div className="border-r p-1 col-span-1 border-gray-300 h-full break-words">
                                                                <p>
                                                                    {scope.quantity} {getFormattedUnit(scope.unit)}
                                                                </p>
                                                            </div>

                                                            {/* Preferred Brands */}
                                                            <div className="col-span-2 p-1 border-gray-300 break-words h-full">
                                                                <p>{ReactHtmlParser(scope.preferredBrands.join(", ") || "-")}</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {/* Supplier Columns */}
                                                    {sortedSuppliers?.map((supplier, idx) => {
                                                        const data = getVendorQuotationDataContractor(supplier, sectionIdx, scopeIdx);

                                                        return (
                                                            <td className={`h-full text-[10px] border text-right align-top ${idx < sortedSuppliers.length - 1 ? "border-r border-r-[#707070]" : "" }`}
                                                            key={idx}
                                                            >

                                                                {/* Check for materials */}
                                                                {scope.materials.length === 0 && (
                                                                    <div className="grid grid-cols-20 h-full">
                                                                        {/* Comments Section */}
                                                                        {/* <div className="col-span-3 p-1 border-r border-gray-300 break-words text-left h-full">
                                                                            {data.comments ? (
                                                                                <>
                                                                                    <p>
                                                                                        {ReactHtmlParser(
                                                                                            data.comments.length > 120
                                                                                                ? data.comments.slice(0, 120) + "..."
                                                                                                : data.comments || "N/A"
                                                                                        )}
                                                                                    </p>
                                                                                    {data.comments.length > 120 && (
                                                                                        <button
                                                                                            className="text-blue-500 underline italic"
                                                                                            onClick={() =>
                                                                                                openFullSpecs(ReactHtmlParser(data.comments), "Remarks")
                                                                                            }
                                                                                        >
                                                                                            Read More
                                                                                        </button>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                "N/A"
                                                                            )}
                                                                        </div> */}

                                                                        {data.comments?.length > 0 && (
                                                                            <div className="col-span-8 pl-2 p-1 border-r border-gray-300 break-words text-left h-full">
                                                                                    <p className=" whitespace-pre-line">
                                                                                    {ReactHtmlParser(
                                                                                        expandedStates[idx]
                                                                                            ? data.comments
                                                                                            : data.comments?.substring(0, 195) +
                                                                                            (data.comments?.length > 195
                                                                                            ? "..."
                                                                                            : "")
                                                                                            )}
                                                                                            </p>
                                                                                            {data.comments?.length > 195 && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="text-blue-500 text-[10px] underline italic"
                                                                                                onClick={() => toggleExpanded(idx)}
                                                                                                >
                                                                                                    {expandedStates[idx] ? "Read Less" : "Read More"}
                                                                                                </button>
                                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {/* Pricing and GST */}
                                                                        <p className="col-span-2 p-1 border-r border-gray-300 text-right">
                                                                            {new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
                                                                                (isNaN(data.basePrice) || data.basePrice <= 0 ? (isNaN(data.totalExclGst) ? 0 : data.totalExclGst) : data.basePrice) || 0
                                                                            )}
                                                                        </p>

                                                                        <p className="col-span-2 p-1 border-r border-gray-300 text-right">
                                                                            {new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
                                                                                data.installRate || 0
                                                                            )}
                                                                        </p>


                                                                        <p className="col-span-1 p-1 border-r border-gray-300 text-right">
                                                                            {data.gst}%
                                                                        </p>

                                                                        <p className="col-span-2 p-1 border-r border-gray-300 text-right">
                                                                            {new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
                                                                                data.materialCost || 0
                                                                            )}
                                                                        </p>


                                                                        <p className="col-span-2 p-1 border-r border-gray-300 text-right">
                                                                            {new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
                                                                                data.installCost || 0
                                                                            )}
                                                                        </p>

                                                                        <p className="col-span-3 p-1 text-right">
                                                                            {new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
                                                                                isNaN(data.totalExclGst) || data.totalExclGst === null || data.totalExclGst === undefined
                                                                                    ? 0
                                                                                    : data.totalExclGst
                                                                            )}
                                                                        </p>


                                                                    </div>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>

                                                {scope.materials.length > 0 && (
                                                    <tr>
                                                        <td className="sticky left-0 bg-gray-100 border-b">
                                                            <div
                                                                className="border-r border-r-gray-300"
                                                                style={{
                                                                    boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >
                                                                <p className="text-[10px] px-4 py-1">
                                                                    Material List for Scope {scopeIdx + 1}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        {sortedSuppliers?.map((supplier, idx) => (
                                                            <td
                                                                key={idx}
                                                                className={`text-[10px] px-4 bg-gray-100 h-full border-r ${idx < sortedSuppliers.length - 1
                                                                    ? "border-r border-r-[#707070]"
                                                                    : ""
                                                                    }`}
                                                            ></td>
                                                        ))}
                                                    </tr>
                                                )}
                                                {/* Material Rows */}
                                                {scope.materials.map((material, materialIdx) => (
                                                   <tr key={materialIdx} className="align-top">
                                                   {/* Left Sticky Column */}
                                                   <td
                                                       className="sticky left-0 h-full text-[10px] border-b p-0 align-top bg-white"
                                                       style={{
                                                           boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)",
                                                       }}
                                                   >
                                                    <div className="grid grid-cols-7 h-full bg-white"
                                                    style={{
                                                        boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)",
                                                    }}>
                                                       <div className="col-span-4 border-r pl-4 px-1 pt-1 flex space-x-2">
                                                           <p>{scopeIdx + 1}.{materialIdx + 1}</p>
                                                           <p className="break-words">{ReactHtmlParser(material.name)}</p>
                                                       </div>
                                                       <p className="border-r col-span-1 px-1 py-1 break-words">
                                                           {material.quantity} {getFormattedUnit(material.unit)}
                                                       </p>
                                                       <p className="col-span-2 px-1 break-words">
                                                           {material.brands.join(", ")}
                                                       </p>
                                                       </div>
                                                   </td>
                                               
                                                   {/* Supplier Columns */}
                                                   {sortedSuppliers?.map((supplier, idx) => {
                                                       const data = getVendorQuotationDataContractor(
                                                           supplier,
                                                           sectionIdx,
                                                           scopeIdx,
                                                           materialIdx
                                                       );
                                               
                                                       return (
                                                           <td key={idx} className="text-[10px] border-y p-0 align-top">
                                                               <div className="grid grid-cols-20">
                                                                   <div className="text-left border-r col-span-8 border-l pl-2 py-1">
                                                                       {data?.remarks?.length > 0 && (
                                                                           <div>
                                                                               <p className="  whitespace-pre-line">
                                                                                   {ReactHtmlParser(
                                                                                       expandedStates[idx]
                                                                                           ? data?.remarks
                                                                                           : data?.remarks?.substring(0, 195) + 
                                                                                             (data?.remarks?.length > 195 ? "..." : "")
                                                                                   )}
                                                                               </p>
                                                                               {data?.remarks?.length > 195 && (
                                                                                   <button
                                                                                       type="button"
                                                                                       className="text-blue-500 text-[10px] underline italic"
                                                                                       onClick={() => toggleExpanded(idx)}
                                                                                   >
                                                                                       {expandedStates[idx] ? "Read Less" : "Read More"}
                                                                                   </button>
                                                                               )}
                                                                           </div>
                                                                       )}
                                                                   </div>
                                               
                                                                   <p className="col-span-2 text-right border-r px-1 py-1 break-words">
                                                                       {new Intl.NumberFormat("en-IN", {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }).format(data.basePrice || 0)}
                                                                   </p>
                                               
                                                                   <p className="col-span-2 text-right border-r px-1 py-1 break-words">
                                                                       {new Intl.NumberFormat("en-IN", {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }).format(data.installRate || 0)}
                                                                   </p>
                                               
                                                                   <p className="col-span-1 text-right border-r px-1 py-1">
                                                                       {data.gst || 0}%
                                                                   </p>
                                               
                                                                   <p className="col-span-2 text-right border-r px-1 py-1 break-words">
                                                                       {new Intl.NumberFormat("en-IN", {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }).format(data.materialCost || 0)}
                                                                   </p>
                                               
                                                                   <p className="col-span-2 text-right border-r px-1 py-1 break-words">
                                                                       {new Intl.NumberFormat("en-IN", {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }).format(data.installCost || 0)}
                                                                   </p>
                                               
                                                                   <p className={`col-span-3 text-right px-1 py-1 break-words ${idx < sortedSuppliers.length - 1 ? "border-r border-r-[#707070]" : ""}`}>
                                                                       {new Intl.NumberFormat("en-IN", {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }).format(data.totalExclGst || 0)}
                                                                   </p>
                                                               </div>
                                                           </td>
                                                       );
                                                   })}
                                               </tr>
                                               
                                                
                                                ))}
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            ))}

                        {/* Sub Total Row */}
                        {/* <tr>
                            <td className="sticky left-0 bg-white shadow-md pt-3 pl-4 min-w-[200px] text-[10px] font-semibold">Sub Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full mt-3 ${idx < sortedSuppliers.length - 1 ? 'border-r border-[#707070]' : ''}`}>
                                        <p className="font-bold text-xs text-right mt-1 text-[#004491]">
                                            <span className='bg-[#E7EAEF] text-[10px] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.subTotal)}</span> 
                                        </p>
                                    </td>
                                );
                            })}
                        </tr> */}

                        {/* Extra Charges Section */}
                        {extraChargesKeys && extraChargesKeys.length > 0 && (
                            <>
                                <tr className="bg-white">
                                    <div
                                        className="bg-white sticky left-0"
                                        style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                    >
                                        {/* {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`p-2 border-t bg-white ${idx < sortedSuppliers.length - 1 ? 'border-x' : ''}`}></td>
                                          ))} */}
                                        <td className="sticky left-0 bg-white p-2"></td>
                                    </div>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td
                                            key={idx}
                                            className={`px-2 border-l ${idx < sortedSuppliers.length - 1
                                                ? "border-r border-r-[#707070]"
                                                : ""
                                                }`}
                                        >
                                            <div className="grid grid-cols-3 uppercase pl-2 text-[10px] ml-auto text-right w-1/2 text-gray-500"></div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <div
                                        className="bg-white sticky left-0 border-y"
                                        style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                    >
                                        <td className="bg-white sticky left-0 px-4 py-1 text-[10px] font-semibold">
                                            {" "}
                                            Other Charges
                                        </td>
                                    </div>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td
                                            key={idx}
                                            className={`border-l ${idx < sortedSuppliers.length - 1
                                                ? "border-r border-r-[#707070]"
                                                : "border-r"
                                                }`}
                                        >
                                            <div className="grid grid-cols-14 text-[10px] text-gray-500 uppercase text-center">
                                                <div className="col-span-11 border-y border-r py-1">
                                                    Remarks
                                                </div>
                                                <div className="col-span-3 border-y py-1">
                                                    Cost (excl. GST)
                                                </div>
                                            </div>
                                        </td>
                                    ))}
                                </tr>

                                {extraChargesKeys.map((chargeKey, index) => (
                                    <tr key={index}>
                                        {/* <div
                                            className="bg-white sticky left-0  border-b py-1 min-w-[200px]"
                                            style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                        > */}
                                            <td
                                                className="sticky left-0 border text-[10px] border-gray-300 bg-white min-w-[200px] px-4"
                                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                            >
                                                {/* <div className="flex items-center text-[10px] text-center"> */}
                                                    <p className="" >{formatChargeKey(chargeKey)}</p>
                                                {/* </div> */}
                                            </td>
                                        {/* </div> */}
                                        {sortedSuppliers?.map((supplier, vendorIdx) => {
                                            const vendorData = getVendorQuotationData(supplier, 0);
                                            return (
                                                <td
                                                    key={vendorIdx}
                                                    className={`border-b border-x border-gray-300 ${vendorIdx < sortedSuppliers.length - 1
                                                        ? "border-r border-r-[#707070]"
                                                        : ""
                                                        }`}
                                                >
                                                    <div className="text-[10px]  text-right grid grid-cols-14 ">
                                                        <td className="p-1 pl-2 col-span-11 border-r text-left">
                                                            {vendorData?.otherCharges[chargeKey]?.remark?.length > 0 ? (
                                                            <div className="h-full p-1 col-span-4">
                                                                <p className="whitespace-pre-line">
                                                                    {ReactHtmlParser(
                                                                        vendorData?.otherCharges[chargeKey]?.remark
                                                                            ? expandedStates[vendorIdx]
                                                                                ? vendorData?.otherCharges[chargeKey]?.remark // Show full content if expanded
                                                                                : vendorData?.otherCharges[chargeKey]?.remark?.length > 280
                                                                                    ? vendorData?.otherCharges[chargeKey]?.remark?.substring(0, 280) + "..." // Show truncated content if not expanded
                                                                                    : vendorData?.otherCharges[chargeKey]?.remark // Show full content if short
                                                                            : "--" // Show a dash if no specifications
                                                                    )}
                                                                </p>

                                                                {vendorData?.otherCharges[chargeKey]?.remark?.length > 280 && (
                                                                    <button
                                                                        type="button"
                                                                        className="text-xs text-blue-500 underline italic"
                                                                        onClick={() => toggleExpanded(vendorIdx)} // Toggle specific item's state
                                                                    >
                                                                        <p className="text-[10px]">
                                                                            {expandedStates[vendorIdx]
                                                                                ? "Read Less"
                                                                                : "Read More"}
                                                                        </p>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="h-full col-span-4 flex items-center justify-center">
                                                                -
                                                            </div>
                                                        )}
                                                        </td>
                                                        <td className="p-1 col-span-3">
                                                            {new Intl.NumberFormat("en-IN", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }).format(
                                                                vendorData.otherCharges[chargeKey]?.mrp || 0
                                                            )}
                                                        </td>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </>
                        )}

                        {/* Grand Total Row */}
                        {/* <tr>
                            <td className="sticky left-0 text-xs px-4 bg-white shadow-md min-w-[200px] font-semibold">Grand Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}}>
                                        <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                            <p className="font-bold text-xs text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E0F0EE] px-6 py-2'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.grandTotal)}</span>
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr> */}

                        {/* Sub Total Row */}
                        <tr>
                            <div
                                className="bg-white sticky left-0 border-b border-r border-r-gray-300"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            >
                                <td className="sticky left-0 bg-white shadow-md pt-3 pl-4 min-w-[200px] text-[10px] font-semibold">
                                    Total Basic Amount
                                </td>
                            </div>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td
                                        key={idx}
                                        className={`w-full border-b mt-3  ${idx < sortedSuppliers.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className="font-bold text-xs text-right mt-1 text-[#004491]">
                                            <span className="bg-[#E7EAEF] text-[10px] px-2 py-1">
                                                Rs.{" "}
                                                {new Intl.NumberFormat("en-IN").format(
                                                    vendorData.totalBaseAmount
                                                )}
                                            </span>{" "}
                                            {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>
                                );
                            })}
                        </tr>

                        {/* GST Total Row */}
                        <tr>
                            <div
                                className="bg-white sticky left-0  border-b py-2 min-w-[200px] border-r border-r-gray-300"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            >
                                <td className="text-[10px] px-4">GST Total</td>
                            </div>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td
                                        key={idx}
                                        className={`w-full border-b mx-2 ${idx < sortedSuppliers.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className="text-[10px] text-right">
                                            <span className=" p-2">
                                                Rs.{" "}
                                                {new Intl.NumberFormat("en-IN").format(
                                                    vendorData.gstTotal
                                                )}
                                            </span>{" "}
                                            {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>
                                );
                            })}
                        </tr>

                        {/* Grand Total Row */}
                        <tr>
                            <div
                                className="bg-white sticky left-0  border-b py-2 min-w-[200px] border-r border-r-gray-300"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            >
                                <td className="sticky left-0 text-[10px] px-4 bg-white font-semibold">
                                    Total Quotation Value (inc. GST)
                                </td>
                            </div>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td
                                        key={idx}
                                        className={`w-full border-b ${idx < sortedSuppliers.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        <div className="">
                                            <p className="font-bold text-[10px] text-right mt-[7px] text-[#004491]">
                                                <span className="bg-[#E0F0EE] px-2 py-1">
                                                    Rs.{" "}
                                                    {new Intl.NumberFormat("en-IN").format(
                                                        vendorData.grandTotal
                                                    )}
                                                </span>{" "}
                                                {/* Adjusted padding */}
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <div
                                className="bg-white sticky left-0 border-b min-w-[200px] border-r border-r-gray-300"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            >
                                <td className="sticky left-0 text-[10px] py-2 px-4 bg-white font-semibold">
                                    Difference from Lowest
                                </td>
                            </div>
                            {sortedSuppliers.map((supplier, idx) => {
                                const vendorQuotation = supplier.vendorQuotations;

                                // Use the appropriate grand total based on withGst state
                                const grandTotal = vendorQuotation.grandTotalWithGst;

                                // Calculate L1 supplier data
                                const l1Supplier = sortedSuppliers[0]; // Assuming sortedSuppliers is sorted by total
                                const l1Total = l1Supplier
                                    ? l1Supplier.vendorQuotations.grandTotalWithGst
                                    : 0;

                                // Calculate deviation
                                const deviation = l1Total ? grandTotal - l1Total : 0;
                                const deviationPercentage = l1Total
                                    ? ((deviation / l1Total) * 100).toFixed(2)
                                    : 0;
                                // map the data here
                                return (
                                    <td
                                        key={idx}
                                        className={`border-t ${idx < sortedSuppliers.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        {idx === 0 ? (
                                            <p className="text-[10px] text-right py-2 mr-2">
                                                Lowest Quote
                                            </p>
                                        ) : (
                                            <p className="text-[10px] text-right py-2 mr-2">
                                                Rs. {new Intl.NumberFormat("en-IN").format(deviation)} (
                                                {deviationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <div
                                className="bg-white sticky left-0  border-b py-2 min-w-[200px] border-r border-r-gray-300"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            >
                                <td
                                    className="sticky left-0 px-4 bg-white w-[300px]"
                                    style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                >
                                    <p className="w-full text-[10px] font-bold text-[#2D2D11]">
                                        Negotiation Outcome{" "}
                                    </p>
                                    <p className="w-full text-[10px] text-[grey]">
                                        Difference between the last & first quote
                                    </p>
                                </td>
                            </div>
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation =
                                    supplier?.vendorQuotations?.quotationSets;

                                // Use the appropriate grand total based on withGst state
                                const firstQuotation =
                                    vendorQuotation?.[0]?.totalWithoutGst +
                                    vendorQuotation?.[0]?.othersTotalWithoutGst || 0;

                                const lastQuotation =
                                    vendorQuotation?.slice(-1)[0]?.totalWithoutGst +
                                    vendorQuotation?.slice(-1)[0]?.othersTotalWithoutGst || 0;

                                // Calculate difference and percentage
                                const difference = firstQuotation - lastQuotation;
                                const negotiationPercentage =
                                    firstQuotation > 0
                                        ? ((difference / firstQuotation) * 100).toFixed(2)
                                        : 0;

                                return (
                                    <td
                                        key={index}
                                        className={`w-[300px] border-t ${index < sortedSuppliers?.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        {firstQuotation === lastQuotation ? (
                                            <p className="text-[10px] text-right p-2">Not Updated</p>
                                        ) : (
                                            <p className="text-[10px] text-right p-2">
                                                Rs. {new Intl.NumberFormat("en-IN").format(difference)}{" "}
                                                ({negotiationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            {/* Sticky Left Column with Shadow */}
                            <td
                                className="sticky left-0 bg-white border-b border-r border-gray-300 px-4 shadow-md"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }} // Custom shadow if needed
                            >
                                <p className="w-full text-[10px] py-2 font-bold text-[#2D2D11]">
                                    Payment Terms
                                </p>
                            </td>

                            {/* Supplier Columns */}
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation =
                                    supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                                return (
                                    <td
                                        key={index}
                                        className={`w-[300px] px-2 py-1 border-t ${index < rfqData?.suppliers?.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        <p className="text-[10px]">
                                            {vendorQuotation?.counterPaymentTerms || "Agreed to payment terms"}
                                        </p>
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            {/* <div
                                className="bg-white sticky left-0 py-2 min-w-[200px] border-r border-r-gray-300"
                                style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                            > */}
                                <td
                                    className="sticky left-0 px-4 bg-white border"
                                    style={{ boxShadow: "4px 0 4px rgba(0, 0, 0, 0.1)" }}
                                >
                                    <p className="w-full text-[10px] py-2 font-bold text-[#2D2D11]">
                                        Vendor Notes
                                    </p>
                                </td>
                            {/* </div> */}
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation =
                                    supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                                return (
                                    <td
                                        className={`w-[300px] px-5 py-2 border-y ${index < rfqData?.suppliers?.length - 1
                                            ? "border-r border-r-[#707070]"
                                            : "border-r"
                                            }`}
                                    >
                                        {/* <p className='text-xs py-2 font-bold'>Counter Offer: <span className='font-normal'> {vendor.paymentTerm}</span></p> */}
                                        <p className="text-[10px] py-2 ">
                                            {" "}
                                            {vendorQuotation?.notes || "N/A"}
                                        </p>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            <StaticPopup
                openModal={openSpecsPopup}
                handleClose={closeSpecsPopup}
                width={"xl"}
                bodyStyle="pb-0 pt-0"
                className="px-0"
                header={popupHeader}
            >
                <div className="py-4">
                    <p>{selectedSpecs}</p> {/* Display the full specifications */}
                </div>
            </StaticPopup>
        </div>
    );
};

export default FullComparison;
