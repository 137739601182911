import { getFormattedUnit } from 'config';
import ExcelJS from 'exceljs';

export const handleDownloadComparisonExcel = async (rfqData, setExcelLoading, setLoadingMessage, enqueueSnackbar) => {
    const sortedSuppliers = rfqData?.suppliers?.filter(supplier => supplier?.quotationReceived)
        .sort((a, b) => {
            const rankA = a.vendorQuotations?.rank;
            const rankB = b.vendorQuotations?.rank;

            // Convert ranks like "L1", "L2" to numerical values for comparison
            const numA = parseInt(rankA.substring(1)); // Extract the number part (e.g., "1" from "L1")
            const numB = parseInt(rankB.substring(1));

            return numA - numB; // Ascending order
        });

    try {
        setExcelLoading(true);
        setLoadingMessage("Generating Comparison Excel...");

        // Create the workbook and sheet
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Material Comparison");

        // Set Column Widths (adjust according to the data)
        sheet.columns = [
            { header: "Sr.", key: "sr", width: 5 },
            { header: "Material Name", key: "materialName", width: 35 },
            { header: "Specification", key: "specification", width: 40 },
            { header: "Approved Brands", key: "brands", width: 20 },
            { header: "Qty", key: "quantity", width: 10 },
            { header: "Unit", key: "unit", width: 10 },
        ];

        // Merge Cells & Add RFQ Details
        const rfqHeaderRange = "A1:F1";
        sheet.mergeCells(rfqHeaderRange);
        sheet.getCell("A1").value =
            `Project Name: ${rfqData.projectName}\n` +
            `Project Location: ${rfqData.location}\n` +
            `Neevay RFQ ID: ${rfqData.rfqId}\n` +
            `Neevay RFQ Subject: ${rfqData.name}\n` +
            `Total Quotations: ${sortedSuppliers.length}`;
        sheet.getCell("A1").alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        sheet.getCell("A1").font = { bold: true };

        // Add Headers in Row 2
        sheet.getRow(2).values = ["Sr.", "Material Name", "Specification", "Approved Brands", "Qty", "Unit"];
        sheet.getRow(2).font = { bold: true };
        sheet.getRow(2).alignment = { vertical: "middle", horizontal: "center" };

        // Populate Materials from RFQ Data (Starting Row 3)
        rfqData.materials.forEach((material, index) => {
            const row = sheet.getRow(index + 3);
            row.getCell(1).value = index + 1; // Sr. No
            row.getCell(2).value = material.name.replace(/<[^>]*>/g, ""); // Clean HTML
            row.getCell(3).value = material.specifications.replace(/<[^>]*>/g, ""); // Clean HTML
            row.getCell(4).value = material.brands.join(", ");
            row.getCell(5).value = material.quantity;
            row.getCell(6).value = getFormattedUnit(material.unit);
        });

        // Add Supplier Columns Starting from Column G onward
        let startCol = 7; // G Column
        sortedSuppliers.forEach((supplier, supplierIndex) => {
            const colStart = startCol + (supplierIndex * 6);
            const colEnd = colStart + 5;
            const lastQuotationSet = supplier.vendorQuotations.quotationSets.slice(-1)[0];

            // Merge Cells for Supplier Header
            const colLetter = sheet.getColumn(colStart).letter;
            const supplierHeaderRange = `${colLetter}1:${sheet.getColumn(colEnd).letter}1`;
            sheet.mergeCells(supplierHeaderRange);

            // Supplier Details for Header
            const supplierDetails =
                `Vendor Rank: ${supplier.vendorQuotations.rank}\n` +
                `Vendor Name: ${supplier.businessName}\n` +
                `Vendor Phone: ${supplier.contactDetails[0]?.mobile}\n\n` +
                `Material Cost: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet.totalWithoutGst)}\n` +
                // Conditionally adding charges based on their value
                (lastQuotationSet?.otherCharges?.transport?.mrp > 0
                    ? `Transport Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.transport?.mrp)}\n`
                    : "") +
                (lastQuotationSet?.otherCharges?.mathadeCharges?.mrp > 0
                    ? `Mathade Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.mathadeCharges?.mrp)}\n`
                    : "") +
                (lastQuotationSet?.otherCharges?.loading?.mrp > 0
                    ? `Loading Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.loading?.mrp)}\n`
                    : "") +
                (lastQuotationSet?.otherCharges?.unLoading?.mrp > 0
                    ? `Unloading Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.unLoading?.mrp)}\n`
                    : "") +
                (lastQuotationSet?.otherCharges?.miscCharges?.mrp > 0
                    ? `Miscellaneous Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.miscCharges?.mrp)}\n`
                    : "") +
                `\nTotal Basic Amount: Rs. ${new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithoutGst)}\n` +
                `Total GST: Rs. ${(new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithGst - supplier?.vendorQuotations?.grandTotalWithoutGst))}\n` +
                `Total Quotation Amount (Inc. GST): Rs. ${new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithGst)}\n\n` +
                (lastQuotationSet?.counterPaymentTerms && `Payment Terms: ${lastQuotationSet?.counterPaymentTerms}`);

            sheet.getCell(`${colLetter}1`).value = supplierDetails
            sheet.getCell(`${colLetter}1`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
            sheet.getCell(`${colLetter}1`).font = { bold: true };

            // Add Supplier Column Headers
            sheet.getRow(2).getCell(colStart).value = "MRP";
            sheet.getRow(2).getCell(colStart + 1).value = "Discount";
            sheet.getRow(2).getCell(colStart + 2).value = "Net Rate";
            sheet.getRow(2).getCell(colStart + 3).value = "GST";
            sheet.getRow(2).getCell(colStart + 4).value = "Basic Amount";
            sheet.getRow(2).getCell(colStart + 5).value = "Vendor Remarks";

            sheet.getColumn(colStart).width = 10;    // MRP
            sheet.getColumn(colStart + 1).width = 9; // Discount
            sheet.getColumn(colStart + 2).width = 13; // Net Rate
            sheet.getColumn(colStart + 3).width = 8; // GST
            sheet.getColumn(colStart + 4).width = 17; // Basic Amount
            sheet.getColumn(colStart + 5).width = 35; // Vendor Remarks

            // Fill Supplier Quotation Data
            rfqData.materials.forEach((material, index) => {
                const row = sheet.getRow(index + 3);
                const lastQuotationSet = supplier.vendorQuotations.quotationSets.slice(-1)[0];
                const quotedMaterial = lastQuotationSet.materialDetails.find(m => m.id === material._id);

                if (quotedMaterial) {
                    // MRP (Base Price)
                    const basePriceCell = row.getCell(colStart);
                    const basePriceFormatted = new Intl.NumberFormat('en-IN').format(quotedMaterial.basePrice);
                    basePriceCell.value = quotedMaterial.basePrice;
                    basePriceCell.numFmt = '0.00';
                    basePriceCell.value = basePriceFormatted;
                    basePriceCell.alignment = { horizontal: "right" }; // Right align the base price cell

                    // Discount
                    row.getCell(colStart + 1).value = `${parseFloat(quotedMaterial.discount)}%` || `0%`;
                    row.getCell(colStart + 1).alignment = { horizontal: "right" }; // Right align the net rate cell

                    // Net Rate
                    row.getCell(colStart + 2).value = new Intl.NumberFormat('en-IN').format(quotedMaterial.netPrice);
                    row.getCell(colStart + 2).alignment = { horizontal: "right" }; // Right align the net rate cell

                    // GST
                    row.getCell(colStart + 3).value = `${quotedMaterial.gst}%`;
                    row.getCell(colStart + 3).alignment = { horizontal: "right" }; // Right align the GST cell

                    // Basic Amount (Price Without GST)
                    const priceWithoutGstCell = row.getCell(colStart + 4);
                    const priceWithoutGstFormatted = new Intl.NumberFormat('en-IN').format(quotedMaterial.priceWithoutGst);
                    priceWithoutGstCell.value = quotedMaterial.priceWithoutGst;
                    priceWithoutGstCell.numFmt = '0.00';
                    priceWithoutGstCell.value = priceWithoutGstFormatted;
                    priceWithoutGstCell.alignment = { horizontal: "right" }; // Right align the basic amount cell

                    // Vendor Remarks
                    row.getCell(colStart + 5).value = quotedMaterial.remark.replace(/<[^>]*>/g, "");
                    row.getCell(colStart + 5).alignment = { wrapText: true };

                }
            });


            const lineCount = supplierDetails.split("\n").length;
            sheet.getRow(1).height = Math.max(20, lineCount * 22);
        });

        // Generate & Download Excel File
        const buffer = await workbook.xlsx.writeBuffer();
        const url = URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Material_Comparison_Rfq_${rfqData.rfqId}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);

        enqueueSnackbar("Excel downloaded successfully!", { variant: "success", autoHideDuration: 3000 });
    } catch (error) {
        console.error("Error generating material comparison Excel:", error);
        enqueueSnackbar("Failed to download Excel file.", { variant: "error", autoHideDuration: 3000 });
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};



export const handleDownloadLabourComparisonExcel = async (rfqData, setExcelLoading, setLoadingMessage, enqueueSnackbar) => {

    // Sorting suppliers based on rank (L1, L2, etc.)
    const sortedSuppliers = rfqData?.suppliers?.filter(supplier => supplier?.quotationReceived)
        .sort((a, b) => {
            const rankA = a.vendorQuotations?.rank;
            const rankB = b.vendorQuotations?.rank;
            return parseInt(rankA.substring(1)) - parseInt(rankB.substring(1));
        });


    try {
        setExcelLoading(true);
        setLoadingMessage("Generating Labour Comparison Excel...");

        // Load the Excel template
        const templatePath = "https://storagereponeevaydevcdn.blob.core.windows.net/business/full_comparison_template_labour.xlsx";
        const response = await fetch(templatePath);
        const buffer = await response.arrayBuffer();

        // Load workbook from the buffer
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);

        // ✅ Access the correct sheet (bulk_material_rates)
        const sheet = workbook.getWorksheet("full_comparison");
        if (!sheet) {
            throw new Error("Sheet 'bulk_material_rates' not found in the template.");
        }

        // **1. Merge Cells & Fill RFQ Details in A1:D1**
        const rfqHeaderRange = "A1:D1";
        if (!sheet.getCell("A1").isMerged) {
            sheet.mergeCells(rfqHeaderRange);
        }
        sheet.getCell("A1").value =
            `Project Name: ${rfqData.projectName}\n` +
            `Project Location: ${rfqData.location}\n` +
            `Neevay RFQ ID: ${rfqData.rfqId}\n` +
            `Neevay RFQ Subject: ${rfqData.name}\n` +
            `Total Quotations: ${sortedSuppliers.length}`;
        sheet.getCell("A1").alignment = { vertical: "middle", horizontal: "left", wrapText: true };

        // **2. Add Labour headers in Row 2**
        sheet.getRow(2).values = ["Sr.", "Work Description", "Quantity", "Unit"];

        // **3. Populate Labour Details from RFQ Data (Starting Row 3)**
        rfqData.labourDetails.forEach((labour, index) => {
            const row = sheet.getRow(index + 3);
            row.getCell(1).value = index + 1; // Sr. No
            row.getCell(2).value = labour.skill.replace(/<[^>]*>/g, ""); // Remove HTML tags from skill description
            row.getCell(3).value = labour.quantity;
            row.getCell(4).value = labour.unit;
        });

        // **4. Fill Supplier Quotation Data (Columns E onward)**
        let startCol = 5; // Column E
        sortedSuppliers.forEach((supplier, supplierIndex) => {
            const lastQuotationSet = supplier.vendorQuotations.quotationSets.slice(-1)[0];
            const colStart = startCol + (supplierIndex * 4);
            const colEnd = colStart + 3; // Each supplier has 4 columns (E-H, I-L, etc.)
            const colLetter = sheet.getColumn(colStart).letter;
            const supplierHeaderRange = `${colLetter}1:${sheet.getColumn(colEnd).letter}1`;

            if (!sheet.getCell(`${colLetter}1`).isMerged) {
                sheet.mergeCells(supplierHeaderRange);
            }

            sheet.getCell(`${colLetter}1`).value =
                `Vendor Rank: ${supplier.vendorQuotations.rank}\n` +
                `Vendor Name: ${supplier.businessName}\n` +
                `Vendor Phone: ${supplier.contactDetails[0]?.mobile}\n\n` +
                `Total Work Cost: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet.totalWithoutGst)}\n` +
                // Conditionally adding charges based on their value
                (lastQuotationSet?.otherCharges?.mathadeCharges?.mrp > 0
                    ? `Mathade Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.mathadeCharges?.mrp)}\n`
                    : "") +
                (lastQuotationSet?.otherCharges?.miscCharges?.mrp > 0
                    ? `Miscellaneous Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.miscCharges?.mrp)}\n\n`
                    : "") +
                `\nTotal Basic Amount: Rs. ${new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithoutGst)}\n` +
                `Total GST: Rs. ${(new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithGst - supplier?.vendorQuotations?.grandTotalWithoutGst))}\n` +
                `Total Quotation Amount (Inc. GST): Rs. ${new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithGst)}\n\n` +
                (lastQuotationSet?.counterPaymentTerms && `Payment Terms: ${lastQuotationSet?.counterPaymentTerms}`)
            sheet.getCell(`${colLetter}1`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };

            // **4b. Supplier Column Headers in Row 2**
            sheet.getRow(2).getCell(colStart).value = "Rate";
            sheet.getRow(2).getCell(colStart + 1).value = "GST";
            sheet.getRow(2).getCell(colStart + 2).value = "Total (Excl. GST)";
            sheet.getRow(2).getCell(colStart + 3).value = "Vendor Remarks";

            // **4c. Map Labour Prices from Supplier Quotation**
            rfqData.labourDetails.forEach((labour, index) => {
                const row = sheet.getRow(index + 3);
                const quotedLabour = lastQuotationSet.labourItems.find(l => l.id === labour._id);

                if (quotedLabour) {
                    row.getCell(colStart).value = new Intl.NumberFormat('en-IN').format(quotedLabour.labourRate);
                    row.getCell(colStart + 1).value = `${quotedLabour.gst}%`;
                    row.getCell(colStart + 2).value = new Intl.NumberFormat('en-IN').format(quotedLabour?.priceWithoutGst);
                    row.getCell(colStart + 3).value = quotedLabour.remarks.replace(/<[^>]*>/g, "");
                }
            });
        });

        // **5. Generate and download the file**
        const blob = await workbook.xlsx.writeBuffer();
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Labour_Comparison_Rfq_${rfqData.rfqId}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);

        enqueueSnackbar("Excel downloaded successfully!", { variant: "success", autoHideDuration: 3000 });
    } catch (error) {
        console.error("Error generating labour comparison Excel:", error);
        enqueueSnackbar("Failed to download Excel file.", { variant: "error", autoHideDuration: 3000 });
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};




export const handleDownloadContractorComparisonExcel = async (rfqData, setExcelLoading, setLoadingMessage, enqueueSnackbar) => {
    try {

        const sortedSuppliers = rfqData?.suppliers?.filter(supplier => supplier?.quotationReceived)
            .sort((a, b) => {
                const rankA = a.vendorQuotations?.rank;
                const rankB = b.vendorQuotations?.rank;
                return parseInt(rankA.substring(1)) - parseInt(rankB.substring(1));
            });


        setExcelLoading(true);
        setLoadingMessage("Generating Contractor Comparison Excel...");

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Full Comparison");

        // Set Column Widths (Adjust as needed)
        sheet.columns = [
            { header: "Sr.", key: "sr", width: 5 },
            { header: "Description", key: "description", width: 45 },
            { header: "Quantity", key: "quantity", width: 12 },
            { header: "Unit", key: "unit", width: 10 },
            { header: "Preferred Brands", key: "brands", width: 30 },
        ];

        // Merge Header Cells & Add RFQ Details
        const headerRange = "A1:E1";
        sheet.mergeCells(headerRange);
        const rfqDetails =
            `Project Name: ${rfqData.projectName}\n` +
            `Project Location: ${rfqData.location}\n` +
            `Neevay RFQ ID: ${rfqData.rfqId}\n` +
            `Neevay RFQ Subject: ${rfqData.name}\n` +
            `Total Quotations: ${sortedSuppliers?.length}`;

        sheet.getCell("A1").value = rfqDetails;
        sheet.getCell("A1").alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        sheet.getCell("A1").font = { bold: true };

        // Add Headers in Row 2
        const headerRow = sheet.getRow(2);
        headerRow.values = ["Sr.", "Description", "Quantity", "Unit", "Preferred Brands"];
        headerRow.font = { bold: true };
        headerRow.alignment = { vertical: "middle", horizontal: "center" };

        // Populate Providing & Fixing Details (Starting Row 3)
        let rowIndex = 3;
        rfqData.providingFixingDetails.forEach((section, secIndex) => {
            const sectionRow = sheet.getRow(rowIndex++);
            sectionRow.getCell(1).value = `S${secIndex + 1}`;
            sectionRow.getCell(2).value = section.sectionName;
            sectionRow.font = { bold: true };

            section.scopes.forEach((scope, scopeIndex) => {
                const scopeRow = sheet.getRow(rowIndex++);
                scopeRow.getCell(1).value = scopeIndex + 1;
                scopeRow.getCell(2).value = scope?.scopeOfWork?.replace(/<[^>]*>/g, "");
                scopeRow.getCell(3).value = scope?.quantity;
                scopeRow.getCell(4).value = getFormattedUnit(scope.unit);
                scopeRow.getCell(5).value = scope?.preferredBrands?.join(", ")?.replace(/<[^>]*>/g, '');

                scope.materials.forEach((material, matIndex) => {
                    const materialRow = sheet.getRow(rowIndex++);
                    materialRow.getCell(1).value = `${scopeIndex + 1}.${matIndex + 1}`;
                    materialRow.getCell(2).value = material?.name?.replace(/<[^>]*>/g, "");
                    materialRow.getCell(3).value = material.quantity;
                    materialRow.getCell(4).value = material.unit;
                    materialRow.getCell(5).value = material?.brands?.join(", ")?.replace(/<[^>]*>/g, '');
                });
            });
        });

        // Add Supplier Details
        let startCol = 6; // Column F
        sortedSuppliers.forEach((supplier, supplierIndex) => {
            const colStart = startCol + (supplierIndex * 7);
            const colLetter = sheet.getColumn(colStart).letter;
            const lastQuotationSet = supplier.vendorQuotations.quotationSets.slice(-1)[0];

            // Merge Cells for Supplier Header
            const supplierHeaderRange = `${colLetter}1:${sheet.getColumn(colStart + 6).letter}1`;
            sheet.mergeCells(supplierHeaderRange);

            // Supplier Details with Conditional Charges
            const supplierDetails =
                `Vendor Name: ${supplier.businessName}\n` +
                `Vendor Rank: ${supplier.vendorQuotations.rank}\n` +
                `Vendor Phone: ${supplier.contactDetails[0]?.mobile}\n\n` +
                `Material/Installation Cost: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.totalWithoutGst)}\n` +
                (lastQuotationSet?.otherCharges?.transport?.mrp > 0 ? `Transport Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.transport?.mrp)}\n` : '') +
                (lastQuotationSet?.otherCharges?.mathadeCharges?.mrp > 0 ? `Mathade Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.mathadeCharges?.mrp)}\n` : '') +
                (lastQuotationSet?.otherCharges?.loading?.mrp > 0 ? `Loading Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.loading?.mrp)}\n` : '') +
                (lastQuotationSet?.otherCharges?.unLoading?.mrp > 0 ? `Unloading Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.unLoading?.mrp)}\n` : '') +
                (lastQuotationSet?.otherCharges?.miscCharges?.mrp > 0 ? `Miscellaneous Charges: Rs. ${new Intl.NumberFormat('en-IN').format(lastQuotationSet?.otherCharges?.miscCharges?.mrp)}\n\n` : '') +
                `\nTotal Basic Amount: Rs. ${new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithoutGst)}\n` +
                `Total GST: Rs. ${new Intl.NumberFormat('en-IN').format(supplier.vendorQuotations?.grandTotalWithGst - supplier?.vendorQuotations.grandTotalWithoutGst)}\n` +
                `Total Quotation Amount (Inc. GST): Rs. ${new Intl.NumberFormat('en-IN').format(supplier?.vendorQuotations?.grandTotalWithGst)}\n\n` +
                (lastQuotationSet?.counterPaymentTerms && `Payment Terms: ${lastQuotationSet?.counterPaymentTerms}`);

            sheet.getCell(`${colLetter}1`).value = supplierDetails;
            sheet.getCell(`${colLetter}1`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
            sheet.getCell(`${colLetter}1`).font = { bold: true };


            sheet.getRow(2).splice(colStart, 0, "Material Rate", "Installation Rate", "GST", "Material Cost", "Installation Cost", "Total(Excl. GST)", "Vendor Remarks");

            // Set column widths for these new columns (starting from colStart)
            sheet.getColumn(colStart).width = 12;       // Material Rate
            sheet.getColumn(colStart + 1).width = 12;   // Installation Rate
            sheet.getColumn(colStart + 2).width = 7;    // GST
            sheet.getColumn(colStart + 3).width = 15;   // Material Cost
            sheet.getColumn(colStart + 4).width = 15;   // Installation Cost
            sheet.getColumn(colStart + 5).width = 20;   // Total(Excl. GST)
            sheet.getColumn(colStart + 6).width = 40;

            // Populate Quotation Data
            let dataRowIndex = 3;
            supplier.vendorQuotations.quotationSets.slice(-1)[0].contractorDetails.forEach(section => {
                const sectionRow = sheet.getRow(dataRowIndex++);
                sectionRow.getCell(colStart + 5).value = new Intl.NumberFormat('en-IN').format(section.sectionTotalExclGst);

                section.scopes.forEach(scope => {
                    const scopeRow = sheet.getRow(dataRowIndex++);


                    if (scope.materials && scope.materials.length > 0) {
                        // Empty the price fields for this scope row
                        scopeRow.getCell(colStart).value = "";  // Base Price
                        scopeRow.getCell(colStart + 1).value = "";  // Installation Rate
                        scopeRow.getCell(colStart + 2).value = "";  // GST
                        scopeRow.getCell(colStart + 3).value = "";  // Material Cost
                        scopeRow.getCell(colStart + 4).value = "";  // Installation Cost
                        scopeRow.getCell(colStart + 5).value = "";  // Total (Excl. GST)
                        scopeRow.getCell(colStart + 6).value = "";  // remarks
                    } else {
                        // If no materials, populate price data for scope itself
                        scopeRow.getCell(colStart).value = new Intl.NumberFormat('en-IN').format(scope.basePrice || 0);
                        scopeRow.getCell(colStart + 1).value = new Intl.NumberFormat('en-IN').format(scope.installationRate || 0);
                        scopeRow.getCell(colStart + 2).value = `${scope.gst || 0}%`;
                        scopeRow.getCell(colStart + 3).value = new Intl.NumberFormat('en-IN').format(scope.supplyCost || 0);
                        scopeRow.getCell(colStart + 4).value = new Intl.NumberFormat('en-IN').format(scope.installationCost || 0);
                        scopeRow.getCell(colStart + 5).value = new Intl.NumberFormat('en-IN').format(scope.totalExclGst || 0);
                        scopeRow.getCell(colStart + 6).value = scope?.comments?.replace(/<[^>]*>/g, "") || '';

                        scopeRow.getCell(colStart + 6).alignment = { wrapText: true };
                    }

                    // adjustRowHeight(scopeRow, colStart + 6)

                    scope.materials.forEach(material => {
                        const materialRow = sheet.getRow(dataRowIndex++);
                        materialRow.getCell(colStart).value = new Intl.NumberFormat('en-IN').format(material.basePrice || 0);
                        materialRow.getCell(colStart + 1).value = new Intl.NumberFormat('en-IN').format(material.installationRate || 0);
                        materialRow.getCell(colStart + 2).value = `${material.gst || 0}%`;
                        materialRow.getCell(colStart + 3).value = new Intl.NumberFormat('en-IN').format(material.netPrice || 0);
                        materialRow.getCell(colStart + 4).value = new Intl.NumberFormat('en-IN').format(material.installationCost || 0);
                        materialRow.getCell(colStart + 5).value = new Intl.NumberFormat('en-IN').format(material.totalExclGst || 0);
                        materialRow.getCell(colStart + 6).value = material?.remarks?.replace(/<[^>]*>/g, "") || '';

                        // adjustRowHeight(materialRow, colStart + 6);
                        materialRow.getCell(colStart + 6).alignment = { wrapText: true };
                    });
                });
            });

            // 📌 Adjust Row Height Based on Content
            const lineCount = supplierDetails.split("\n").length;
            sheet.getRow(1).height = Math.max(20, lineCount * 20);
        });


        // Add Terms of Contract Sheet
        const termsSheet = workbook.addWorksheet("Terms of Contract");

        // Set header and terms content
        termsSheet.getCell("A1").value = "Terms of Contract";
        termsSheet.getCell("A1").font = { bold: true };
        termsSheet.getCell("A1").alignment = { vertical: "middle", horizontal: "center" };
        termsSheet.getCell("A2").value = rfqData?.termsAndConditions?.replace(/<[^>]*>/g, "");
        termsSheet.getCell("A2").alignment = { wrapText: true };
        termsSheet.getColumn(1).width = 100;

        // 📌 Generate & Download Excel File
        const blob = await workbook.xlsx.writeBuffer();
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Contractor_Comparison_Rfq_${rfqData.rfqId}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);

        enqueueSnackbar("Excel downloaded successfully!", { variant: "success", autoHideDuration: 3000 });
    } catch (error) {
        console.error("Error generating contractor comparison Excel:", error);
        enqueueSnackbar("Failed to download Excel file.", { variant: "error", autoHideDuration: 3000 });
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};