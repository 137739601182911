import StaticPopup from 'components/StaticPopup';
import StaticPopupNew from 'components/StaticPopupNew';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from "react-html-parser";


const RfqOverview = ({ data }) => {
    const [selectedSpecs, setSelectedSpecs] = useState('');
    const [products, setProducts] = useState([]);
    const [openSpecsPopup, setOpenSpecsPopup] = useState(false);
    const [showMaterial, setShowMaterial] = useState(false);
    const [showContractor, setShowContractor] = useState(false);
    const [showLabour, setShowLabour] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [expandedSections, setExpandedSections] = useState(() => {
        // Initialize the state with all sections open by default
        const initialOpenState = {};
        data?.providingFixingDetails?.forEach((section) => {
            initialOpenState[section._id] = true; // Set all sections to true (open)
        });
        return initialOpenState;
    });
    const [expandedMaterials, setExpandedMaterials] = useState({});

    // Toggle section visibility
    const toggleSection = (sectionId) => {
        setExpandedSections((prev) => ({
            ...prev,
            [sectionId]: !prev[sectionId],
        }));
    };

    // Toggle material visibility
    const toggleMaterial = (materialId) => {
        setExpandedMaterials((prev) => ({
            ...prev,
            [materialId]: !prev[materialId],
        }));
    };

    const togglePopup = (detail) => {
        setSelectedDetail(detail);
        setIsPopupOpen(true);
    };

    const closeSpecsPopup = () => {
        setOpenSpecsPopup(false);
    };

    const handleSpecsButtonClick = (specifications) => {
        if (specifications) {
            setSelectedSpecs(specifications);
            setOpenSpecsPopup(true);
        } else {
            console.log("No specifications available for this product.");
        }
    };

    useEffect(() => {
        if (data) {
            setProducts(data.materials || []);
            const rfqType = data.rfqType;
            setShowMaterial(rfqType === "material");
            setShowContractor(rfqType === "contractor");
            setShowLabour(rfqType === "labour");
        }
    }, [data]);

    const attachmentsData = data?.selectedFilesBase
        ? data.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : []


    // const providingFixingDetails = data?.providingFixingDetails?.[0]
    // ? data.providingFixingDetails[0]
    //     .map((detail, index) => `<li key=${index}>${detail}</li>`) // Map through the details
    //     .join("") // Combine into a single string
    // : "<li>No details available</li>";

    return (
        <div>
            {/* Material Table */}
            {showMaterial && products.length > 0 && (
                <div>
                    <h3 className='font-semibold text-sm my-2'>Material Information</h3>
                    {data?.materials?.some(material => material.name || material.quantity || material.brands.length > 0 || material.specifications) && (
                        <div className='border w-full text-xs  shadow-md rounded-lg'>
                            <div className='grid grid-cols-14 gap-2 p-2 font-medium text-xs text-[#727269] bg-[#DDDDD3]'>
                                <p className='col-span-1'>Sr.No.</p>
                                <p className='col-span-7'>Product</p>
                                <p className='col-span-2'>Quantity</p>
                                <p className='col-span-2'>Brand</p>
                                <p className='col-span-2 text-center'>Specifications</p>
                            </div>
                            <div className='max-h-[200px] overflow-y-auto'>
                                {products.map((product, productIndex) => (
                                    <div key={productIndex} className={`grid grid-cols-14 gap-2 font-normal p-2 ${productIndex % 2 === 0 ? 'bg-[#FBFBF9]' : ''}`}>
                                        <p className='col-span-1 pl-2'>{productIndex + 1}</p>
                                        <p className='col-span-7 break-words'>{ReactHtmlParser(product?.name || "N/A")}</p>
                                        <p className='col-span-2 truncate'>{product?.quantity ? `${product.quantity} ${product.unit || ''}` : "N/A"}</p>
                                        <p className='col-span-2 break-words'>{product?.brands.length > 0 ? product.brands.join(", ") : "N/A"}</p>
                                        {product?.specifications?.length > 0 ? (
                                            <button
                                                type="button"
                                                className="col-span-2 mx-auto"
                                                onClick={() => handleSpecsButtonClick(product?.specifications)}
                                            >
                                                <img
                                                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/comment_create_rfq.svg"
                                                    alt="View Specs"
                                                />
                                            </button>
                                        ) : (
                                            <p className='mx-auto col-span-2'>NA</p>
                                        )}

                                    </div>
                                ))}

                                <StaticPopup
                                    openModal={openSpecsPopup}
                                    handleClose={closeSpecsPopup}
                                    width={"xl"}
                                    bodyStyle="pb-0 pt-0"
                                    className="px-0"
                                    header={"Specifications"}
                                >
                                    <div className='py-8'>
                                        <p className='text-center'>{ReactHtmlParser(selectedSpecs || "N/A")}</p>
                                    </div>
                                </StaticPopup>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* Providing & Fixing Section */}
            {showContractor && data?.providingFixingDetails && (
                <div>
                    <h3 className="font-semibold text-sm my-2">Providing & Fixing</h3>
                    <div className="space-y-2 text-xs ">
                        {data.providingFixingDetails.map((section, sectionIndex) => (
                            <div key={section._id} className="border border-gray-300">
                                {/* Section Header */}
                                <div
                                    className="bg-white p-2 flex justify-between items-center cursor-pointer"
                                    onClick={() => toggleSection(section._id)}
                                >
                                    <h2>{section.sectionName}</h2>
                                    <img
                                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_dropdown_arrow.svg"
                                        alt="toggle"
                                        className={`w-2 h-2 transition-transform duration-200 ${expandedSections[section._id] ? "rotate-180" : "rotate-0"
                                            }`}
                                    />
                                </div>
                                {/* Scopes */}
                                {expandedSections[section._id] && (
                                    <div className=" shadow-md">
                                        {section.scopes.map((scope, scopeIndex) => (
                                            <div key={scope._id} className="p-2">
                                                {/* Scope Details */}
                                                <div className="w-full text-xs border border-gray-300">
                                                    {/* Grid Header */}
                                                    <div className="grid grid-cols-5 gap-2 text-gray-500 bg-gray-100 border-b border-gray-300 ">
                                                        {/* <div className="px-4 py-2">Index</div> */}
                                                        <div className="px-4 py-1 col-span-3">Scope of Work</div>
                                                        <div className="px-4 py-1">Quantity</div>
                                                        <div className="px-4 py-1">Preferred Brands</div>
                                                    </div>

                                                    {/* Grid Rows */}
                                                    <div className="grid grid-cols-5 gap-2 border-b bg-white border-gray-300">
                                                        {/* Row Content */}
                                                        {/* <div className="px-4 py-2"></div> */}
                                                        <div className="px-4 py-2 col-span-3 flex space-x-2">
                                                            <p>{scopeIndex + 1}. </p>
                                                            <p>{ReactHtmlParser(scope.scopeOfWork)}</p>
                                                        </div>
                                                        <div className="px-4 py-2">{scope.quantity} {scope.unit}</div>
                                                        <div className="px-4 py-2 break-words ">
                                                            {scope.preferredBrands.length > 0 ? (
                                                                scope.preferredBrands.map((brand, index) => (
                                                                    <span key={index}>
                                                                        {ReactHtmlParser(brand)}
                                                                        {index !== scope.preferredBrands.length - 1 ? ", " : ""}
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                <span className='mx-auto'>N/A</span>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* Materials */}
                                                {scope.materials.length > 0 && <div className="mt-4">
                                                    <p className='pb-1'>Material List for scope {scopeIndex + 1}</p>
                                                    <div className="w-full border border-gray-300">
                                                        {/* Header */}
                                                        <div className="grid grid-cols-10 text-gray-500 bg-gray-100 border-b border-gray-300">
                                                            <div className="px-4 py-1 col-span-1 text-left">Sr#</div>
                                                            <div className="px-4 py-1 col-span-3 text-left">Name</div>
                                                            <div className="px-4 py-1 col-span-3 text-left">Quantity</div>
                                                            <div className="px-4 py-1 col-span-3 text-left">Brands</div>
                                                        </div>

                                                        {/* Body */}
                                                        {scope.materials.map((material, materialIndex) => (
                                                            <div key={material._id} className="grid grid-cols-10 border-b bg-white border-gray-300">
                                                                <p className='px-4 py-2'>{scopeIndex + 1}.{materialIndex + 1}</p>
                                                                <div className="px-4 py-2 col-span-3 break-words">
                                                                    {ReactHtmlParser(material.name)}
                                                                </div>
                                                                <div className="px-4 py-2 col-span-3">
                                                                    {material.quantity} {material.unit}
                                                                </div>
                                                                <div className="px-4 py-2 col-span-3 break-words">
                                                                    {material.brands.length > 0
                                                                        ? material.brands.join(", ")
                                                                        : "NA"}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}


            {/* Labour Table */}
            {data.labourDetails?.length > 0 && (
                <div>
                    <h3 className="text-sm font-bold my-2">Labour Information</h3>
                    <div className="grid grid-cols-12 text-sm table-fixed">
                        <div className="col-span-1 font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Sr.No.</div>
                        <div className="col-span-8 font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Work Description</div>
                        <div className="col-span-3 font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Quantity</div>
                        {/* <div className="col-span-2 font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Unit</div> */}

                        {data.labourDetails.map((detail, index) => (
                            <React.Fragment key={index}>
                                <div className="col-span-1 text-[#222222] bg-white border-b text-xs pl-4 py-2">{index + 1}</div>
                                <div className="col-span-8 text-[#222222] bg-white border-b text-xs p-2">
                                    <>
                                        {ReactHtmlParser(
                                            detail.skill.length > 200
                                                ? `${detail.skill.slice(0, 200)}...`
                                                : detail.skill
                                        ) || "N/A"}
                                        {detail.skill && detail.skill !== "N/A" && detail.skill.length > 200 && (
                                            <button
                                                onClick={() => togglePopup(detail.skill)}
                                                type="button"
                                                className="text-gray-400 text-xs underline italic"
                                            >
                                                Read More
                                            </button>
                                        )}</>
                                </div>
                                <div className="col-span-3 text-[#727269] bg-white border-b text-xs p-2">{detail.quantity || "N/A"} {detail.unit || "N/A"}</div>
                                {/* <div className="col-span-2 text-[#727269] bg-white border-b text-xs p-2"></div> */}
                            </React.Fragment>
                        ))}
                    </div>

                    {/* StaticPopupNew Component */}
                    {isPopupOpen && selectedDetail && (
                        <StaticPopupNew
                            openModal={isPopupOpen}
                            handleClose={() => setIsPopupOpen(false)}
                            width={"lg"}
                            height={80}
                            bodyStyle="pb-0 pt-0 px-0"
                            className="px-0 text-sm"
                            header={"Work Details"}
                        >
                            <div className="p-4 max-h-[200px] overflow-y-auto">
                                <p className="text-xs text-[#3D3D3D]">{ReactHtmlParser(selectedDetail)}</p>
                            </div>
                        </StaticPopupNew>
                    )}
                </div>
            )}


            <div className="my-5 hidden xl:block">
                {attachmentsData.length > 0 && (
                    <>
                        <h3 className="font-semibold text-sm hidden xl:block">Attached Documents</h3>

                        {attachmentsData.map((doc, index) => (
                            <div key={index} className="px-2 mt-2 flex w-fit items-center bg-white justify-center shadow-md rounded-full py-1 space-x-2 text-[#434343]">
                                {/* Dynamic icon based on file type */}
                                <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                {/* Document name now clickable */}
                                <a
                                    href={doc.Content}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={doc.Name}
                                    className="text-xs text-[#434343] hover:underline"
                                >
                                    {doc.Name}
                                </a>
                            </div>
                        ))}
                    </>
                )}
            </div>

            <div className="my-5 hidden xl:block">
                {data?.paymentTerms?.length > 0 && (
                    <>
                        <h3 className="font-semibold text-sm hidden xl:block break-words">Payment Terms</h3>
                        {/* payment terms */}
                        {data?.paymentTerms && <tr>
                            <td className='text-xs break-words'>{ReactHtmlParser(data?.paymentTerms)}</td>
                        </tr>}
                    </>
                )}
            </div>

            <div className="my-5 hidden xl:block">
                {data?.termsAndConditions?.length > 0 && (
                    <>
                        <h3 className="font-semibold text-sm hidden xl:block">Terms & Conditions</h3>
                        {/* payment terms */}
                        {data?.termsAndConditions && <tr>
                            <td className='text-xs break-words'>{ReactHtmlParser(data?.termsAndConditions)}</td>
                        </tr>}
                    </>
                )}
            </div>
        </div>
    );
}

export default RfqOverview;
