import React from 'react';

const ContractorRFQTable = ({ rfqData, sortedSuppliers, withGst, openDropdown, handleMaterialDropdown, handleSectionClick }) => (
    
    rfqData?.providingFixingDetails?.map((providingFixingDetail, rowIndex) => (
        <tr key={rowIndex} className='bg-white'>
            <div onClick={()=>handleSectionClick(rfqData?.rfqId, rfqData?.rfqType)} className='sticky cursor-pointer z-10 left-0 bg-white' style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
                <td className=" text-xs z-10 bg-white  max-w-[300px] min-w-[300px] px-6">
                    <div className='space-x-2 text-blue-500 bg-white w-full py-2 flex'>
                        <p>{rowIndex + 1}.</p>
                        <p className='w-full whitespace-normal underline break-words '>{providingFixingDetail?.sectionName}</p>
                    </div>
                </td>
            </div>
            {sortedSuppliers?.map((supplier, vendorIndex) => {
                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                const vendorItem = vendorQuotation?.contractorDetails || [];

                // Find the matching section based on sectionName or ID
                const matchingSection = vendorItem.find(
                    (section) => (section.id === providingFixingDetail._id || section.sectionName === providingFixingDetail.sectionName)
                );

                return (
                    <td key={vendorIndex} className={`min-w-[300px] px-4 z-0 border border-gray-300 ${vendorIndex < sortedSuppliers?.length - 1 ? 'border-r' : ''}`}>
                        <div onClick={() => handleMaterialDropdown(rowIndex, vendorIndex)} className='relative py-1'>
                            <div>
                                {matchingSection ? (
                                    <p className="text-xs text-right w-full text-[#777777]">
                                        Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(matchingSection.sectionTotalExclGst || 0)}
                                    </p>
                                ) : (
                                    <p className="text-xs text-right w-full text-[#777777]">Rs. 0.00</p>
                                )}
                            </div>
                        </div>
                    </td>
                );
            })}
        </tr>
    ))
);

export default ContractorRFQTable;