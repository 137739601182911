import { isPast } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "utils/axios";
import Skeleton from "@mui/material/Skeleton"
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { submitMaterialQuotation } from "actions/vendorQuotationAction";
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from "react-router-dom";
import CustomGstDropdown from "./CustomGstDropdown";
import StaticPopup from "components/StaticPopup";
import FileUpload from "./FileUpload";
import { vendorQuotationService } from "services/vendorQuotationService";
import ReactQuill from "react-quill";
import ExcelJS from "exceljs";
import LoadingModal from "views/Rfqs/LoadingModal";
import { handleFileUploadClick, handleFileUpload, handleDeleteSelectedFile, handleCloseFileSizeError } from "./fileUploadHelpers";
import { handleDownloadExcel, handleUploadExcel } from './excelHelpers';
import {
    handleMaterialFileUploadClick,
    handleMaterialFileUpload,
    handleDeleteMaterialFile,
    handleCloseMaterialFileSizeError
} from './materialFileUpload';
import MaterialMobileQuotatoinForm from "./MaterialMobileQuotatoinForm";
import ReactHtmlParser from "react-html-parser";
import RfqInformation from "./RfqInformation";



const getFormattedUnit = (unit) => {
    switch (unit) {
        case "Sq. Feet(SFT)":
            return "sq. ft.";
        case "Running Meter(RMT)":
            return "rmt";
        case "Sq. Meter":
            return "sq. mtr.";
        case "Numbers(Qty)":
            return "nos.";
        case "Cubic Meters(CUM)":
            return "cum";
        case "Kilograms(Kgs)":
            return "kgs";
        case "Metric Ton(M.Ton)":
            return "m. ton";
        case "Liters":
            return "ltrs";
        case "Bags":
            return "bags";
        case "Cubic Foot(CFT)":
            return "cft";
        default:
            return unit;
    }
};


const MaterialQuotationForm = () => {

    const { loading, error, errorMessage, quotation } = useSelector((state) => state.vendorQuotation);
    const { enqueueSnackbar } = useSnackbar();

    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const [rfqData, setRfqData] = useState(location.state?.rfqData || {});
    const { isEdit } = location.state;
    const { otherCharges } = rfqData || {};

    const supplierId = location.state?.supplierId || "";
    const quotationIndex = location.state?.quotationIndex;
    const isReadOnly = quotationIndex !== undefined || rfqData?.status === "CLOSED"
    const mostRecentquotation = quotationIndex !== undefined ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex] : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]

    useEffect(() => {
        const fetchVendorQuotation = async () => {
            if (supplierId) {
                try {
                    const payload = { rfqId: rfqData?.rfqId, supplierId };
                    const response = await vendorQuotationService.getVendorQuotation(payload);
                    // console.log("Fetched Vendor Quotation Data:", response);

                    // Update rfqData.suppliers[0] with fetched vendorQuotations
                    if (response?.vendorQuotations) {
                        setRfqData(prevData => ({
                            ...prevData,
                            suppliers: prevData.suppliers?.map((supplier, index) =>
                                index === 0 ? { ...supplier, vendorQuotations: response.vendorQuotations } : supplier
                            ) || []
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching vendor quotation:", error);
                }
            }
        };

        fetchVendorQuotation();
    }, [supplierId, isEdit, rfqData?.rfqId]);

    useEffect(() => {
        if (rfqData?.suppliers?.[0]?.vendorQuotations) {

            const latestQuotationSet = quotationIndex !== undefined ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex] : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0];

            setTotalCosts(latestQuotationSet?.materialDetails?.map((material) => material?.netPrice) || rfqData?.materials.map(() => 0));
            setTotalMaterialCostsExclGST(latestQuotationSet?.totalWithoutGst > 0 ? latestQuotationSet?.totalWithoutGst : 0)
            setOverallTotal(quotationIndex !== undefined ? (latestQuotationSet?.othersTotalWithGst + latestQuotationSet?.totalWithGst) : rfqData.suppliers[0].vendorQuotations.grandTotalWithGst || 0);
            setOverallTotalExclGST(latestQuotationSet?.totalWithoutGst + latestQuotationSet?.othersTotalWithoutGst || 0)
            setSelectedFilesBase(latestQuotationSet?.attachments || []);
            setIsCounterTerm(latestQuotationSet?.agreedToPaymentTerms || false);


            const otherCharges = latestQuotationSet?.otherCharges || {};

            setMiscellaneousCosts({
                transport: otherCharges?.transport || { mrp: 0, gst: 0, totalCost: 0 },
                loading: otherCharges?.loading || { mrp: 0, gst: 0, totalCost: 0 },
                unLoading: otherCharges?.unLoading || { mrp: 0, gst: 0, totalCost: 0 },
                mathadeCharges: otherCharges?.mathadeCharges || { mrp: 0, gst: 0, totalCost: 0 },
                miscCharges: otherCharges?.miscCharges || { mrp: 0, gst: 0, totalCost: 0 }
            });
        } else {
            setMiscellaneousCosts({
                transport: { mrp: 0, gst: 0, totalCost: 0 },
                loading: { mrp: 0, gst: 0, totalCost: 0 },
                unLoading: { mrp: 0, gst: 0, totalCost: 0 },
                mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
                miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
            });
        }
    }, [rfqData, quotationIndex]);


    const miscellaneousItems = [
        { label: "Transport", name: "transport" },
        { label: "Loading", name: "loading" },
        { label: "Unloading", name: "unLoading" },
        { label: "Mathade Charges", name: "mathadeCharges" },
        { label: "Misc. Charges", name: "miscCharges" },
    ];

    const visibleMiscellaneousItems = miscellaneousItems.filter(item => otherCharges?.[item.name]);

    // Check if any items are visible
    const hasVisibleMiscellaneousItems = visibleMiscellaneousItems.length > 0;

    const currentRank = rfqData?.suppliers[0]?.vendorQuotations?.rank
    // console.log("aaaaaaaa", currentRank)

    const attachmentsData = rfqData?.selectedFilesBase
        ? rfqData.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : [];

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isSpecsPopupOpen, setIsSpecsPopupOpen] = useState(false);
    const [totalCosts, setTotalCosts] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.materialDetails?.map((material) => material?.netPrice) || rfqData?.materials.map(() => 0));
    const [overallTotal, setOverallTotal] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.grandTotalWithGst || 0);
    const [overallTotalExclGST, setOverallTotalExclGST] = useState(0);
    const [totalMaterialCostsExclGST, setTotalMaterialCostsExclGST] = useState(mostRecentquotation?.totalWithoutGst > 0 ? mostRecentquotation?.totalWithoutGst : 0)
    const [miscellaneousCosts, setMiscellaneousCosts] = useState({
        transport: { mrp: 0, gst: 0, totalCost: 0 },
        loading: { mrp: 0, gst: 0, totalCost: 0 },
        unLoading: { mrp: 0, gst: 0, totalCost: 0 },
        mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
        miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
    });

    const [isCounterTerm, setIsCounterTerm] = useState(false);
    // const [isCounterTerm, setIsCounterTerm] = useState(!rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.agreedToPaymentTerms || false);
    const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
    const [selectedFilesBase, setSelectedFilesBase] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.attachments || []);
    const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
    const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState("");
    const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
    const [openAccordian, setOpenAccordian] = useState(null); // State to manage open accordion
    const [openSpecsPopup, setOpenSpecsPopup] = useState(false);
    const [selectedSpecs, setSelectedSpecs] = useState("");

    const [excelLoading, setExcelLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [excelError, setExcelError] = useState(null);


    const [materialFilesBase, setMaterialFilesBase] = useState([]);
    const [materialFilesSkeleton, setMaterialFilesSkeleton] = useState([]);
    const [materialFilesTotalSize, setMaterialFilesTotalSize] = useState([]);
    const [materialUploadsErrors, setMaterialUploadsErrors] = useState("");
    const [openMaterialFileSizeError, setOpenMaterialFileSizeError] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null);
      const [expandedStates, setExpandedStates] = useState({}); // Track expanded states for each item
          const toggleExpanded = (index) => {
            setExpandedStates((prev) => ({
              ...prev,
              [index]: !prev[index], // Toggle specific item's state
            }));
          };

    const getInitialValues = (rfqData, userProfile) => {
        const isEditing = Boolean(rfqData?.suppliers?.[0]?.vendorQuotations);
        const lastQuotationSet = isEditing
            ? (quotationIndex !== undefined
                ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex]
                : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0])
            : null;

        // Create a map of materials from lastQuotationSet for quick lookup by ID and name
        const quotationMaterialMap = (lastQuotationSet?.materialDetails || []).reduce((map, material) => {
            if (material.id) {
                map.byId[material.id.toString()] = material;
            }
            if (material.name) {
                map.byName[material.name.toLowerCase()] = material;
            }
            return map;
        }, { byId: {}, byName: {} });

        // Merge rfqData.materials with lastQuotationSet.materialDetails
        const mergedMaterials = (rfqData?.materials || []).map(material => {
            const matchedMaterial =
                quotationMaterialMap.byId[material._id?.toString()] || // Match by ID
                quotationMaterialMap.byName[material.name?.toLowerCase()]; // Fallback to name match

            return matchedMaterial
                ? {
                    name: matchedMaterial.name,
                    quantity: material.quantity, // Use updated quantity from rfqData
                    unit: matchedMaterial.unit || material.unit,
                    id: matchedMaterial.id, // Retain ID from lastQuotationSet
                    specifications: matchedMaterial.specifications || material.specifications,
                    brand: matchedMaterial.brand,
                    basePrice: matchedMaterial.basePrice || 0,
                    discount: matchedMaterial.discount || 0,
                    gst: matchedMaterial.gst,
                    totalCost: matchedMaterial.totalCost || 0,
                    totalExclGst: matchedMaterial.priceWithoutGst || 0,
                    netPrice: matchedMaterial.netPrice || 0,
                    remark: matchedMaterial.remark || "",
                    deliveryDate: matchedMaterial?.deliveryDate
                        ? new Date(matchedMaterial.deliveryDate).toISOString().split("T")[0]
                        : "",
                    attachments: matchedMaterial.attachments || []
                }
                : {
                    name: material.name,
                    quantity: material.quantity,
                    unit: material.unit,
                    id: material._id, // Use the ID from rfqData
                    specifications: material.specifications,
                    brand: material.brands?.join(", ") || "",
                    basePrice: "",
                    discount: 0,
                    gst: 18,
                    totalCost: 0,
                    totalExclGst: 0,
                    netPrice: 0,
                    remark: "",
                    deliveryDate: rfqData?.providingDate
                        ? new Date(rfqData.providingDate).toISOString().split("T")[0]
                        : "",
                    attachments: []
                };
        });

        return {
            materials: mergedMaterials,

            miscellaneousCosts: {
                transport: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.transport?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.transport?.gst || 18 : 18,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.transport?.totalCost || 0 : 0
                },
                loading: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.loading?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.loading?.gst || 18 : 18,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.loading?.totalCost || 0 : 0
                },
                unLoading: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.unLoading?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.unLoading?.gst || 18 : 18,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.unLoading?.totalCost || 0 : 0
                },
                mathadeCharges: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.mathadeCharges?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.mathadeCharges?.gst || 18 : 18,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.mathadeCharges?.totalCost || 0 : 0
                },
                miscCharges: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.gst || 18 : 18,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.totalCost || 0 : 0
                }
            },

            agreedToPaymentTerms: isEditing ? lastQuotationSet?.agreedToPaymentTerms : true,
            counterPaymentTerms: isEditing ? lastQuotationSet?.counterPaymentTerms || "" : "",
            notes: isEditing ? lastQuotationSet?.notes : ""
        };
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const toggleAccordion = (index) => {
        setOpenAccordian(openAccordian === index ? null : index); // Toggle the open state
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const togglePopupSpecs = () => {
        setIsSpecsPopupOpen(!isSpecsPopupOpen);
    };

    const handleCloseForm = () => {
        if (supplierId) {
            navigate('/leads')
        } else {
            navigate(-1)
        }
    }

    // Function to open the popup with full specifications
    const openFullSpecs = (specifications) => {
        setSelectedSpecs(specifications); // Set the full specifications
        setOpenSpecsPopup(true); // Open the popup
    };

    // Function to close the popup
    const closeSpecsPopup = () => {
        setOpenSpecsPopup(false); // Close the popup
    };

    // const handleBrandDropDown = (index) => {
    //     setIsBrandDropdownOpen((prev) => {
    //         const newDropdownState = [...prev];
    //         newDropdownState[index] = !newDropdownState[index];
    //         return newDropdownState;
    //     });
    // };

    // const handleRadioChange = (index, value) => {
    //     setselectedBrand((prev) => {
    //         const newPaintState = [...prev];
    //         newPaintState[index] = value;
    //         return newPaintState;
    //     });
    //     setIsBrandDropdownOpen((prev) => {
    //         const newDropdownState = [...prev];
    //         newDropdownState[index] = false; 
    //         return newDropdownState;
    //     });
    // };

    const calculateTotalCost = (basePrice, quantity, discount, gst) => {
        const cost = basePrice * quantity;
        const discountAmount = (cost * discount) / 100;
        const gstAmount = ((cost - discountAmount) * gst) / 100;
        return cost - discountAmount + gstAmount;
    };

    const calculateOverallTotal = (materialCosts, miscCosts) => {
        // Summing up all the material costs
        const totalMaterialCosts = materialCosts.reduce((acc, curr) => acc + curr, 0);

        // Summing up all the miscellaneous costs (like transport, loading, etc.)
        const totalMiscCosts = Object.values(miscCosts).reduce((acc, curr) => acc + (curr.totalCost || 0), 0);

        // Update the overall total (material costs + miscellaneous costs)
        const newOverallTotal = totalMaterialCosts + totalMiscCosts;
        setOverallTotal(newOverallTotal);
    };

    const calculateOverallTotalWithoutGST = (materialCosts, miscCosts) => {
        // Summing up all the material costs excluding GST
        const totalMaterialCostsExclGST = materialCosts.reduce((acc, curr) => acc + curr.totalExclGst, 0);
        setTotalMaterialCostsExclGST(totalMaterialCostsExclGST)
        // Summing up all the miscellaneous costs excluding GST
        const totalMiscCostsExclGST = Object.values(miscCosts).reduce((acc, curr) => acc + (curr.mrp || 0), 0);

        // Update the overall total excluding GST (material costs + miscellaneous costs)
        const newOverallTotalExclGST = totalMaterialCostsExclGST + totalMiscCostsExclGST;
        setOverallTotalExclGST(newOverallTotalExclGST);
    };

    const handleFieldChange = (index, field, value, values) => {
        const updatedLineItems = [...values.materials];
        updatedLineItems[index][field] = value;

        const basePrice = parseFloat(updatedLineItems[index].basePrice) || 0;
        const quantity = parseFloat(updatedLineItems[index].quantity) || 0;
        const discount = parseFloat(updatedLineItems[index].discount) || 0;
        const gst = parseFloat(updatedLineItems[index].gst) || 0;

        const netPrice = basePrice - (basePrice * discount) / 100;
        updatedLineItems[index].netPrice = netPrice;

        const totalExclGst = netPrice * quantity;
        updatedLineItems[index].totalExclGst = totalExclGst;

        const totalCost = calculateTotalCost(basePrice, quantity, discount, gst);
        updatedLineItems[index].totalCost = totalCost;

        const newTotalCosts = [...totalCosts];
        newTotalCosts[index] = totalCost;
        setTotalCosts(newTotalCosts);

        // Recalculate the overall total whenever material costs change
        calculateOverallTotal(newTotalCosts, miscellaneousCosts);
        calculateOverallTotalWithoutGST(updatedLineItems, miscellaneousCosts);
    };

    const calculateTransportationTotalCost = (mrp, gst) => {
        const gstValue = parseFloat(gst) || 0;
        const mrpValue = parseFloat(mrp) || 0;
        return mrpValue + (mrpValue * gstValue) / 100;
    };


    // Handler for miscellaneous cost inputs
    const handleMiscellaneousChange = (field, value, setFieldValue, values, type) => {
        // Parse the value or use an empty string if the input is empty
        const parsedValue = value === "" ? "" : parseFloat(value) || 0;

        // Clone the current miscellaneous costs from Formik values to work on
        const updatedMiscellaneousCosts = { ...values.miscellaneousCosts };

        // Update the specific field (transport, loading, etc.) with the new MRP or GST
        updatedMiscellaneousCosts[field][type] = parsedValue;

        // Calculate total cost if both MRP and GST are valid
        if (updatedMiscellaneousCosts[field].mrp !== "" && updatedMiscellaneousCosts[field].gst !== "") {
            updatedMiscellaneousCosts[field].totalCost = calculateTransportationTotalCost(
                updatedMiscellaneousCosts[field].mrp,
                updatedMiscellaneousCosts[field].gst
            );
        } else {
            updatedMiscellaneousCosts[field].totalCost = 0; // Reset total cost if inputs are incomplete
        }

        // Update Formik values and state
        setFieldValue(`miscellaneousCosts.${field}.${type}`, parsedValue);

        // Update the component state to reflect changes in the UI
        setMiscellaneousCosts(updatedMiscellaneousCosts);

        // Recalculate overall total cost
        calculateOverallTotal(totalCosts, updatedMiscellaneousCosts);
        calculateOverallTotalWithoutGST(values.materials, updatedMiscellaneousCosts);
    };

    const handleGstChange = (field, value, setFieldValue, values) => {
        handleMiscellaneousChange(field, value, setFieldValue, values, 'gst');
    };


    // Yup validation schema
    const miscellaneousSchema = Yup.object().shape({
        mrp: Yup.number().typeError("Enter a valid MRP").required("MRP is required").min(0, "Invalid MRP"),
        gst: Yup.number().typeError("Enter a valid GST").required("GST is required"),
        // totalCost: Yup.number().typeError("Total cost should be a number").required(),
    });

    const createMiscellaneousValidationSchema = (otherCharges) => {
        const schema = {};

        if (otherCharges.transport) {
            schema.transport = miscellaneousSchema;
        }
        if (otherCharges.loadingOffloading) {
            schema.loading = miscellaneousSchema;
        }
        if (otherCharges.mathadeCharges) {
            schema.mathadeCharges = miscellaneousSchema;
        }
        if (otherCharges.miscCharges) {
            schema.miscCharges = miscellaneousSchema;
        }

        return Yup.object().shape(schema);
    };

    const validationSchema = Yup.object().shape({
        materials: Yup.array().of(
            Yup.object().shape({
                basePrice: Yup.number().typeError("Enter a valid unit cost").required("Required").min(0, "Invalid cost"),
                discount: Yup.number().typeError("Enter a number without %"),
                gst: Yup.string().typeError("Enter a valid GST").required("Required").min(0, "Invalid GST"),
            })
        ),
        // miscellaneousCosts: createMiscellaneousValidationSchema(otherCharges),
    });


    // const MAX_FILE_SIZE = 10 * 1024 * 1024; 

    // useEffect(() => {
    //     if (!loading && quotation && Object.keys(quotation).length > 0) {
    //         enqueueSnackbar('Vendor quotation submitted successfully!', {
    //             variant: 'success',
    //             autoHideDuration: 3000,
    //         });
    //     }

    //     if (!loading && error) {
    //         enqueueSnackbar(`Error: ${errorMessage}`, {
    //             variant: 'error',
    //             autoHideDuration: 3000,
    //         });
    //     }
    // }, [loading, quotation, error, errorMessage, enqueueSnackbar]);

    const [currentStep, setCurrentStep] = useState(0);


    const steps = [
        "rfq info",
        "Input Fields",
        "Miscellaneous",
        "Supporting Documents",
        "Additional Information & Upload",
        "Completion"
    ];

    const handleNextStep = async (validateForm, setTouched, values) => {
        const errors = await validateForm(); // Trigger Formik validation for current step
        // Find the index of the first material with an error
        const errorIndex = errors.materials?.findIndex(error => error != null); // Check for the first non-null error object

        if (errorIndex !== -1) {
            // Set the accordion to open at the first error index
            setOpenAccordian(errorIndex);
            setTouched({
                materials: values.materials.map((material, index) =>
                    index === errorIndex ? { ...material, ...errors.materials[errorIndex] } : material
                ),
            });
        }
        const hasErrors = Object.keys(errors).length > 0; // Check if there are any errors
        if (!hasErrors) {
            // If no errors, move to the next step
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };


    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleMaterialFileUploadHandler = async (event, materialIndex, setFieldValue, values) => {
        await handleMaterialFileUpload(
            event,
            materialIndex,
            setFieldValue,
            values,
            setMaterialFilesSkeleton,
            setMaterialUploadsErrors,
            setOpenMaterialFileSizeError,
            materialFilesTotalSize
        );
    };

    const handleDeleteMaterialFileHandler = (name, event, selectedMaterialIndex, setFieldValue, values) => {
        handleDeleteMaterialFile(
            name,
            event,
            setFieldValue,
            selectedMaterialIndex,
            values,
            setMaterialFilesBase,
            setMaterialFilesTotalSize
        );
    };

    const handleDownloadMaterialExcel = async () => {
        await handleDownloadExcel(
            rfqData,
            userProfile,
            getInitialValues,
            setExcelLoading,
            setLoadingMessage
        );
    };

    const handleUploadMaterialExcel = async (event, setFieldValue, values) => {
        await handleUploadExcel(
            event,
            setFieldValue,
            values,
            rfqData,
            totalCosts,
            setExcelLoading,
            setLoadingMessage,
            enqueueSnackbar,
            calculateTotalCost,
            setTotalCosts,
            calculateOverallTotal,
            calculateOverallTotalWithoutGST
        );
    };

    return (
        <div className="max-w-7xl lg:mx-auto h-full bg-white shadow-md" style={{ fontFamily: "goldman_sans" }}>

            <Formik
                enableReinitialize={true}
                initialValues={useMemo(() => getInitialValues(rfqData, userProfile), [rfqData, userProfile])}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    const materialDetails = values.materials.map((item, index) => ({
                        id: item.id,
                        name: item.name,
                        brand: item.brand,
                        quantity: parseFloat(item.quantity),
                        basePrice: item.basePrice ? parseFloat(item.basePrice.toString().replace('Rs. ', '')) : 0,
                        discount: item.discount,
                        gst: item.gst,
                        totalCost: totalCosts[index],
                        remark: item.remark, // Add remark to submission
                        deliveryDate: item.deliveryDate, // Add deliveryDate to submission
                        attachments: item.attachments, // Add attachments to
                    }));
                    // Include miscellaneous costs in the submission
                    const submissionData = {
                        materialDetails,
                        otherCharges: values.miscellaneousCosts,
                        agreedToPaymentTerms: values.agreedToPaymentTerms,
                        counterPaymentTerms: values.counterPaymentTerms,
                        notes: values.notes,
                        selectedFilesBase: selectedFilesBase,
                        rfqId: rfqData?.rfqId,
                        rfqType: rfqData?.rfqType,
                        supplierId: rfqData?.suppliers?.[0]?.supplierId,
                        submittedBy: userProfile?.name,
                        userId: userProfile?.userId,
                    };

                    try {
                        await dispatch(submitMaterialQuotation({ values: submissionData }));
                        if (supplierId) {
                            navigate('/leads')
                        } else {
                            navigate(-1)
                        }
                        // if (isEdit) {
                        //     navigate(-1)
                        // } else {
                        //     navigate('/submitted-quotation')
                        // }
                    } catch (error) {
                        console.log("SUBMIT Error", error)
                    }

                }}
            >
                {({ values, errors, touched, setFieldValue, validateForm, setTouched }) => (
                    <Form autoComplete="off" onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                        {/*Mobile View */}
                        <MaterialMobileQuotatoinForm
                            values={values}
                            setFieldValue={setFieldValue}
                            currentStep={currentStep}
                            steps={steps}
                            openAccordian={openAccordian}
                            expandedStates={expandedStates}
                            toggleExpanded={toggleExpanded}
                            toggleAccordion={toggleAccordion}
                            handleDownloadMaterialExcel={handleDownloadMaterialExcel}
                            handleUploadMaterialExcel={handleUploadMaterialExcel}
                            rfqData={rfqData}
                            isSpecsPopupOpen={isSpecsPopupOpen}
                            togglePopupSpecs={togglePopupSpecs}
                            handleFieldChange={handleFieldChange}
                            overallTotal={overallTotal}
                            overallTotalExclGST={overallTotalExclGST}
                            totalMaterialCostsExclGST={totalMaterialCostsExclGST}
                            currentRank={currentRank}
                            setSelectedMaterialIndex={setSelectedMaterialIndex}
                            setOpenUploadFile={setOpenUploadFile}
                            isReadOnly={isReadOnly}
                            totalCosts={totalCosts}
                            visibleMiscellaneousItems={visibleMiscellaneousItems}
                            handleMiscellaneousChange={handleMiscellaneousChange}
                            handleGstChange={handleGstChange}
                            attachmentsData={attachmentsData}
                            togglePopup={togglePopup}
                            isPopupOpen={isPopupOpen}
                            setIsCounterTerm={setIsCounterTerm}
                            isCounterTerm={isCounterTerm}
                            attachmentsSkeleton={attachmentsSkeleton}
                            selectedFilesBase={selectedFilesBase}
                            attachmentsUploadsErrors={attachmentsUploadsErrors}
                            openFilesSizeError={openFilesSizeError}
                            selectedFilesTotalSize={selectedFilesTotalSize}
                            setAttachmentsSkeleton={setAttachmentsSkeleton}
                            setSelectedFilesBase={setSelectedFilesBase}
                            setSelectedFilesTotalSize={setSelectedFilesTotalSize}
                            setOpenFilesSizeError={setOpenFilesSizeError}
                            setAttachmentsUploadsErrors={setAttachmentsUploadsErrors}
                        />

                        {/* desktop view */}
                        <div className="hidden lg:block">
                            {/* Header Section */}
                            <div className="grid grid-cols-3 w-full items-center lg:px-8 lg:py-1 shadow-md lg:sticky lg:top-0 bg-white z-20">
                                <div >
                                    <div className="lg:flex items-center hidden">
                                        {/* <h1 className="text-[24px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)}</h1> */}
                                        {/* <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                        <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-sm bg-[#E9E9E9] cursor:pointer px-4 py-1 my-1 text-[#434343]">Back</button>
                                    </div>
                                </div>
                                {/* <div className="flex justify-center items-center lg:items-start lg:justify-start my-4">
                                    <span class="relative flex items-center justify-center h-9 w-9 ">
                                     <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-75"></span>
                                     <span class="relative inline-flex rounded-full h-[18px] w-[18px] bg-lime-500"></span>
                                     </span>

                                    <div className="lg:w-9 lg:h-9 w-6 h-6 mx-3 flex justify-center items-center rounded-full bg-[#DFF1C8] opacity-95">
                                        <div className="lg:w-[18px] lg:h-[18px] w-3 h-3 rounded-full bg-lime-500"></div>
                                    </div>
                                    </div> */}
                                {/* <div className="flex items-center justify-center space-x-2">
                                    <span class="relative flex items-center justify-center h-4 w-4 ">
                                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                        <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                    </span>
                                    <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                        Quotation Rank:{" "}
                                        <span className="font-bold text-black">
                                            {currentRank === "L1"
                                                ? "L2"
                                                : currentRank === undefined || currentRank === null
                                                    ? "Quotation not submitted"
                                                    : currentRank}
                                        </span>
                                    </h3>
                                    <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p>
                                </div> */}
                            </div>
                            <div className="bg-[#F6F6F4]">
                                {/* rfq information */}
                                <RfqInformation 
                                rfqData={rfqData}
                                togglePopup={togglePopup}
                                isPopupOpen={isPopupOpen}
                                />
                             <div className="lg:px-8 lg:space-y-6 pb-8">
                                

                                {/* Material Section */}
                                <div>
                                    {/* line items label */}
                                    {/* <h3 className="text-xl pb-2 font-semibold text-[#434343] hidden lg:block">Line Items</h3> */}
                                    {/* material */}
                                    <div className="lg:flex items-center hidden">
                                        <h3 className="whitespace-nowrap text-[#434343] uppercase text-sm font-semibold">Material List ({rfqData?.materials?.length || 0} {rfqData?.materials?.length === 1 ? "item" : "items"})
                                        </h3>
                                        <hr className="flex-grow border-t border-gray-300 mx-4" />
                                        <div className="flex justify-between items-center my-1">
                                        {/* <p className="text-xs font-medium text-[#434343] hidden lg:block">
                                            Your bid value is the sum of these line items
                                        </p> */}
                                        {
                                            <div className="flex flex-col items-end space-y-2">
                                                <div className="flex space-x-4">
                                                    <button
                                                        type="button"
                                                        onClick={handleDownloadMaterialExcel}
                                                        className="bg-blue-500 text-white px-3 py-1 text-xs"
                                                    >
                                                        Download Excel
                                                    </button>

                                                    <label className="border border-gray-800 px-3 py-1 cursor-pointer text-xs">
                                                        Upload Excel
                                                        <input
                                                            type="file"
                                                            accept=".xlsx"
                                                            onChange={(e) => handleUploadMaterialExcel(e, setFieldValue, values)}
                                                            className="hidden"
                                                        />
                                                    </label>
                                                </div>
                                                <p className="text-[10px] italic text-gray-500">Note: Download Excel to upload material list</p>
                                            </div>
                                        }
                                    </div>
                                    </div>

                                    

                                    <div className="hidden lg:grid lg:grid-cols-40 gap-2  self-end  text-[#777777] text-sm font-normal border-y py-2">
                                        <label className="block font-medium col-span-1 text-xs">Sr#</label>
                                        <label className="block font-medium col-span-3 text-xs">Material</label>
                                        <label className="block font-medium col-span-3 text-xs">Brands</label>
                                        <label className="block font-medium col-span-3 text-xs">Qty.</label>
                                        <label className="block font-medium col-span-6 text-xs">Specifications</label>
                                        <label className="font-medium text-xs col-span-2 flex items-center justify-center relative">
                                            MRP
                                            <span className="bg-[#cac9c9] text-white ml-1 text-[10px] h-[10px] w-[10px] flex justify-center items-center mb-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                i
                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                    Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                </span>
                                            </span>
                                        </label>
                                        <label className="font-medium text-xs col-span-2 text-right self-start items-center flex relative">
                                            Disc(%)
                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-[2px] h-[10px] w-[10px] flex justify-center items-center mb-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                i
                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[220px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                    Specify the discount percentage or amount offered on this material. This discount will be applied to the unit cost and reflected in the final pricing
                                                </span>
                                            </span>
                                        </label>
                                        <label className="block font-medium text-center col-span-2 text-xs">GST(%)</label>
                                        <label className="font-medium flex self-start items-center justify-end col-span-3 text-xs">Item Rate
                                            <span className="bg-[#cac9c9] text-white text-[10px] ml-1 h-[10px] w-[10px] flex justify-center items-center mb-1 pt-1 rounded-full relative group hover:cursor-pointer">
                                                i
                                                <span className="absolute bottom-[130%] text-xs left-1/2 whitespace-nowrap  transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                    ( MRP - Disc )</span>
                                            </span></label>
                                        <label className="block font-medium col-span-4 text-right text-xs">Total (Excl. GST)</label>
                                        <label className="block font-medium col-span-9 text-center text-xs pl-2">Remarks</label>
                                        {/* <label className="block text-center col-span-4 font-medium text-xs">Delivery Date</label> */}
                                        <label className="block font-medium col-span-2 text-center text-xs">Attach.</label>
                                    </div>
                                    <div className="lg:space-y-6 space-y-6 pt-4 my-4 lg:my-0">
                                        {rfqData?.materials.map((row, index) => (
                                            <div key={index}>
                                                {/* desktop */}
                                                <div className="hidden lg:grid lg:grid-cols-40 gap-2 text-[#373737]">

                                                    <p className="text-xs col-span-1 whitespace-pre-line">{index + 1}</p>

                                                    <p className="text-xs col-span-3 whitespace-pre-line">{ReactHtmlParser(row.name)}</p>

                                                    <p className="text-xs col-span-3 break-words whitespace-pre-line">{row?.brands.join(", ")}</p>
                                                    <div className="relative group col-span-3">
                                                        <p className="text-xs cursor-pointer mr-1 break-words">
                                                            {row.quantity} {getFormattedUnit(row.unit)}
                                                        </p>
                                                    </div>

                                                    <div className="col-span-6 min-h-fit">
                                                         {row?.specifications?.length > 0 && (
                                                            <div className="col-span-4 text-xs">
                                                                <p className="font-light whitespace-pre-line">
                                                                     {ReactHtmlParser(
                                                                        expandedStates[index]
                                                                         ? row?.specifications
                                                                         : row?.specifications?.substring(0, 160) +
                                                                         (row?.specifications?.length > 160
                                                                          ? "..."
                                                                         : "")
                                                                       )}
                                                                 </p>
                                                               {row?.specifications?.length > 160 && (
                                                              <button
                                                            type="button"
                                                             className="text-[#8E8E85] text-xs underline italic"
                                                              onClick={() => toggleExpanded(index)}
                                                              >
                                                              {expandedStates[index] ? "Read Less" : "Read More"}
                                                               </button>
                                                               )}
                                                            </div>
                                                            )}
                                                    </div>
    
                                                    <div className="col-span-2 flex flex-col">
                                                        <Field
                                                            name={`materials[${index}].basePrice`}
                                                            type="text"
                                                            className={`lg:w-full w-[220px] h-[30px] focus:ring-0 p-1 text-right text-xs border-[#DCDCD0] border ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => handleFieldChange(index, 'basePrice', e.target.value, values)}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].basePrice`} component="div" className="text-red-500 text-xs " />
                                                    </div>

                                                    <div className="col-span-2 justify-self-center flex flex-col">
                                                        <Field
                                                            name={`materials[${index}].discount`}
                                                            type="text"
                                                            className={`w-[40px] p-1 text-xs text-right focus:ring-0 h-[30px] border-[#DCDCD0] border ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => handleFieldChange(index, 'discount', e.target.value, values)}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].discount`} component="div" className="text-red-500 text-xs" />
                                                    </div>

                                                    <div className="col-span-2  w-[50px]">
                                                        <CustomGstDropdown
                                                            options={[0, 5, 12, 18, 28]}
                                                            value={values?.materials[index]?.gst}  // Display the current value
                                                            onChange={(value) => handleFieldChange(index, 'gst', value, values)}
                                                            className={`w-full  text-xs h-[30px]`}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].gst`} component="div" className="text-red-500 text-xs h-fit" />
                                                    </div>

                                                    <p className=" col-span-3 text-right mt-3  text-xs">{new Intl.NumberFormat('en-IN').format(values.materials[index].basePrice - (values.materials[index].basePrice * values.materials[index].discount) / 100) || 0}</p>
                                                    <p className=" col-span-4 text-right mt-3  text-xs">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.materials[index].totalExclGst || 0)}</p>

                                                    {/* <div className="col-span-2 flex flex-col">
                                                        <Field
                                                            as="textarea"
                                                            name={`materials[${index}].remark`}
                                                            rows={1}
                                                            className={`lg:w-full min-h-[40px] text-xs border-[#DCDCD0] border px-2 py-1 resize-none overflow-hidden h-auto ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => {
                                                                handleFieldChange(index, 'remark', e.target.value, values);

                                                                // Adjust the textarea height
                                                                e.target.style.height = "auto"; // Reset height first
                                                                e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on content
                                                            }}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].remark`} component="div" className="text-red-500 text-xs" />
                                                    </div> */}
                                                    <div className="col-span-9 mx-2 min-h-[30px]">
                                                        <style>
                                                            {`
                                                                        /* Remove padding from the ReactQuill editor's container */
                                                                        .no-padding .ql-container {
                                                                        padding: 4px !important;
                                                                        border: none !important;
                                                                      }
                                                                       .no-padding .ql-editor {
                                                                       padding: 4px !important;
                                                                       font-size: 0.75rem !important; /* Equivalent to Tailwind's text-xs */
                                                                       line-height: 1rem; /* Optional: Adjust line height for better readability */
                                                                     }
                                                                  `}
                                                        </style>
                                                        <ReactQuill
                                                            value={values?.materials?.[index]?.remark || ''}
                                                            name={`materials[${index}].remark`}
                                                            onChange={(value) => {
                                                                handleFieldChange(index, 'remark', value, values);

                                                                // Adjust the textarea height
                                                                // e.target.style.height = "auto"; 
                                                                // e.target.style.height = `${e.target.scrollHeight}px`; 
                                                            }}
                                                            disabled={isReadOnly && true}
                                                            theme="snow"
                                                            className="min-h-[30px] max-h-[80px] text-xs word-break whitespace-normal p-0 py-0 px-0 focus:outline-none focus:ring-0 bg-white border-[#DCDCD0] border w-full overflow-y-auto no-padding" // Set min and max height
                                                            modules={{ toolbar: false }}
                                                        // style={{ padding: "-10px" }}
                                                        /><ErrorMessage
                                                            name={`materials[${index}].remark`} component="div" className="text-red-500 text-xs" />
                                                    </div>

                                                    {/* <div className="col-span-4 ">
                                                        <Field
                                                            type="date"
                                                            id={`deliveryDate-${index}`}
                                                            name={`materials[${index}].deliveryDate`}
                                                            className={`shadow-sm block text-xs h-[30px] px-2 lg:w-full border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1 ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => handleFieldChange(index, 'deliveryDate', e.target.value, values)} // Ensure you have this function defined
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage
                                                            name={`materials[${index}].deliveryDate`}
                                                            component="div"
                                                            className="text-red-500 text-xs"
                                                        />
                                                    </div> */}

                                                    <div className=" col-span-2 relative text-center mt-2">
                                                        <AttachFileIcon
                                                            className="hover:cursor-pointer"
                                                            sx={{
                                                                width: "0.7em", ml: "2px",
                                                                transform: 'rotate(45deg)',
                                                                transition: 'transform 0.3s ease'
                                                            }}
                                                            onClick={() => {
                                                                setSelectedMaterialIndex(index);
                                                                setOpenUploadFile(true);
                                                            }}
                                                        />

                                                        {values.materials[index]?.attachments?.length > 0 && (
                                                            <span
                                                                className="absolute top-0 right-4 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                style={{ transform: 'translate(50%, -50%)' }}
                                                            >
                                                                {values.materials[index].attachments.length}
                                                            </span>
                                                        )}
                                                    </div>

                                                </div>

                                                {index < rfqData?.materials?.length - 1 && <hr className="hidden lg:block  lg:mt-4" />}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {hasVisibleMiscellaneousItems && <div className="">
                                    <hr className="hidden lg:block" />
                                    <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                        <p className="text-[#007366] lg:text-black text-sm px-4">Sub Total</p>
                                        <p className="text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalMaterialCostsExclGST.toFixed(2))}</p>
                                    </div>
                                </div>}

                                {/* Miscellaneous Section */}
                                <div className="p-8 bg-white shadow-md">
                                    {hasVisibleMiscellaneousItems && (
                                        <div className="pb-6">
                                            <div className="hidden lg:block">
                                                <h3 className="text-sm font-semibold text-[#434343] hidden lg:block mb-3">Other Charges</h3>

                                                <div className="text-xs bg-white border py-4 shadow-md lg:border-none lg:mx-0 lg:shadow-none lg:py-0">
                                                    <div className="mb-3 hidden lg:grid lg:grid-cols-6  items-center gap-2 text-[#777777] font-normal border-y py-2">
                                                        <label className="block font-medium col-span-3">Charges</label>
                                                        <label className="font-medium text-start col-span-1 flex justify-center items-center relative">
                                                            MRP
                                                            <span className="bg-[#cac9c9] text-white text-[10px] h-[10px] w-[10px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-default">
                                                                i
                                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                    Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <label className="block font-medium text-center col-span-1">GST (%)</label>
                                                        <label className="block font-medium text-right col-span-1">Total Cost</label>
                                                    </div>

                                                    {/* Render visible miscellaneous items */}
                                                    {visibleMiscellaneousItems.map((item) => (
                                                        <div key={item.name} className="lg:grid lg:grid-cols-6 mb-8 lg:mb-0 items-center">
                                                            <label className="block col-span-3 font-semibold lg:font-medium ">
                                                                {item.label}
                                                            </label>

                                                            {/* MRP Input Field */}
                                                            <div className="col-span-1 w-full">
                                                                <Field
                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                    type="text"
                                                                    className={`w-fit hidden focus:ring-0 lg:block text-right lg:w-full h-[30px] text-xs placeholder-[#373737] p-2 border border-gray-300 ${isReadOnly && "bg-gray-100"}`}
                                                                    // placeholder="Rs."
                                                                    onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                                    disabled={isReadOnly && true}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs mt-1"
                                                                />
                                                            </div>

                                                            {/* GST Dropdown */}
                                                            <div className="col-span-1 lg:flex hidden justify-center h-fit lg:w-full w-[262px]">
                                                                <CustomGstDropdown
                                                                    options={[0, 5, 12, 18, 28]}
                                                                    value={values.miscellaneousCosts[item.name]?.gst}
                                                                    onChange={(value) => handleGstChange(item.name, value, setFieldValue, values)}
                                                                    className="lg:w-[64px] w-[262px] justify-between h-[30px]"
                                                                    disabled={isReadOnly && true}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.gst`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs mt-1"
                                                                />
                                                            </div>

                                                            {/* Total Cost */}
                                                            <div className="flex justify-between lg:justify-end items-center border border-t-[#004491] py-3 px-4 bg-[#E7EAEF] lg:border-none lg:bg-white">
                                                                <p className="text-[#004491] font-bold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                    Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.miscellaneousCosts[item.name]?.mrp) || '0'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Total Amount Section */}
                                    <div className="">
                                        <hr className="hidden lg:block" />
                                        <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                            <p className="text-[#007366] lg:text-black text-sm">Total Basic Amount</p>
                                            <p className="text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(overallTotalExclGST.toFixed(2))}</p>
                                        </div>
                                    </div>

                                    {/* GST Amount Section */}
                                    <div className="">
                                        <hr className="hidden lg:block" />
                                        <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                            <p className="text-[#007366] lg:text-black text-sm">GST Total</p>
                                            <p className="text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((overallTotal - overallTotalExclGST).toFixed(2))}</p>
                                        </div>
                                    </div>

                                    {/* Total Quotation Value Amount Section */}
                                    <div className="">
                                        <hr className="hidden lg:block" />
                                        <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                            <p className="text-[#007366] lg:text-black text-sm">Total Quotation Value (inc. GST)</p>
                                            <p className="bg-[#E0F0EE] text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(overallTotal.toFixed(2))}</p>
                                        </div>
                                    </div>
                                </div>



                                {/* Supporting Documents Section */}
                                {attachmentsData.length > 0 && (
                                    <div className="mb-8 hidden lg:block bg-white p-8 shadow-md">
                                        <>
                                            <h3 className="text-sm font-semibold text-[#434343] hidden lg:block">Supporting Documents</h3>
                                            <p className="text-xs font-normal text-[#9D9D9D] "> <span className="font-bold text-gray-500">Note: </span>  Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                            <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 mx-auto">
                                                {attachmentsData.map((doc, index) => (
                                                    <div key={index} className="flex justify-between items-center mx-2">
                                                        <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                            {/* Dynamic icon based on file type */}
                                                            <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                            {/* Document name now clickable */}
                                                            <a
                                                                href={doc.Content}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                download={doc.Name}
                                                                className="text-xs text-[#434343] hover:underline"
                                                            >
                                                                {doc.Name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    </div>
                                )}



                                {/* Additional Information Section */}
                                <div className="mb-8 text-sm shadow-md bg-white p-8">

                                    {/* <h3 className="text-sm font-semibold text-[#434343] hidden lg:block ">Additional Information</h3> */}

                                    <div className="hidden space-x-8 lg:flex items-center w-full">
                                        <p className=" text-sm ">
                                            Below are our payment terms. Do you agree?
                                        </p>
                                        <div className="flex space-x-4">
                                            <label className="inline-flex items-center">
                                                <Field
                                                    type="radio"
                                                    name="agreedToPaymentTerms"
                                                    value="true"
                                                    checked={values.agreedToPaymentTerms === true} // Controlled check status
                                                    onChange={() => {
                                                        setFieldValue('agreedToPaymentTerms', true);
                                                        setIsCounterTerm(false); // Update counter term
                                                    }}
                                                    className="form-radio"
                                                />
                                                <span className="ml-2 mt-1 text-sm">Yes</span>
                                            </label>

                                            <label className="inline-flex items-center">
                                                <Field
                                                    type="radio"
                                                    name="agreedToPaymentTerms"
                                                    value="false"
                                                    checked={values.agreedToPaymentTerms === false} // Controlled check status
                                                    onChange={() => {
                                                        setFieldValue('agreedToPaymentTerms', false);
                                                        setIsCounterTerm(true); // Update counter term
                                                    }}
                                                    disabled={isReadOnly}
                                                    className="form-radio"
                                                />
                                                <span className="ml-2 mt-1 text-sm">Counter Offer</span>
                                            </label>
                                        </div>
                                        <ErrorMessage name="agreedToPaymentTerms" component="div" className="text-red-500 text-xs" />
                                    </div>

                                    <div>
                                        {isCounterTerm && <div className="mt-4 mx-4 lg:mx-0">
                                            <p className=" text-sm mb-1 text-[#4F4F42]">Counter Payment Terms</p>
                                            <Field
                                                as="textarea"
                                                name="counterPaymentTerms"
                                                // className={`lg:w-[390px] w-full h-[90px] text-xs placeholder:text-xs border text-gray-500 border-gray-300 placeholder:italic 
                                                //     ${values.agreedToPaymentTerms === true || isReadOnly ? 'bg-gray-200  ' : 'bg-white'}`}
                                                className=" w-full h-[90px] text-xs focus:ring-0 placeholder:text-xs border text-gray-500 border-gray-300 placeholder:italic "

                                                placeholder="Please write your counter payment terms "
                                            // disabled={values.agreedToPaymentTerms === true || isReadOnly && true}
                                            />
                                        </div>}
                                    </div>

                                    <div className=" mt-4 mx-4 lg:mx-0">
                                        <p className="text-[#434343] pb-2 lg:mb-0 ">Any Remark for Client?</p>
                                        <Field
                                            as="textarea"
                                            name="notes"
                                            placeholder="Add Notes (Optional) "
                                            className={`border-[#CDCDB8] w-full focus:ring-0 placeholder:text-xs text-xs lg:w-full h-[120px] placeholder:italic placeholder-[#8C8C8C] ${isReadOnly && "bg-gray-100"}`}
                                            disabled={isReadOnly && true}
                                        />
                                    </div>
                                </div>

                                {/* Upload Supporting Documents */}
                                <div className="hidden lg:block bg-white p-8 shadow-md">
                                    <h3 className="whitespace-nowrap  text-[#434343] text-sm font-semibold lg:mb-[15px]">Upload Supporting Documents</h3>

                                    {attachmentsSkeleton.length > 0 && (
                                        <p className="text-xs pl-3 ">
                                            Your file is being scanned for upload, be patient and continue to fill
                                            the rest of the form.
                                        </p>
                                    )}

                                    <div className="px-3">
                                        {/* Display the selected file names */}
                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                            {selectedFilesBase.map((file, index) => (
                                                <a
                                                    href={file.Content}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                    key={file.Name}
                                                >
                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                        {file.Name}
                                                    </span>
                                                    <span
                                                        className="ml-1 cursor-pointer"
                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event, setSelectedFilesBase, setSelectedFilesTotalSize)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 48 48"
                                                        >
                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                            <line
                                                                x1="15"
                                                                y1="15"
                                                                x2="33"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                            <line
                                                                x1="33"
                                                                y1="15"
                                                                x2="15"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                        </svg>
                                                    </span>
                                                </a>
                                            ))}

                                            {/* Skeleton loaders for files being processed */}
                                            {attachmentsSkeleton.map((_, index) => (
                                                <Skeleton
                                                    key={index}
                                                    variant="rounded"
                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                    width={150}
                                                    height={32}
                                                />
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        {attachmentsUploadsErrors && (
                                            <div className="mt-3">
                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                    {attachmentsUploadsErrors}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    {/* Error handling for file size */}
                                    <div
                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                            }`}
                                    >
                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                            <p>Total size of attachments is not more than 10 MB</p>
                                            <button
                                                onClick={handleCloseFileSizeError}
                                                className="px-4 py-2 focus:outline-none"
                                            >
                                                <svg
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    {/* drag and drop file */}
                                    {!isReadOnly && <div className="lg:ml-14 lg:mr-0 hidden mx-4 lg:flex items-center justify-center h-[175px] lg:h-[180px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                        <input
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            onClick={handleFileUploadClick}
                                            onChange={(event) =>
                                                handleFileUpload(
                                                    event,
                                                    selectedFilesTotalSize,
                                                    setAttachmentsSkeleton,
                                                    setSelectedFilesBase,
                                                    setSelectedFilesTotalSize,
                                                    setOpenFilesSizeError,
                                                    setAttachmentsUploadsErrors
                                                )
                                            }
                                            multiple // Allow multiple files to be selected
                                        />
                                        <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500 pointer-events-none">
                                            <div className="relative mx-auto hidden lg:block">
                                                {/* Overlapping rectangles */}
                                                <div className="absolute top-10 -left-8 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                <div className="absolute top-2 left-0 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                            </div>
                                            {/* Centered text */}
                                            <p className="mt-32 text-center text-sm hidden lg:block">Drag & Drop or Click to upload your proposal instead</p>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="sticky bottom-0 z-20">
                            {/* action buttons for mobile */}
                            <div
                                style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}
                                className="lg:hidden flex justify-end p-4 space-x-8 bg-white "
                            >
                                {currentStep === 0 ? (
                                    <div className="space-x-4">
                                        {/* {currentStep === 0 && (<div className="lg:flex items-center sticky top-0 shadow-md px-4 py-2 "> */}
                                        {/* <h1 className="text-[24px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)}</h1> */}
                                        {/* <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                        <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-xs bg-[#E9E9E9] cursor:pointer px-4 py-2 text-[#434343]">Back</button>

                                        <button
                                            type="button"
                                            className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                            onClick={() => setCurrentStep(currentStep + 1)}
                                        >
                                            Start Quotation
                                        </button>
                                    </div>
                                ) : (
                                    currentStep > 0 && (
                                        <button
                                            type="button"
                                            className="underline text-xs"
                                            onClick={handlePreviousStep}
                                        >
                                            Back
                                        </button>
                                    )
                                )}

                                {/* Show "Next" button for all steps except "Additional Information & Upload" and "Completion" */}
                                {currentStep > 0 && currentStep < steps.length - 2 && (
                                    <button
                                        type="button"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        onClick={() => handleNextStep(validateForm, setTouched, values)}
                                    >
                                        Next
                                    </button>
                                )}

                                {/* Show "Submit" button on "Additional Information & Upload" step */}
                                {currentStep === steps.length - 2 && (
                                    <button
                                        type="submit"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </button>
                                )}

                                {/* No buttons displayed on "Completion" step */}
                            </div>
                            {/* action buttons for desktop */}
                            {!isReadOnly && <div style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }} className="bg-white hidden space-x-4 lg:flex justify-end items-center py-3 lg:px-8 px-4">
                                <button type="button" onClick={handleCloseForm} className="border border-[#1E1E08] px-3 py-2 w-[120px] text-sm text-[#434343] font-bold">
                                    Cancel
                                </button>
                                <button type="submit" disabled={loading} className="bg-black text-sm text-white px-3 py-2 flex justify-center items-center">
                                    {loading ? "Submitting..." : isEdit ? "Update Quotation" : "Submit Quotation"}
                                </button>
                            </div>}
                        </div>



                        <StaticPopup
                            openModal={openUploadFile}
                            handleClose={() => setOpenUploadFile(false)}
                            size={"sm"}
                            width={"xl"}
                            bodyStyle="pb-0 pt-0"
                            className="px-0"
                            header={"Upload Supporting Documents"}
                        >
                            <FileUpload
                                handleFileUploadClick={handleMaterialFileUploadClick}
                                handleFileUpload={(event) => handleMaterialFileUploadHandler(event, selectedMaterialIndex, setFieldValue, values)}
                                handleDeleteSelectedFile={handleDeleteMaterialFileHandler}
                                selectedFilesBase={values.materials[selectedMaterialIndex]?.attachments || []} // Get current attachments
                                attachmentsSkeleton={materialFilesSkeleton}
                                attachmentsUploadsErrors={materialUploadsErrors}
                                openFilesSizeError={openMaterialFileSizeError}
                                handleCloseFileSizeError={handleCloseMaterialFileSizeError}
                                selectedMaterialIndex={selectedMaterialIndex}
                                isReadOnly={isReadOnly}
                            />
                        </StaticPopup>
                    </Form>
                )}
            </Formik>


            <LoadingModal
                isOpen={excelLoading || excelError !== null}
                onClose={() => setExcelError(null)} // Close the modal on click
            >
                {excelLoading ? (
                    <div className="flex flex-col items-center justify-center">
                        <div className="animate-spin rounded-full border-4 border-t-4 border-orange-500 border-t-gray-200 h-8 w-8 mb-4"></div>
                        <p className="text-xl font-medium text-gray-600 italic">{loadingMessage}</p>
                    </div>
                ) : (
                    <div>
                        <p className="text-red-500 font-medium">{excelError || "Operation completed successfully!"}</p>
                    </div>
                )}
            </LoadingModal>

            <StaticPopup
                openModal={openSpecsPopup}
                handleClose={closeSpecsPopup}
                width={"xl"}
                bodyStyle="pb-0 pt-0"
                className="px-0"
                header={"Specifications"}
            >
                <div className="py-4">
                    <p>{selectedSpecs}</p> {/* Display the full specifications */}
                </div>
            </StaticPopup>


        </div>
    )
}

export default MaterialQuotationForm
