import React from "react";
import { Modal } from "flowbite-react";

const  StaticPopup = ({
  openModal,
  handleClose,
  header,
  headerSub,
  children,
  height = 60,
  width = "xl",
  size = "lg",
  headerLogo,
  bodyStyle = "pb-8 pt-3",
}) => {
  return (
    <div>
      <Modal
        style={{ zIndex: "50" }}
        dismissible
        show={openModal}
        onClose={handleClose}
        size={width}
      >
        <div
          className={`flex justify-between ${
            size === "sm" ? "pl-5" : "pl-5"
          } pr-4 py-2 items-center border-b-[1px] border-gray-200`}
        >
          <div className="flex gap-3 items-center text-sm">
            {headerLogo && (
              <div className="max-h-6">
                {/* <div dangerouslySetInnerHTML={{ __html: headerLogo }} /> */}
                {headerLogo}
              </div>
            )}
            <p className="font-bold text-sm text-[#3F4B64]">
              {header}
              {headerSub && (
                <span className="text-sm font-extralight ml-1 italic">
                  {`( ${headerSub} )`}
                </span>
              )}
            </p>
          </div>
          <button
            className="hover:bg-slate-100 text-sm text-[#5B6882] flex gap-2 items-center px-2  rounded-lg"
            onClick={handleClose}
          >
            Close
            <svg
              width="8"
              height="8"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L11 11M11 1L1 11"
                stroke="#232E9B"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>
        <div
          className={` ${bodyStyle}${
            size === "sm" ? "px-4 " : " px-4"
          } flex flex-col px-6 gap-6 min-h-[${height}] overflow-auto text-sm`}
        >
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default StaticPopup;
