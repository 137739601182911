import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuItemsList from "./MenuItemsList";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "flowbite-react";
import { signout } from "actions/authActions";
import WarningPopup from "components/WarningPopup";

const SidePanel = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sidePanelRef = useRef(null);
  const business = useSelector((state) => state.business);
  const profile = business?.profile;
  // console.log("hasbusiness", profile);

  const hasBusiness = profile?.isActive

  const auth = useSelector((state) => state.auth);
  const [openSlider, setOpenSlider] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openLogoutSlider, setOpenLogoutSlider] = useState(false);
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentPage = location.pathname;
  const basePath = "/" + currentPage.split("/")[1]; // "/rfqs/789" convert into /rfqs so btn click is on focus
  const menuItems = MenuItemsList;

  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;

  const repeatRfqFeature = user?.userEntity?.features?.some(
    (feature) => feature.featureName === "REPEAT_ORDER" && feature.hasFeature === true
  ) || false;
  // console.log("USERENTITY", repeatRfqFeature)

  function getInitials(name) {
    // Split the name into words
    const words = name?.split(" ");

    // Extract the first letter of each word
    const initials = words?.map((word) => word?.charAt(0)?.toUpperCase());

    // Join the initials together
    return initials?.join("");
  }

  const handleClickOnProfile = () => {
    setOpenLogoutSlider(!openLogoutSlider);
  };

  const handleCloseWarning = () => {
    navigate("/");
  };
  const handleChangeSliderValue = () => {
    setOpenSlider(!openSlider);
  };
  const handleCreateRegularRfq = () => {
    navigate("/rfqs/create");
    setOpenSlider(false);
  };
  const handleCreateRepeatRfq = () => {
    navigate("/repeated-orders/create");
    setOpenSlider(false);
  };
  const handleLogout = async () => {
    setOpenLogoutSlider(false);
    setLoading(true);
    try {
      await dispatch(signout());
      navigate("/");
      setLoading(false);
    } catch (error) { }
  };

  useEffect(() => {
    if (entityId) {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1028);
      };

      window.addEventListener("resize", handleResize);
      handleResize(); // Check initial screen size

      return () => window.removeEventListener("resize", handleResize);
    } else {
      if (user?.userProfile?.userType === "buyer") {
        navigate("/");
      }
    }
  }, []);

  const handleClosePopup = () => {
    navigate("/");
  };


  // Close the side panel when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidePanelRef.current && !sidePanelRef.current.contains(event.target)) {
        setOpenSlider(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidePanelRef]);

  const vendorSidepanelOptions = [
    // {
    //   to: "/leads",
    //   text: "Leads",
    //   icon: (
    //     <img
    //       src="https://storagereponeevaydevcdn.blob.core.windows.net/business/search_leads.svg"
    //       alt="Business Leads Icon"
    //       fill="none"
    //       className="w-6 h-6 fill-white"
    //     />
    //   ),
    // },
    // {
    //   to: "/submitted-quotation",
    //   text: "Submitted Quotation",
    //   icon: (
    //     <img
    //       src="https://storagereponeevaydevcdn.blob.core.windows.net/business/checked_sidepanel_leads.png"
    //       alt="Business Leads Icon"
    //       className="w-6 h-6"
    //     />
    //   ),
    // },
    // {
    //   to: "/my-business",
    //   text: "My Business",
    //   icon: (
    //     <img
    //       src="https://storagereponeevaydevcdn.blob.core.windows.net/business/my_business_leads.svg"
    //       alt="Business Leads Icon"
    //       className="w-6 h-6"
    //     />
    //   ),
    // },
    {
      to: "/leads",
      text: "Leads",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 fill-white"
          focusable="false"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.319 14.4326C20.7628 11.2941 20.542 6.75347 17.6569 3.86829C14.5327 0.744098 9.46734 0.744098 6.34315 3.86829C3.21895 6.99249 3.21895 12.0578 6.34315 15.182C9.22833 18.0672 13.769 18.2879 16.9075 15.8442C16.921 15.8595 16.9351 15.8745 16.9497 15.8891L21.1924 20.1317C21.5829 20.5223 22.2161 20.5223 22.6066 20.1317C22.9971 19.7412 22.9971 19.1081 22.6066 18.7175L18.364 14.4749C18.3493 14.4603 18.3343 14.4462 18.319 14.4326ZM16.2426 5.28251C18.5858 7.62565 18.5858 11.4246 16.2426 13.7678C13.8995 16.1109 10.1005 16.1109 7.75736 13.7678C5.41421 11.4246 5.41421 7.62565 7.75736 5.28251C10.1005 2.93936 13.8995 2.93936 16.2426 5.28251Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      to: "/my-business",
      text: "My Business",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 fill-white"
          focusable="false"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3m-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3m0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5m8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5"
            fill="currentColor"
          />
        </svg>
      ),
    },
    // {
    //   to: "/register/user",
    //   text: "Register Here",
    //   icon: (
    //     <img
    //       src="https://storagereponeevaydevcdn.blob.core.windows.net/business/my_business_leads.svg"
    //       alt="Business Leads Icon"
    //       className="w-6 h-6"
    //     />
    //   ),
    // },
  ]

  return (
    <div className="flex h-screen bg-gray-100">
      {isSmallScreen && user?.userType === "buyer" ? (
        <div
          onClick={handleClosePopup}
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              This page is not optimized for small screens
            </h2>
            <p className="text-sm">
              Please view this page on a larger device for the best experience.
            </p>
            <button
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handleClosePopup}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <>
          <aside
            id="sidebar-double"
            className="flex z-40 h-full transition-transform -translate-x-full sm:translate-x-0 relative"
            aria-label="Sidebar"
            ref={sidePanelRef}
          >
            <div className="overflow-y-hidden z-30 py-2  min-w-16 h-full bg-gray-800 border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between">
              <div>
                {/* Neevay logo */}
                <img
                  src="https://storagereponeevaydevcdn.blob.core.windows.net/business/NEEVAY_logo_new_smallscreen.svg"
                  className="pl-2 h-14 m-auto"
                  alt="company Logo"
                />

                {/* + button */}
                <ul className="space-y-2">
                  {/* Show + button only for buyers (with or without business) */}
                  {/* {user?.userType === "buyer" && (
                    <div className="w-full flex justify-center p-1">
                      <button
                        onClick={handleChangeSliderValue}
                        className="w-10 h-10 bg-blue-600 hover:bg-blue-700 flex items-center justify-center rounded-xl"
                      >
                        <span className="text-white font-medium text-2xl">+</span>
                      </button>
                    </div>
                  )} */}

                  {/* Buyer Options */}
                  {(user?.userType === "buyer" && !hasBusiness) &&
                    menuItems
                      .filter((item) => repeatRfqFeature || item.to !== "/repeated-orders") // Filter logic for buyers
                      .map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.to}
                            className={`flex max-w-[84px] items-center flex-col p-2 transition duration-75 hover:text-gray-100 hover:bg-gray-600 ${basePath === item.to ? "text-gray-100 bg-gray-600" : "text-gray-400"
                              }`}
                          >
                            {item.icon}
                            {item.to === "/company-directory" ? (
                              <p className="text-center text-xs">Internal Vendors</p>
                            ) : (
                              <p className="text-center text-xs">{item.text}</p>
                            )}
                          </Link>
                        </li>
                      ))}

                  {/* Buyer + Vendor Options (Buyer with Business) */}
                  {(user?.userType === "buyer" && hasBusiness) && (
                    <>
                      {menuItems
                        .filter((item) => repeatRfqFeature || item.to !== "/repeated-orders")
                        .map((item, index) => (
                          <li key={index}>
                            <Link
                              to={item.to}
                              className={`flex max-w-[84px] items-center flex-col p-1 transition duration-75 hover:text-gray-100 hover:bg-gray-600 ${basePath === item.to ? "text-gray-100 bg-gray-600" : "text-gray-400"
                                }`}
                            >
                              {item.icon}
                              <p className="text-center text-xs">{item.text}</p>
                            </Link>
                          </li>
                        ))}
                      {vendorSidepanelOptions.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.to}
                            className={`flex max-w-[84px] items-center flex-col p-1 transition duration-75 hover:text-gray-100 hover:bg-gray-600 ${basePath === item.to ? "text-gray-100 bg-gray-600" : "text-gray-400"
                              }`}
                          >
                            {item.icon}
                            <p className="text-center text-xs">{item.text}</p>
                          </Link>
                        </li>
                      ))}
                    </>
                  )}

                  {/* Vendor Options */}
                  {user?.userType === "vendor" && (
                    vendorSidepanelOptions.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.to}
                          className={`flex max-w-[84px] items-center flex-col p-2 transition duration-75 hover:text-gray-100 hover:bg-gray-600 ${basePath === item.to ? "text-gray-100 bg-gray-600" : "text-gray-400"
                          }`}
                        >
                          {item.icon}
                          <p className="text-center text-xs">{item.text}</p>
                        </Link>
                      </li>
                    ))
                  )}
                </ul>
              </div>

              {/* New Menu Item */}


              <div
                onMouseLeave={() => setOpenLogoutSlider(false)}
                className="w-full"
              >
                <div
                  className="flex w-full relative items-center justify-center p-2 text-gray-400 mt-3 transition duration-75 dark:text-gray-600 hover:text-gray-900 dark:hover:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                  onMouseEnter={handleClickOnProfile}
                >
                  <Tooltip content="Log out">
                    <div
                      onClick={() => setOpenLogoutPopup(true)}
                      className="bg-blue-700 text-white  cursor-pointer h-10 w-10 rounded-full flex items-center justify-center"
                    >
                      <p>{getInitials(user?.name)}</p>
                    </div>
                  </Tooltip>
                </div>
              </div>

            </div>
            {openSlider && (
              <div className="absolute left-full top-0 w-60 p-5 bg-white h-screen shadow-lg">
                <h5 class="text-base font-semibold mb-3">Shortcuts</h5>
                <div className="flex flex-col">
                  {/* <button
                    onClick={handleCreateRegularRfq}
                    className="flex justify-between py-3 border-b text-sm"
                  >
                    <p>Create RFQ</p>
                    <svg
                      class="w-3 h-4"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                    </svg>
                  </button> */}

                  <button
                    onClick={handleCreateRepeatRfq}
                    className="flex justify-between py-3 border-b text-sm"
                  >
                    <p>Create Repeat Orders</p>
                    <svg
                      class="w-3 h-4"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </aside>
          {user?.userProfile?.userType === "buyer" ? (
            entityId ? (
              <div className="flex-1 overflow-auto">
                {children}
              </div>
            ) : null
          ) : (
            <div className="flex-1 overflow-auto">
              {children}
            </div>
          )}

          <WarningPopup
            openModal={openLogoutPopup}
            handleClosePopup={() => setOpenLogoutPopup(false)}
            handleSubmit={handleLogout}
            warningText="Are you sure you want to log out?"
            loading={loading}
          />
        </>
      )}

    </div>
  );
};

export default SidePanel;
