import ExcelJS from "exceljs";
import { htmlToText } from "html-to-text";
import ReactHtmlParser from "react-html-parser";


// Helper function for downloading Excel
export const handleDownloadExcel = async (rfqData, userProfile, getInitialValues, setExcelLoading, setLoadingMessage) => {
    try {
        setExcelLoading(true);
        setLoadingMessage("Preparing the Excel file for download...");

        // Load the Excel template
        const templatePath = "/assets/general/bulk_template_material_rates_.xlsx";
        const response = await fetch(templatePath);
        const buffer = await response.arrayBuffer();

        // Load workbook from the buffer
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);

        // Access the specific sheet
        const sheet = workbook.getWorksheet("bulk_material_rates");

        // Use rfqData.materials and initial form values to prepare data
        const materials = getInitialValues(rfqData, userProfile).materials || [];

        // Populate the sheet starting from row 3
        materials?.forEach((material, index) => {
            const row = sheet.getRow(index + 3); // Start from row 3

            // Populate the row with material data
            row.getCell(1).value = index + 1; // Sr
            row.getCell(2).value = material.id; // Neevay_ID
            row.getCell(3).value = htmlToText(material.name); // Material_Name
            row.getCell(4).value = htmlToText(material.specifications); // Specification
            row.getCell(5).value = material.brand; // Approved_brand
            row.getCell(6).value = material.quantity; // Qty
            row.getCell(7).value = material.unit; // Unit

            // Populate the editable fields based on material data
            row.getCell(8).value = material.basePrice || null; // MRP
            row.getCell(9).value = material.discount > 0 && `${material.discount}%` || null; // Discount
            row.getCell(10).value = material.gst ? `${material.gst}%` : `18%`; // GST
            row.getCell(11).value = htmlToText(material.remark) || null; // Vendor_Remarks

            // Set formulas for calculated fields
            row.getCell(12).value = { formula: `H${index + 3} - (H${index + 3} * I${index + 3})` } || null; // Corrected Net_Rate formula
            row.getCell(13).value = { formula: `L${index + 3} * F${index + 3}` } || null; // Basic_Amount formula remains unchanged
            row.getCell(14).value = { formula: `M${index + 3} * J${index + 3}` } || null; // Corrected GST_Amount formula
            row.getCell(15).value = { formula: `M${index + 3} + N${index + 3}` } || null; // Total_Amount_with_GST formula remains unchanged
        });

        // Ensure the sheet protection and styles are preserved
        sheet.protect("Neevay", {
            selectLockedCells: true,
            selectUnlockedCells: true,
        });

        // Generate and download the updated file
        const blob = await workbook.xlsx.writeBuffer();
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `RFQ-${rfqData?.rfqId}_material_upload.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error processing Excel file:", error);
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};

// Helper function for uploading Excel
export const handleUploadExcel = async (event, setFieldValue, values, rfqData, totalCosts, setExcelLoading, setLoadingMessage, enqueueSnackbar, calculateTotalCost, setTotalCosts, calculateOverallTotal, calculateOverallTotalWithoutGST) => {
    try {
        setExcelLoading(true);
        setLoadingMessage("Processing the uploaded Excel file...");
        const file = event.target.files[0];
        if (!file) return;

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(await file.arrayBuffer());
        const sheet = workbook.getWorksheet("bulk_material_rates");

        const updatedMaterials = [...values.materials];
        const updatedTotalCosts = [...totalCosts];
        const errors = [];

        sheet.eachRow((row, rowNumber) => {
            if (rowNumber < 3) return; // Skip header rows

            const materialIndex = rowNumber - 3;
            const material = updatedMaterials[materialIndex];

            if (material) {
                const uploadedNeevayId = row.getCell(2).value; // Neevay_ID from Excel

                // **New Logic**: Validate if Neevay_ID matches any material._id in rfqData.materials
                const isValidMaterial = rfqData?.materials.some((item) => item._id === uploadedNeevayId);
                if (!isValidMaterial) {
                    errors.push(`Please upload correct sheet. Invalid material at row ${rowNumber}: Neevay_ID does not match.`);
                    return; // Skip processing this row
                }

                const mrp = row.getCell(8).value; // MRP
                const discount = row.getCell(9).value; // Discount
                let gst = row.getCell(10).value; // GST
                const remarks = row.getCell(11).value; // Vendor_Remarks
                const totalExclGstValue = row.getCell(13).value; // Vendor_Remarks

                // Convert raw discount value to percentage if needed
                let discountValue = parseFloat(discount);
                if (discountValue && discountValue <= 1) {
                    discountValue = discountValue * 100; // Convert to percentage
                }

                // Handle GST percentage conversion
                if (gst && gst < 1) {
                    gst = gst * 100; // Convert to whole number percentage if needed
                }

                // Validation: MRP must not be empty
                if (mrp === undefined || mrp === null || isNaN(mrp)) {
                    errors.push(`MRP is empty or invalid in row ${rowNumber}`);
                    return;
                }

                // Update material fields
                material.basePrice = parseFloat(mrp) || 0;
                material.discount = discountValue || 0;
                material.gst = parseFloat(gst) || 0;
                material.remark = remarks || "";
                material.totalExclGst = ((material.basePrice - (material.basePrice * material.discount) / 100) * material.quantity);

                // Recalculate totalCost
                const totalCost = calculateTotalCost(
                    material.basePrice,
                    material.quantity,
                    material.discount,
                    material.gst
                );
                material.totalCost = totalCost;

                // Update totalCosts array
                updatedTotalCosts[materialIndex] = totalCost;
            }
        });

        if (errors.length > 0) {
            enqueueSnackbar(errors.join(", "), { variant: "error" });
            return;
        }

        // Update form values and totalCosts state
        setFieldValue("materials", updatedMaterials);
        setTotalCosts(updatedTotalCosts);

        // Calculate the overall total after updating total costs
        calculateOverallTotal(updatedTotalCosts, values?.miscellaneousCosts);
        calculateOverallTotalWithoutGST(updatedMaterials, values?.miscellaneousCosts)

        enqueueSnackbar("Excel uploaded and data updated successfully!", { variant: "success" });
    } catch (error) {
        console.error("Error processing Excel file:", error);
        enqueueSnackbar("Failed to upload Excel file.", { variant: "error" });
    } finally {
        setExcelLoading(false);
        setLoadingMessage("");
    }
};
